import React from 'react';


class TermsOfUse extends React.Component {	
	render() {
		
		return (
			<div class="v-list-container">
				<div class="v-list-header">Terms of use</div>
				<div class="v-container">						
					<p style={{textAlign: "justify"}}>				


<div style={{lineHeight: "50px"}}>Last updated: June 16, 2022</div>



<h2>WELCOME TO DEAL CORNER.</h2>
<p>We (deal-coner, plc) hope you find it useful. By accessing or otherwise interacting with our servers, services, websites, or any associated content/postings, you agree to these Terms of Use ("TOU"). You acknowledge and agree Deal Corner is a private site owned and operated by deal-corner, plc. If you are accessing or using Deal Corner on behalf of a business, you represent and warrant to Deal Corner that you have authority to accept the TOU on behalf of that business and that that business agrees to the TOU. If you do not agree to the TOU, you are not authorized to use Deal Corner. We may modify the TOU at any time in our sole discretion. You are responsible for periodically checking for changes and are bound by them if you continue to use Deal Corner. Our <a href="https://deal-corner.com/privacy">privacy policy</a>, <a href="http://deal-corner.com/prohibited">prohibited list</a>, and all other policies, site rules, and agreements referenced below or on Deal Corner, are fully incorporated into this TOU, and you agree to them as well.</p>

<h2>LICENSE.</h2>
<p>If you agree to the TOU and (1) are of sufficient age and capacity to use Deal Corner and be bound by the TOU, or (2) use Deal Corner on behalf of a business, thereby binding that business to the TOU, we grant you a limited, revocable, non-exclusive, non-assignable license to use Deal Corner in compliance with the TOU; unlicensed use is unauthorized. You agree not to display, make derivative works, distribute, license, or sell, content from Deal Corner, excluding postings you create. You grant us a perpetual, irrevocable, unlimited, worldwide, fully paid/sublicensable license to use, copy, display, distribute, and make derivative works from the content you post.</p>

<h2>USE.</h2>
<p>Unless licensed by us in a separate written or electronic agreement, 

<ul>
    <li style={{lineHeight: "30px"}}>You agree not to use or provide software (except our App and general purpose web browsers and email clients) or services that interact or interoperate with Deal Corner, e.g. for downloading, uploading, creating/accessing/using an account, posting, flagging, emailing, searching, or mobile use.</li> 
	<li style={{lineHeight: "30px"}}>You agree not to copy/collect Deal Corner content via robots, spiders, scripts, scrapers, crawlers, or any automated or manual equivalent (e.g., by hand). Misleading, unsolicited, and/or unlawful postings/communications/accounts are prohibited, as is buying or selling accounts.</li>  
	<li style={{lineHeight: "30px"}}>You agree not to post content that is prohibited by any of Deal Corner's policies or rules referenced above ("Prohibited Content"). You agree not to abuse Deal Corner's flagging or reporting processes. You agree not to collect Deal Corner user information or interfere with Deal Corner.</li>  
	<li style={{lineHeight: "30px"}}>You agree we may moderate Deal Corner's access/use in our sole discretion, e.g., by blocking, filtering, re-categorizing, re-ranking, deleting, delaying, holding, omitting, verifying, or terminating your access/license/account.</li>  
	<li style={{lineHeight: "30px"}}>You agree (1) not to bypass said moderation, (2) we are not liable for moderating or not moderating, and (3) nothing we say or do waives our right to moderate, or not.</li>  
	<li style={{lineHeight: "30px"}}>Unless licensed by us in a separate written or electronic agreement, you agree not to 
		<ul>
			<li style={{listStyleType: "none"}}>(i) rent, lease, sell, publish, distribute, license, sublicense, assign, transfer, or otherwise make available Deal Corner or our application programming interface ("API"),</li> 
			<li style={{listStyleType: "none"}}>(ii) copy, adapt, create derivative works of, decompile, reverse engineer, translate, localize, port or modify the App, the API, any website code, or any software used to provide Deal Corner,</li> 
			<li style={{listStyleType: "none"}}>(iii) combine or integrate Deal Corner or the API with any software, technology, services, or materials not authorized by us,</li> 
			<li style={{listStyleType: "none"}}>(iv) circumvent any functionality that controls access to or otherwise protects Deal Corner or the API, or</li> 
			<li style={{listStyleType: "none"}}>(v) remove or alter any copyright, trademark or other proprietary rights notices.</li> 
		</ul>
	</li>
	<li style={{lineHeight: "30px"}}>You agree not to use Deal Corner or the API in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law.</li>
</ul>
</p>

<h2>DISCLAIMER & LIABILITY.</h2>
<p>To the full extent permitted by law, deal-coner, PLC., and its officers, directors, employees, agents, licensors, affiliates, and successors in interest ("Deal Corner Entities") 
	<ol>
		<li style={{lineHeight: "30px"}}>make no promises, warranties, or representations as to Deal Corner, including its completeness, accuracy, availability, timeliness, propriety, security or reliability;</li> 
		<li style={{lineHeight: "30px"}}>provide Deal Corner on an "AS IS" and "AS AVAILABLE" basis and any risk of using Deal Corner is assumed by you;</li> 
		<li style={{lineHeight: "30px"}}>disclaim all warranties, express or implied, including as to accuracy, merchantability, fitness for a particular purpose, and non-infringement, and all warranties arising from course of dealing, usage, or trade practice; and</li> 
		<li style={{lineHeight: "30px"}}>disclaim any liability or responsibility for acts, omissions, or conduct of you or any party in connection with Deal Corner.</li> 	
	</ol>
	Deal Corner Entities are NOT liable for any direct, indirect, consequential, incidental, special, punitive, or other losses, including lost profits, revenues, data, goodwill, etc., arising from or related to Deal Corner, and in no event shall such liability exceed $100 or the amount you paid us in the year preceding such loss. Some jurisdictions restrict or alter these disclaimers and limits, so some may not apply to you.
</p>	
	
<h2>CLAIMS & INDEMNITY.</h2> 
<p>Any claim, cause of action, demand, or dispute arising from or related to Deal Corner ("Claims") will be governed by the legal codes of Ethiopia, without regard to conflict of law provisions. Any Claims will be exclusively resolved by courts in Addis Ababa, Ethiopia (except we may seek preliminary or injunctive relief anywhere). You agree to 
	<ol>
		<li style={{lineHeight: "30px"}}>submit to the personal jurisdiction of courts in Addis Ababa, Ethiopia;</li> 
		<li style={{lineHeight: "30px"}}>indemnify and hold Deal Corner Entities harmless from any Claims, losses, liability, or expenses (including attorneys' fees) that arise from a third party and relate to your use of Deal Corner; and</li> 
		<li style={{lineHeight: "30px"}}>be liable and responsible for any Claims we may have against your officers, directors, employees, agents, affiliates, or any other party, directly or indirectly, paid, directed or controlled by you, or acting for your benefit.</li>
	</ol>
</p>

<h2>MISC.</h2> 
<p>Unless you have entered into a separate written or electronic agreement with us that expressly references the TOU, this is the exclusive and entire agreement between us and you, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral. Users complying with prior written licenses may access Deal Corner thereby until authorization is terminated. Our actions or silence toward you or anyone else does not waive, modify, or limit the TOU or our ability to enforce it. The USE and CLAIMS & INDEMNITY sections survive termination of the TOU, and you will remain bound by those sections. If a TOU term is unenforceable, it shall be limited to the least extent possible and supplemented with a valid provision that best embodies the intent of the parties. The English version of the TOU controls over any translations. If you reasonably believe content infringes your IP rights, see our <a href="https://deal-corner.com/infringement">infringment notification page</a>.</p>
					</p>
				</div>
			</div>
		);
	}
}
         
export default TermsOfUse;
