import React from 'react';
import Draggable from 'react-draggable';
import Form from './Form';
import Categories from './Categories';
import { ReactComponent as Delete } from '../img/delete.svg';
import { ReactComponent as Logo } from '../img/logo.svg';
import { ReactComponent as Search } from '../img/search-outline.svg';
import { ReactComponent as Post } from '../img/add.svg';


class Header extends React.Component {
	state={
		showForm: false
	}
	
	handleTextChange = (e) => {
		const txt = e.target.value;
		if (txt && txt.length > 0 || !this.state.textEntered) {
			this.setState({textEntered: true});
		} else {
			this.setState({textEntered: false});
		}
		this.props.filter(txt);
	}
	
	clearText = () => {
		const element = document.getElementById("search-input");
		if (element) {
			element.value = "";
			this.props.filter("");
			this.setState({textEntered: false});
		}
	}
	
	DealCorner = () => {
		return (
			<div class="tr-center" style={{maxHeight: "40px", marginLeft: "10px"}}>
				<div class="logo" onClick={() => {window.location.href = "/";}}><Logo /></div>
				<div class="flex-center col" style={{height: "50px", color: "#fff", margin: "0 10px"}}>
					<div style={{fontSize: "18px", paddingLeft: "2px", fontWeight: "bold"}}>DEAL-CORNER</div>
					<div style={{fontSize: "12px"}}>Get anything in your city.</div>
				</div>
			</div>
		);
	}
	
	
	render() {	
		return (
			<div style={{position: "fixed", top: 0, right: 0, left: 0, zIndex: 3, zIndex: 1100}}>
			<div class="tr-center" style={{backgroundColor: "orange", justifyContent: "space-between", padding: "5px 15px"}}>				
				<this.DealCorner />
				<div class="search flex-center rel" style={{zIndex: 6, margin: "0 30px", width: "60vw"}}>
					<input id="search-input w-100" placeholder="I am looking for ..." 
						style={{color: "rgb(128, 43, 177,0.9)", fontFamily: "Montserrat", height: "35px"}} type="text" onChange={this.handleTextChange} />
					<div class="abs" style={{right: "45px", width: "20px", height: "20px", color: "#aaa"}}>
						{this.state.textEntered && <Delete onClick={this.clearText} />}
						{!this.state.textEntered && <Search />}
					</div>
				</div>
				
				<div class="rel flex-center ptr" style={{padding: "15px", color: "#fff", zIndex: 100}}
					onClick={() => {window.location.href = "/form"}}>
					<span style={{position: "absolute", right: "37px", fontSize: "11px"}}>Post</span>
					<div style={{position: "absolute", right: "5px", width: "25px", height: "25px",maxWidth: "25px", maxHeight: "25px"}}>
						<Post />
					</div>
				</div>
				</div>
			</div>
		); 
	}
}

export default Header;
