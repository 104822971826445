import React, { useState } from 'react';
import '../css/main.css';

function Menu(props) {   
  const [shouldExpand, setShouldExpand] = useState("");
  
	return (
		<div>
			<div class="details-menu" style={{top: "15px", left: "100px", zIndex: 1007}} onClick={(e) => {e.stopPropagation();}}>
				{ !props.hideEmptyRow &&
					<div class="rel" onClick={() => {props.getSelected("");}}
						style={{padding: "2px 25px 2px 5px", height: "15px", cursor: "pointer"}}>
					</div>
				}
				{
					props.array.map((item) => 
						<div class="rel" style={{padding: "2px 25px 2px 5px", cursor: "pointer"}}
						onClick={(e) => {e.preventDefault();e.stopPropagation();setShouldExpand(shouldExpand == item ? "" : item);
										props.getSelected(item);}}>{item}
							{ props.subarray && props.subarray[item] && props.subarray[item].length > 0 &&
								<span style={{position: "absolute", right: "0px", fontSize: "12px", marginLeft: "10px"}} 
									onClick={(e) => {e.preventDefault();e.stopPropagation();setShouldExpand(shouldExpand == item ? "" : item);
										}}>&#9658;
								</span>
							}
							{ shouldExpand == item && props.subarray && props.subarray[item] && props.subarray[item].length > 0 &&
								<Menu array={props.subarray[item]} getSelected={props.getSelected} hideEmptyRow={true} />
							}
						</div>
					)
				}
			</div>
		</div>
	);
}

export default Menu;
