import React from 'react';
import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import Activity from "./Activity";
import { ReactComponent as Eye } from '../img/eye.svg';
import CATEGORIES from '../constants/retail_categories.json';
import '../css/main.css';


 
 // Note: This example requires that you consent to location sharing when
// prompted by your browser. If you see the error "The Geolocation service
// failed.", it means you probably did not give permission for the browser to
// locate you.




/* function initMap(coordinates) {
    var location = {'lng': coordinates.longitude, 'lat': coordinates.latitude};

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 4,
      center: location
    });
    var marker = new google.maps.Marker({
      position: location,
      map: map
    });
} */


/* let map, infoWindow;

function initMap() {
  map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 6,
  });
  
  infoWindow = new google.maps.InfoWindow();

  const locationButton = document.createElement("button");

  locationButton.textContent = "Pan to Current Location";
  locationButton.classList.add("custom-map-control-button");
  map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
  locationButton.addEventListener("click", () => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          infoWindow.setPosition(pos);
          infoWindow.setContent("Location found.");
          infoWindow.open(map);
          map.setCenter(pos);
        },
        () => {
          handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false, infoWindow, map.getCenter());
    }
  });
}

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
  );
  infoWindow.open(map);
}

window.initMap = initMap; */

class StoreRegistrationForm extends React.Component {
	state={
		name: "",
		category: "",		
		phone: "",
		password: "",
		admin_password: "",
		locations: "",
		isSending: false,		
		successMessage: "",
		errorMessage: ""
	}
	
	reset = () => {
		this.setState({
			name: "",
			category: "",		
			phone: "",
			password: "",
			locations: "",
			isSending: false,		
			successMessage: "",
			errorMessage: ""
		});
	}
	
	showPassword = () => {
		const element = document.getElementById("password");
		element.type = element.type == "password" ? "text" : "password";
	}
	
	submit = () => {
		if (this.state.admin_password == "deal1098corner" || this.state.admin_password == "_deal_123_corner") {		
			try {			
				
				const url = "https://deal-corner.com/server/shop/register";
				
				const data = {
					
					method: 'POST',
					body: JSON.stringify({
						name: this.state.name,
						category: this.state.category,		
						phone: this.state.phone,
						password: jwt.sign(this.state.password, "_deal_corner"),
						locations: this.state.locations
					}),
					headers: {
						"Content-Type": "application/json"
					}			
				};
					 
				fetch(url, data).then((e) => e.json())
				.then((e) => {						
					if (e.data === "SUCCESS") {
						this.setState({showActivity: false, successMessage: "Shop registered!", errorMessage: ""});
					} else {
						this.setState({showActivity: false, successMessage: "",errorMessage: e.data});
					}
				})
				.catch((e) => {
					this.setState({showActivity: false, successMessage: "", errorMessage: e.message});
				});
			} catch(e) { }
		} else {
			this.setState({errorMessage: "Wrong admin password!"});
		}
	}

	componentDidMount() {
		navigator.geolocation.getCurrentPosition(
			(pos) => {this.setState({locations: JSON.stringify([{lat: pos.coords.latitude, lng: pos.coords.longitude}]) });}, 
			(err) => {},
			{
				enableHighAccuracy: true
			}
		);
	}
	
	render() {
		if (this.state.successMessage.length > 0) {
			return (
				<div class="form-container flex-center purple-cl rel" 
					style={{minHeight: "90vh", zIndex: 101, textAlign: "center"}}>
					
					<div class="form-message" style={{marginTop: "50px"}}>
						{this.state.successMessage}
					</div>
					<div style={{fontSize: "16px", margin: "100px 0"}}>
						<a onClick={this.reset} style={{textDecoration: "underline", cursor: "pointer"}}>Post</a> another one.
					</div>
					<div class="button" style={{alignSelf: "center"}}
						onClick={() => {window.location.href = "/"}}>
						Close
					</div>
				</div>
			);
		}
		return (
			<div class="tr-center" style={{height: "100vh", backgroundColor: "#ccc"}}>
				<div class="form-container" style={{position: "relative", zIndex: 1001, minHeight: "70vh"}}>
					<div class="close btn purple-cl"  style={{alignSelf: "flex-end", cursor: "pointer"}} 
						onClick={() => {window.location.href = "/";}}><span>&#10006;</span>
					</div>
					<div class="select-container">
						<div class="purple-cl select-label">Category:</div>
						<select class="purple-cl" value={this.state.category} 
						onChange={(e) => this.setState({category: e.target.value})}>
							<option value=""></option>
							{
								CATEGORIES.map((item) => <option value={item.toLowerCase()}>{item}</option>)
							}
						</select>
					</div>
					<input class="full-width form" type="text" placeholder="Name" required value={this.state.name} 
						onChange={(e)=> this.setState({name: e.target.value, errorMessage: ""})} />
					<input class="full-width form" type="text" placeholder="Phone no." value={this.state.phone} 
						onChange={(e)=> this.setState({phone: e.target.value, errorMessage: ""})} />
				
					<div class="rel w-100 tr-center">
						<input class="full-width form w-100" type="password" id="password" placeholder="Password" 
							value={this.state.password} onChange={(e)=> this.setState({password: e.target.value, errorMessage: ""})} />
						<div class="side-icon" onClick={this.showPassword}><Eye /></div>
					</div>
					<div class="rel w-100 tr-center">
						<input class="full-width form w-100" type="password" id="password" placeholder="Admin Password" 
							value={this.state.admin_password} onChange={(e)=> this.setState({admin_password: e.target.value, errorMessage: ""})} />
					</div>				
					{ this.state.errorMessage.length > 0 && 
						<small style={{color: "#aa0000"}}>{this.state.errorMessage}</small>
					}
					{ this.state.isSending && this.state.errorMessage.length == 0 && 
						<div class="w-100 purble-cl tr-center" style={{padding: "0 15px"}}>
							<Activity small="small"/>
							<small style={{flex: 1}}>Sending...</small>
						</div>
					}
					<div class="button" onClick={this.submit}>
						Submit
					</div>
				</div>
			</div>
		); 
	}
}

export default StoreRegistrationForm;
