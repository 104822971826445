import React from 'react';


class Infringement extends React.Component {	
	render() {
		
		return (
			<div class="v-list-container">
				<div class="v-list-header">NOTIFICATION OF CLAIMS</div>
				<div class="v-container">						
					<p style={{textAlign: "justify"}}>

If you believe your copyright has been infringed, or your intellectual property rights otherwise violated, please provide our Agent with each of the following:
<ol>
<li style={{lineHeight: "30px"}}>Identification of the copyrighted work you claim has been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>

<li style={{lineHeight: "30px"}}>Identification of the material on the deal-corner.com site that you claim is infringing, with enough detail so that we may locate it (must include deal-corner URL);</li>

<li style={{lineHeight: "30px"}}>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>

<li style={{lineHeight: "30px"}}>A statement by you declaring under penalty of perjury that (1) the above information in your Notice is accurate, and (2) that you are the owner of the copyright interest involved or that you are authorized to act on behalf of that owner;</li>

<li style={{lineHeight: "30px"}}>Your address, telephone number, and email address; and</li>

<li style={{lineHeight: "30px"}}>Your physical or electronic signature.</li>
</ol>
Your Notice will be subject to The Digital Millennium Copyright Act (DMCA).  Under appropriate circumstances, Deal Corner may terminate the accounts of repeat infringers.

Your notification (which must include items 1-6 as shown above) may be sent to:

info@deal-corner.com



					</p>
				</div>
			</div>
		);
	}
}
         
export default Infringement;
