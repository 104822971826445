import React, { useState }  from 'react';
import Adsense from "./Adsense";

import Form from "./Form";
import Activity from "./Activity";
import HomeActivityRow from './HomeActivityRow';
import Badge from "./Badge";
import Menu from "./Menu";
import Feedback from "./Feedback";
import HomeActivity from "./HomeActivity";
import Header from "./Header";
import ExpandedItem from "./ExpandedItem";

import SideInfo from "./SideInfo";
import Services from "./Services";

//import Consultancies from "./Consultancies";
//import Deliveries from "./Deliveries";

import Utils from "./Utils";
import CATEGORIES from '../constants/categories.json';
import DETAILS from '../constants/details.json';
import '../css/main.css';

import { ReactComponent as Facebook } from '../img/facebook.svg';
import { ReactComponent as Linkedin } from '../img/linkedin.svg';
import { ReactComponent as Twitter } from '../img/twitter.svg';
import { ReactComponent as Telegram } from '../img/telegram.svg';
import { ReactComponent as Instagram } from '../img/instagram.svg';
import { ReactComponent as Tiktok } from '../img/tiktok.svg';

import { ReactComponent as OutlineLogo } from '../img/logo-outline.svg';
import { ReactComponent as Right } from '../img/chevron-right.svg';
import { ReactComponent as DownArrow } from '../img/down-arrow.svg';
import { ReactComponent as Call } from '../img/phone-call.svg';
import { ReactComponent as MoreInfo } from '../img/more-info.svg';
import { ReactComponent as Message } from '../img/message.svg';
import { ReactComponent as Location } from '../img/location.svg';
import { ReactComponent as Chevron_left } from '../img/chevron-left.svg';
import { ReactComponent as Chevron_right } from '../img/chevron-right.svg';
import { ReactComponent as Views } from '../img/views.svg';


import Electronics_en from '../img/electronics.webp';
import ShopItem from '../img/shop-item.svg';
import LogoOutline from '../img/logo-outline.svg';
 
var STARTS = {};
CATEGORIES.forEach((item) => {STARTS[item.toLowerCase()] = 0;});
var ADS_INTERVAL = 20;

var DEVICE = "mobile";
var TITLE_LENGTH = 20;
if (window.screen.width < 768) {
	DEVICE = "mobile";
	TITLE_LENGTH = 20;
} else if (window.screen.width < 1024) {
	DEVICE = "tab";
	TITLE_LENGTH = 30;
} else {
	DEVICE = "pc";
	TITLE_LENGTH = 35;
}
const video = ["mp4","mpg","mpeg","ogg","m4v","wmv","avi","flv","swf","mkv","rm","3g2","3gp","h264","mov","vob"];  
const audio = ["aif","cda","mid","midi","mp3","wav","wma", "wpl"];
	
const getAdjustedTitle = (title) => {
	var t_length = TITLE_LENGTH, count = 0;
	
	for (let i = 0; i < title.length; i++) {
		
		if (title.charCodeAt(i) > 64 && title.charCodeAt(i) < 123 && title.charAt(i) == title.charAt(i).toUpperCase()) {
			count++;
			if (count > 4) {
				t_length = 8;
				break;
			}
		}
	}
	
	if (title.length < t_length) {
		return title;
	} else if (title.lastIndexOf(" ") == -1) {
		return title.substring(0, t_length) + "...";		
	} else if (title.lastIndexOf(" ") < t_length) {		
		return title.substring(0,title.lastIndexOf(" ")) + "...";		
	} else {
		var tIndex = title.indexOf(" ", 0), temp = tIndex, counter = 0, temp2;	
		while (temp > 0 && temp < t_length && temp <= title.lastIndexOf(" ") && counter < 20) {
			temp2 = title.indexOf(" ", tIndex + 1);
			if (temp2 < t_length) {
				tIndex = temp2;
			}
			
			temp = title.indexOf(" ", tIndex + 1);
			counter++;
		}
		
		return title.substring(0,tIndex) + "...";
	}
};

const FreeDelivery = () => {
	return (
		<div style={{width: "30px", height: "30px", position: "absolute", left: "7px", top: "5px"}}>
			<svg viewBox="0 0 455 455">
				<polygon fill="yellow" stroke="yellow" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" 
					points="455,0 0,0 0,300 227,455 455,300"/>
				<text x="100" y="120" fontSize="100px">Free</text>
				<text x="48" y="250" fontSize="100px">delivery</text>
			</svg>
		</div>
	);
};
const CulturalActivity = () => {		
		return (
			<div class="grid-2b2 loading" style={{height: "420px"}}>
				{ Array(4).fill().map(() => 
				<div style={{height: "170px"}}>
					<div style={{width: "100%", height: "90%", backgroundColor: "#ddd", borderRadius: 0, marginBottom: "5px"}}></div>
					<div class="tr-center w-100" style={{color: "transparent", backgroundColor: "#ccc"}}>abcde ETB</div>
				</div>
				)}					
			</div>
		);		
	};
	
const ClothingOptions = (props) => {
	
	const [selected, setSelected] = useState("CULTURAL");
	
	return (
		<div>					
			<div class="tr-center clothing-options">
				{
					["CULTURAL","WOMEN","MEN","KIDS"].map((item) => 
						<div style={{textDecoration: selected == item ? "underline #000 solid 3px": "none", textUnderlineOffset: "5px",
						fontWeight: selected == item ? "700": "400"}} onClick={() => {setSelected(item);props.getSelected(item);}}>
							<img></img>
							<div>{item}</div>
						</div>
					)
				}
			</div>
		</div>
	);
};

const OneRowActivity = () => {
		return (
			<div class="grid-2b2 grid-1b2 electronics-list loading" style={{height: "210px", minHeight: "210px"}}>
				{ Array(2).fill().map(() => 
					<div style={{height: "100%"}}>
						<div style={{width: "100%", height: "95%", minHeight: "95%", backgroundColor: "#ddd", borderRadius: 0, marginBottom: "5px"}}>
						</div>
						<div class="w-100" style={{width: "100%", fontSize: "10px", color: "tranparent", backgroundColor: "#ccc"}}></div>
						<div class="w-100" style={{color: "transparent", backgroundColor: "#ccc"}}></div>
					</div>
				)}
			</div>
		);
	};
const Cultural = (props) => {
		return (
			<div style={{margin: "15px", backgroundColor: "#fff"}}>
				
				{	props.data && props.data.length > 0 && 
					<div class="grid-2b2 cultural" style={{height: "420px"}}>
						{ 	props.data.map((item) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								
								return (
									<div style={{height: "170px"}} class="rel" 
										onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										<img style={{height: "90%", marginBottom: 0, paddingBottom: 0}} 
											src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="flex-center w-100 home-grid-border">
											<div style={{flex: 1, paddingLeft: "3px"}}> 
												<div class="tr-center w-100" style={{height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
													{getAdjustedTitle(item.title)}
												</div>
												<div class="tr-center w-100" style={{color: "#ff8a8a"}}>
													{parseFloat(item.price).toLocaleString('en-US')} ETB
												</div>
											</div>
											<div class="flex-center col" style={{color: "#afada9"}}>
												<div style={{width: "50px", height: "20px"}} class="flex-center">
													<div style={{width: "20px", height: "20px"}}><Views /></div>
													<span>{item.views}</span>
												</div>
												<div style={{fontSize: "12px"}}>{item.city}</div>
											</div>
										</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
									</div>
								)
							})
						}				
					</div>
				}
				{ (!props.data || props.data.length == 0) && <CulturalActivity /> }
				{ props.data && props.data.length > 0 && 
					<div class="see-more" onClick={() => {window.location.href = "/list?category=clothing&Type=" + props.clothingType;}}>See more</div>
				}
			</div>
		);
	};
	
const Shoes = (props) => {
	return(
		<div style={{margin: "15px", backgroundColor: "#fff"}}>
			<div style={{fontWeight: "bold", fontSize: "18px", paddingLeft: "15px"}}>Quality shoes</div>
				{	props.data && props.data.length > 0 &&
					<div class="grid-2b2 grid-1b2 grid-1b3 electronics-list">
						{ 	props.data.map((item, index) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								
								return (
									<div onClick={() => {window.location.href = `/items?category=Clothing&title=${item.title}&type=Men&details=Shoes&id=${item.id}`}}>
										<img src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="flex-center w-100 home-grid-border">
											<div class="flex-center col" style={{flex: 1, paddingLeft: "3px", alignItems: "flex-start"}}> 
												<div class="w-100" style={{alignSelf: "flex-start", height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
													{getAdjustedTitle(item.title)}
												</div>
												<div style={{alignSelf: "flex-start", color: "#ff8a8a"}}>
													{parseFloat(item.price).toLocaleString('en-US')} ETB
												</div>
											</div>
											<div style={{height: "16px", justifyContent: "space-around", color: "#aaa"}} class="flex-center col">
												<div style={{width: "16px", height: "16px"}}><Views /></div>
												<span style={{fontSize: "10px"}}>{item.views}</span>
											</div>
										</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
									</div>
								)
							})
						}				
					</div>
				}
			
			{ (!props.data || props.data.length == 0) && <OneRowActivity /> }
			{ props.data && props.data.length > 0 && 
				<div class="see-more" onClick={() => {window.location.href = "/list?category=clothing&Type=Men&Details=Shoes";}}>See more</div>
			}
		</div>
	);
};
const Parser = (props) => <div style={{height: "165px", width:"100%"}} dangerouslySetInnerHTML={{__html: props.data}}></div>
const getFile = async (file_name, index) => {
	fetch(`https://deal-corner.com/server/information/items/${file_name}`)
	.then((e) => e.text())
	.then((res) => {
		const element = document.getElementById("info"+ index);
		if (element) {
			element.appendChild(<div style={{height: "165px", width:"100%"}} dangerouslySetInnerHTML={{__html: res}}></div>);
		}
	})
	.catch((e) => {
	});
};

const Info = (props) => {
	return (
		<div style={{margin: "0", backgroundColor: "#fff"}}>
			<div onClick={() => {window.location.href = "https://info.deal-corner.com";}} 
				style={{fontWeight: 500, fontSize: "18px", textAlign: "justify", marginBottom: "10px"}}>
				<span class="flex-center" 
								style={{backgroundColor: "orange", color: "#fff", textDecoration: "underline", padding: "5px 15px"}}>
				You can also get blogs and detailed information on selected topics.
				</span>
			</div>
						{false && props.data && props.data.length > 0 && <div class="grid-2b2 grid-1b2 electronics-list"  style={{height: "240px"}}>
						<div>			
							<span class="flex-center" 
								style={{backgroundColor: "orange", color: "#fff", 
									boxSizing: "border-box", fontSize: "24px", padding: "15px", textAlign: "center", whiteSpace: "normal"}}>
									Do you have a story?
							</span>
									
									
								<div class="flex-center col w-100 home-grid-border" style={{marginTop: "0"}}>
									<div class="flex-center col rel" style={{width: "95%"}}>
										<div class="abs" style={{fontSize: "18px", color: "orange"}}>
											<span style={{fontSize: "20px", textDecoration: "underline"}} 
											onClick={() => {window.location.href = "https://info.deal-corner.com/info-form"}}>Share</span> it here.
										</div>
										<div class="tr-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px", color: "transparent"}}> 
										title	
										</div>
										<div class="flex-center w-100">
											<div class="tr-center w-100" style={{color: "transparent", padding:"0", marginLeft: "-4px"}}>
												
												<div style={{fontSize: "12px"}}>text</div>
											</div>
											<div class="flex-center" style={{color: "transparent"}}>
												<div style={{height: "20px"}} class="flex-center">
													<div style={{width: "20px", height: "20px"}}><Views /></div>
												</div>
												<span>transaparent</span>
											</div>
										</div>
									</div>
								</div>
							</div>
				{ 	props.data.map((item, index) => {
						
						const ext = item.file_name.substring(item.file_name.lastIndexOf("."));
								var fileType = "Text";
								if (video.indexOf(ext.substring(1)) != -1) {
									fileType = "Video";
								} else if (audio.indexOf(ext.substring(1)) != -1) {
									fileType = "Audio";
								}
						return (
							<div onClick={() => {window.location.href = `https://info.deal-corner.com/?category=${item.category}&title=${item.title}&keywords=${item.keywords}&author=${item.author}&phone=${item.phone}&telegram=${item.telegram}&
									date=${item.date}&views=${item.views}&likes=${item.likes}&dislikes=${item.dislikes}&file_name=${item.file_name}&id=${item.id}&password=${item.password}`}}>			
								
									{	ext == ".txt" &&
											<span class="flex-center" style={{backgroundColor: "#555", color: "#fff", 
									boxSizing: "border-box", fontSize: "24px", padding: "15px", textAlign: "center", whiteSpace: "normal"}}>{item.title}</span>
										}
										
										{	ext != ".txt" &&
											<video controls>
												<source src={`https://info.deal-corner.com/server/information/items/${item.file_name}`} />
											</video> 
										}
									
								<div class="flex-center col w-100 home-grid-border" style={{marginTop: "0"}}>
									<div class="flex-center col" style={{width: "95%"}}>
										<div class="tr-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px"}}> 
											{	ext == ".txt" && <div style={{fontSize: "12px"}}>{getAdjustedTitle("Posted by: " + item.author)}</div> }
											{	ext != ".txt" && <div style={{fontSize: "16px"}}>{getAdjustedTitle(item.title)}</div> }
										</div>
										<div class="flex-center w-100">
											<div class="tr-center w-100" style={{color: "#aaa", padding:"0", marginLeft: "-4px"}}>
												
												<div style={{fontSize: "12px"}}>{fileType} file</div>
											</div>
											<div class="flex-center" style={{color: "#aaa"}}>
												<div style={{height: "20px"}} class="flex-center">
													<div style={{width: "20px", height: "20px"}}><Views /></div>
												</div>
												<span>{item.views}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					})
				}					
			</div> }
						{ false && (!props.data || props.data.length == 0) && <OneRowActivity /> }
						{  false && props.data && props.data.length > 0 && 
							<div class="see-more" onClick={() => {window.location.href = "https://info.deal-corner.com";}}>See more</div>
						}
					</div>
					
	);
};


const Watches = (props) => {
	return (
		<div style={{margin: "15px", backgroundColor: "#fff"}}>
			<div class="tr-center watches" style={{marginLeft: "-15px", marginRight: "-15px"}}>
			<span style={{width: "50%", marginLeft: "15px"}}>Stylish watches</span></div>						
			{   props.data && props.data.length > 0 &&
				<div class="grid-2b2 grid-1b2 grid-1b3 electronics-list" style={{marginTop: "15px"}}>
				{ 	props.data.map((item, index) => {
						var pictures = {}, details = {};
						try {
							pictures = JSON.parse(item.pictures);
						} catch(e) {}
						try {
							details = JSON.parse(item.details);
						} catch(e) {}
						
						return (
							<div onClick={() => {window.location.href = `/items?category=Clothing&title=${item.title}&type=Men&details=Watches&id=${item.id}`}}>
								<img src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
								<div class="flex-center w-100 home-grid-border">
									<div class="flex-center col" style={{flex: 1, paddingLeft: "3px", alignItems: "flex-start"}}> 
										<div class="w-100" style={{alignSelf: "flex-start", height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
											{getAdjustedTitle(item.title)}
										</div>
										<div style={{alignSelf: "flex-start", color: "#ff8a8a"}}>
											{parseFloat(item.price).toLocaleString('en-US')} ETB
										</div>
									</div>
									<div style={{height: "16px", justifyContent: "space-around", color: "#aaa"}} class="flex-center col">
										<div style={{width: "16px", height: "16px"}}><Views /></div>
										<span style={{fontSize: "10px"}}>{item.views}</span>
									</div>
								</div>
								{details["Has delivery"] == "Yes" && <FreeDelivery />}
							</div>
						)
					})
				}				
				</div>
			}
			{ (!props.data || props.data.length == 0) && <OneRowActivity /> }
			{ props.data && props.data.length > 0 && 
				<div class="see-more" onClick={() => {window.location.href = "/list?category=clothing&Type=Men&Details=Watches";}}>See more</div>
			}
		</div>
	);
};

const Cars = (props) => {
	return(
		<div style={{margin: "15px", backgroundColor: "#fff"}}>
			<div style={{fontWeight: "bold", fontSize: "18px", paddingLeft: "15px"}}>Cars</div>
				{	props.data && props.data.length > 0 &&
					<div class="grid-2b2 grid-1b2 electronics-list" style={{height: "240px"}}>
						{ 	props.data.map((item, index) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								const now = Date.now();
								return (
									<div onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										
										<img src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="flex-center col w-100 home-grid-border">
											<div class="flex-center col w-100" style={{width: "93%"}}>
												<div class="flex-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px"}}> 
													<div class="tr-center w-100" style={{flex: 1, height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
														{getAdjustedTitle(item.title)}
													</div>
													<div class="flex-center" style={{color: "#aaa"}}>
														<div style={{height: "20px"}} class="flex-center">
															<div style={{width: "20px", height: "20px"}}><Views /></div>
														</div>
														<span>{item.views}</span>
													</div>
												</div>
												<div class="flex-center w-100">
													<div class="tr-center" style={{flex: 1, color: "#ff8a8a"}}>
														{Utils.formatNumber(item.price)} ETB
													</div>
													<div class="tr-center" style={{fontSize: "10px", color: "#aaa"}}>
														{Utils.RelativeTime(now, item.date)}
													</div>
												</div>
											</div>
											<div class="flex-center w-100" style={{color: "#5f9ea0", padding:"3px", width: "95%"}}>
													<div style={{width: "15px", height: "15px"}}><Location /></div>
													<div style={{fontSize: "12px"}}>{item.city}</div>
												</div>
											
										</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
									</div>
								)
							})
						}				
					</div>
				}
			
			{ (!props.data || props.data.length == 0) && <OneRowActivity /> }
			{ props.data && props.data.length > 0 && 
				<div class="see-more" onClick={() => {window.location.href = "/list?category=cars&Type=";}}>See more</div>
			}
		</div>
	);
};

const Houses = (props) => {
	return(
		<div style={{margin: "15px", backgroundColor: "#fff"}}>
			<div style={{fontWeight: "bold", fontSize: "18px", paddingLeft: "15px"}}>Houses</div>
				{	props.data && props.data.length > 0 &&
					<div class="grid-2b2 grid-1b2 electronics-list" style={{height: "240px"}}>
						{ 	props.data.map((item, index) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								const now = Date.now();
								return (
									<div onClick={() => {window.location.href = `/items?category=Houses&title=${item.title}&id=${item.id}`}}>
										<img src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="flex-center col w-100 home-grid-border">
											<div class="flex-center col w-100" style={{width: "93%"}}>
												<div class="flex-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px"}}> 
													<div class="tr-center w-100" style={{flex: 1, height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
														{getAdjustedTitle(item.title)}
													</div>
													<div class="flex-center" style={{color: "#aaa"}}>
														<div style={{height: "20px"}} class="flex-center">
															<div style={{width: "20px", height: "20px"}}><Views /></div>
														</div>
														<span>{item.views}</span>
													</div>
												</div>
												<div class="flex-center w-100">
													<div class="tr-center" style={{flex: 1, color: "#ff8a8a"}}>
														{Utils.formatNumber(item.price)} ETB
													</div>
													<div class="tr-center" style={{fontSize: "10px", color: "#aaa"}}>
														{Utils.RelativeTime(now, item.date)}
													</div>
												</div>
											</div>
											<div class="flex-center w-100" style={{color: "#5f9ea0", padding:"3px", width: "95%"}}>
													<div style={{width: "15px", height: "15px"}}><Location /></div>
													<div style={{fontSize: "12px"}}>{item.city}</div>
												</div>
											
										</div>
										
									</div>
								)
							})
						}				
					</div>
				}
			
			{ (!props.data || props.data.length == 0) && <OneRowActivity /> }
			{ props.data && props.data.length > 0 && 
				<div class="see-more" onClick={() => {window.location.href = "/list?category=houses&Type=";}}>See more</div>
			}
		</div>
	);
};


const Clothes = (props) => {
	return (
		<div style={{margin: "15px", backgroundColor: "#fff"}}>
			<div style={{fontWeight: "bold", fontSize: "28px", paddingLeft: "15px", textAlign: "center"}}>Just for you</div>
			{	props.data && props.data.length > 0 && 
				<div class="grid-2b2 grid-1b2 grid-1b3 electronics-list">
					{ 	props.data.map((item, index) => {
							var pictures = {}, details = {};
							try {
								pictures = JSON.parse(item.pictures);
							} catch(e) {}
							try {
								details = JSON.parse(item.details);
							} catch(e) {}
							
							return (
								<div onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										
									<img src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
									<div class="flex-center w-100 home-grid-border">
											<div class="flex-center col" style={{flex: 1, paddingLeft: "3px", alignItems: "flex-start"}}> 
												<div class="w-100" style={{alignSelf: "flex-start", height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
													{getAdjustedTitle(item.title)}
												</div>
												<div style={{alignSelf: "flex-start", color: "#ff8a8a"}}>
													{parseFloat(item.price).toLocaleString('en-US')} ETB
												</div>
											</div>
											<div style={{height: "16px", justifyContent: "space-around", color: "#aaa"}} class="flex-center col">
												<div style={{width: "16px", height: "16px"}}><Views /></div>
												<span style={{fontSize: "10px"}}>{item.views}</span>
											</div>
										</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
								</div>
							)
						})
					}				
				</div> 
			}
			{ (!props.data || props.data.length == 0) && <OneRowActivity /> }
			{ props.data && props.data.length > 0 && 
				<div class="see-more" onClick={() => {window.location.href = "/list?category=clothing&Type=Women&Details=Watches,T-shirts,Suits,Jackets,Sweaters,Bags";}}>See more</div>
			}
		</div>
	);
};

const Electronics = (props) => {
	return (
		<div style={{margin: "15px", backgroundColor: "#fff"}}>
						<div style={{fontWeight: "bold", fontSize: "22px", paddingLeft: "15px"}}>Just for you</div>
						{ props.data && props.data.length > 0 && <div class="grid-2b2 grid-1b2 electronics-list"  style={{height: "240px"}}>
				{ 	props.data.map((item, index) => {
						var pictures = {}, details = {};
						try {
							pictures = JSON.parse(item.pictures);
						} catch(e) {}
						try {
							details = JSON.parse(item.details);
						} catch(e) {}
						
						return (
							<div style={{height: "100%"}} onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										
								<img style={{minHeight: "150px"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
								<div class="flex-center col w-100 home-grid-border">
									<div class="flex-center col" style={{width: "95%"}}>
										<div class="flex-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px"}}> 
											<div class="tr-center w-100" style={{flex: 1, height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
												{getAdjustedTitle(item.title)}
											</div>
											{details.Status == "New" && 
												<span style={{fontSize: "10px", color: "orange", height: "15px", paddingLeft: "5px"}}>NEW</span>
											}
											{details.Status == "Used" && 
												<span style={{fontSize: "10px", color: "#a00", height: "15px", paddingLeft: "5px"}}>USED</span>
											}
										</div>
										<div class="flex-center w-100">
											<div class="tr-center w-100" style={{color: "#aaa", padding:"0", marginLeft: "-4px"}}>
												<div style={{width: "15px", height: "15px"}}><Location /></div>
												<div style={{fontSize: "12px"}}>{item.city}</div>
											</div>
											<div class="flex-center" style={{color: "#aaa"}}>
												<div style={{height: "20px"}} class="flex-center">
													<div style={{width: "20px", height: "20px"}}><Views /></div>
												</div>
												<span>{item.views}</span>
											</div>
										</div>
									</div>
									
									<div class="tr-center" style={{width: "95%", flex: 1, color: "#ff8a8a"}}>
										{parseFloat(item.price).toLocaleString()} ETB
									</div>
									
								</div>
								{details["Has delivery"] == "Yes" && <FreeDelivery />}
							</div>
						)
					})
				}					
			</div> }
						{ (!props.data || props.data.length == 0) && <OneRowActivity /> }
						{ props.data && props.data.length > 0 && 
							<div class="see-more" onClick={() => {window.location.href = "/list?category=electronics&Type=";}}>See more</div>
						}
					</div>
					
	);
};

class Home extends React.Component {
	state={
		ad: '',
		home_message: '',
		page: 0,
		eIconsPage: 0,
		data: [],
		temp: [],
		information: [],
		shopNames: [],
		shopItems: {},
		activePics: {},
		message: "",
		clothingType: "Cultural",
		displayDetails: "",
		showFiteredItems: false,
		showCategories: false,
		isPageLoading: true,
		fetchingFilteredData: "",		
		starts: STARTS,
		selected: "",
		isPosting: false
	}
	
	setCategory = (txt) => {
		const cat = txt.split("-")[0].toLowerCase();
		this.setState({active: cat, showCategories: false});
		this.props.history.push(`/list/${cat}/`)
	}
	
	setActivePic = (e, cat, index, num) => {
		e.preventDefault();
		e.stopPropagation();
		var pics = this.state.activePics;
		pics[cat+index] = num;
		this.setState({activePics: pics});
	}
	
	select = (category, index) => {
		this.setState({selected: category + index});
	}
	
	unselect = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({selected: "" });
	}	
	
	filter = (txt) => {		
		if (txt.length == 0) {
			this.setState({ filteredItems: null, showFilteredItems: false });						
			return;
		} else {
			txt = txt.toLowerCase();
			this.setState({ showFilteredItems: true });	
			this.fetchFilteredData(txt);
		}
	}
	
	fetchFilteredData = (txt) => {
		const url = `https://deal-corner.com/server/get/filtered/deals`;
		var body = {page: 0, limit: 20, text: txt};		
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			this.setState({ filteredItems: e.data });
		}).catch((e) => {
			
		});
	}
	
	fetchPosts = (category, criteria, limit) => {
		const url = `https://deal-corner.com/server/get/deals/${category.toLowerCase()}`;
		var body = {page: 0, limit: limit ? limit: 10};
		
		if (criteria && criteria.length > 0) {			
			body.criteria = criteria;
		}
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {			
			var temp = {...this.state.data, ... e.data};				
			this.setState({...e.data, isLoading: false, isPageLoading: false, fetchingFilteredData: "" });
		}).catch((e) => {
			
		});
	}
	
	getPosts = (txt, page) => {
		CATEGORIES.slice(0,9).forEach((category) => {
			this.fetchPosts(category);
		});
	}
	
	getShopNames = (txt, page) => {		
		const url = `https://deal-corner.com/server/shops/get`;
		const data = {
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			this.setState({shopNames: e.data});
			this.getShopItems(e.data);
		}).catch((e) => {
			
		});
	}
	
	getShopItems = (data) => {		
		data.forEach((item) => {
			const url = `https://deal-corner.com/server/shopItems/get`;
			const name = item.name;
			const data = {
				method: 'POST',
				body: JSON.stringify({page: 0, limit: 10, name: item.name }),
				headers: {
					"Content-Type": "application/json"
				}			
			};
			fetch(url, data).then((e) => e.json())
			.then((e) => {
				if (typeof e.data == 'object') {
					var temp = this.state.shopItems
					temp[name] = e.data;
					this.setState({shopItems: temp});
				}
			}).catch((e) => {
				
			});
		});
		
	}
	
	picIndex = (data) => {		
		const hasPicture1 = data && data.picture1 && data.picture1.length > 0;
		const hasPicture2 = data && data.picture2 && data.picture2.length > 0;
		const hasPicture3 = data && data.picture3 && data.picture3.length > 0;
		var index = -1;
		
		if (hasPicture1) {
			index = 1;
		} else if (hasPicture2) {
			index = 2;
		} else if (hasPicture3) {
			index = 3;
		}
		 
		return index;
	}
	
	showForm = () => {
		this.setState({isPosting: true});
	}
	
	closeForm = () => {
		this.setState({isPosting: false, selected: ""});
	}	
	
	moveRow = (cat, increment) => {
		const start = this.state.starts[cat.toLowerCase()] + increment;
		if (start >= 0 && start < (10- start)) {
			var starts =this.state.starts; 
			starts[cat.toLowerCase()] = start; 
			this.setState({starts: starts});
		}
	}
	
	titles = (props) => {
		const category = props.category;
		return (
			<div class="rel tr-center" style={{justifyContent: "space-between", padding: "5px 20px"}}>
				<div class="purple-cl tr-center" style={{fontWeight: "bold", fontSize: "22px",cursor: "pointer"}} 
					onClick={() => {this.props.history.push(`/list/${category.toLowerCase()}`)}}>
					{ category.toUpperCase()} 
					{ DETAILS[category.toLowerCase()] &&
						<span style={{fontSize: "14px", marginLeft: "10px"}} 
							onClick={(e) => {this.changeDisplayDetails(e, category);}}>&#9660;
						</span>
					}
					{ this.state.fetchingFilteredData == category && 
						<div class="w-100 purple-cl tr-center" style={{padding: "0 15px"}}>
							<Activity small="small"/>
							<small style={{flex: 1, fontWeight: 400, fontSize: "12px"}}>Processing...</small>
						</div>
					}
				</div>
				
				{ DETAILS[category.toLowerCase()] && this.state.displayDetails == category &&
					<Menu array={DETAILS[category.toLowerCase()].Type} 
						subarray={DETAILS[category.toLowerCase()].Details} 
						getSelected={(detail) => {this.fetchFilteredData(category, detail);}}/>
				}
			</div>
		);
	}
	
	description = (props) => {
		const item = props.item;
		const category = props.category;
		const index = props.index;
		const shopItem = props.shopItem ? "/shop" : "";
		var pictures, keys;
		try {
			pictures = JSON.parse(item.pictures);
			keys = Object.keys(pictures).filter((key) => pictures[key].length > 0);
		} catch(e) {
			pictures = {};
			keys = [];
		}
		var tIndex = item.title.indexOf(" ", 0), temp = tIndex, counter = 0;
		while (temp > 0 && temp < TITLE_LENGTH && temp <= item.title.lastIndexOf(" ") && counter < 20) {
			tIndex = item.title.indexOf(" ", tIndex + 1);
			temp = item.title.indexOf(" ", tIndex + 1);
			counter++;
		}
		if (tIndex < 0) tIndex = Math.min(item.title.length, TITLE_LENGTH);
		const pic = props.pic;
		
		return (
			<div class="w-100" style={{marginTop: "5px"}}>
				<div onClick={() => {window.location.href = shopItem + "/items?id=" + item.itemid;}} 
					style={{fontSize: "14px", color: "#BFA5B8", marginLeft: "5px"}}>
					{item.title.substring(0,tIndex)}
				</div>
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'number' &&
					<div style={{fontSize: "14px", color: "#512A44", marginLeft: "5px"}}>
						{parseFloat(item.price).toLocaleString('en-US')} ETB													
					</div>
				}
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'string' &&
					<div style={{fontSize: "14px", color: "#512A44", marginLeft: "5px"}}>
						{parseFloat(item.price.replace(/,/g,"")).toLocaleString('en-US')} ETB													
					</div>
				}
				{ (!item.price || isNaN(parseFloat(item.price))) && 
					<div style={{fontSize: "14px", color: "transparent", marginLeft: "5px"}}>
						PRICE NOT GIVEN													
					</div>
				}
				<div class="tr-center" style={{margin: "5px 0"}}>
					{ keys.slice(0, 10).map((key) => 
						<div onClick={(e) => {this.setActivePic(e, category, index, key)}} 
							class="pic-protype" 
							style={{backgroundImage: `url(https://deal-corner.com/server/${shopItem}image/${pictures[key]})`,
							outline:  pic == key ? "1px solid #777": "none", outlineOffset: "1px"}}>
						</div>
					)}
				</div>
			</div>
		);
	}
	
	itemInfo = (props) => {
		const item = props.item;
		return (
			<div class="flex-center col highlight" 
				onClick={(e) => {this.unselect(e)}}>
				<div class="flex-center col" style={{flex: 1}}>
					<div class="side-info-icon">
						<div>
							<Location />
						</div>
					</div>
					<div class="side-info-text" style={{flexBasis: "auto", color: "#fff"}}>{item.city}</div>
				</div>
				<div class="ln-center" style={{height: "40px", paddingBottom: "15px"}}>
					{ item.phone && item.phone.length > 0 &&
						<div title={item.phone} class="description-icon" >
							<a href={`tel:${item.phone}`}>
								<Call />
							</a>
						</div>
					}
					{ item.phone && item.phone.length > 0 &&
						<div title={item.phone} class="description-icon">
							<a href={`sms:${item.phone}`}>
								<Message />
							</a>
						</div>
					}
				
					<div title={"More info..."} class="description-icon purple-bg flex-center" 
						onClick={() => {window.location.href = "/items/" + item.id;}} 
						style={{color: "#fff", borderRadius: "3px"}}>
						<div class="dot"></div>
						<div class="dot"></div>
						<div class="dot"></div>
					</div>
				</div>
			</div>
		);
	}
	
	goToMap = (location) => {
		var point;
		try {
			point = JSON.parse(location)[0];
		} catch(e) {}
		if (point) {
			const url = `https://maps.google.com/?q=${point.lat},${point.lng}&ll=${point.lat},${point.lng}&z=15`;
			window.open(url, '_blank');			
		}
	}
	
	shopInfo = (props) => {
		const item = props.data;
		return (
			<div class="tr-center">
				<div class="tr-center" style={{height: "40px", padding: "15px 0 5px 30px", textShadow: "1px 1px #000"}}>
					<div>
						<div style={{fontSize: "26px", fontWeight: "bold", color: "#802bb1", margin: "0 15px"}}>
						{item.name}
						</div>
					</div>					
					<div class="description-icon" style={{backgroundColor: "rgb(247, 136, 136)",cursor: "pointer", borderRadius: "3px", margin: "5px"}}>
						<div onClick={() => {this.goToMap(item.locations); }}>
							<Location />
						</div>
					</div>
					<div title={item.phone} class="description-icon" style={{margin: "5px", cursor: "pointer"}}>
						<a href={`tel:${item.phone}`}>
							<Call />
						</a>
					</div>
				</div>
			</div>
		);
	}
	
	
	
	pictures = () => {
		const url = `https://deal-corner.com/server/get/deals/pictureUpdate`;
		var body = {page: 0, limit: 1000};		
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			e.data.forEach((item) => {
								
			});
		}).catch((e) => {
			
		});
	}
	
	
	
	fetchRecommendations = (body, title) => {
		const url = `https://deal-corner.com/server/get/item/recommendations`;
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {			
			if (e.data && Array.isArray(e.data)) {
				var temp = {};
				if (title && title.length > 0) {				
					temp[title] = e.data;
					this.setState(temp);
				} 
			}
		}).catch((e) => {
			
		});
	}
	
	getInfo = () => {
		const url = `https://deal-corner.com/server/get/information`;
		
		const data = {
			method: 'POST',
			body: JSON.stringify({}),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {			
			if (e.data && Array.isArray(e.data)) {
				this.setState({information: e.data});
			}
		}).catch((e) => {
			
		});
	}
	
	FilteredItems = () => {
		return (
			<div class="grid-2b2" style={{marginTop: "100px", height: "auto"}}>
				{ 	this.state.filteredItems.length > 0 && this.state.filteredItems.map((item) => {
						var pictures = {};
						try {
							pictures = JSON.parse(item.pictures);
						} catch(e) {}
						return (
							<div style={{height: "180px"}} onClick={() => {window.location.href = "/items/" + item.id}}>
								<img style={{height: "90%"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
								<div class="tr-center w-100" style={{width: "100%", fontSize: "10px"}}>{item.title}</div>
								<div class="flex-center">{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
							</div>
						)
					})
				}
				{ 	this.state.filteredItems.length == 0 && 
					<div class="flex-center" style={{width: "100vw", height: "75vh", fontSize: "22px"}}>There is no data for your search.</div>
				}
			</div>
		);
	}
	
	
	
	
	
	/* HomeMessage = () => {

		return (
		<div id="home-message" style={{marginTop: "40px"}}>
		<div  class="home-pic">
		<div style={{position: "relative", border: "1px solid #ccc", height: "100%", boxSizing: "border-box",
		background:`no-repeat center/100% url('${home_image.toString()}')`}}>
						
		</div></div></div>
		);
	} */
	
	
	
	Others = () => {
		var picture1 = "",picture2 = "",picture3 = "",picture4 = "",picture5 = "",picture6 = "",picture7 = "",picture8 = "";
		try {
			picture1 = JSON.parse(this.state["health & beauty"][0].pictures).picture1;
		} catch(e) {}
		try {
			picture2 = JSON.parse(this.state["health & beauty"][1].pictures).picture1;
		} catch(e) {}
		try {
			picture3 = JSON.parse(this.state["health & beauty"][2].pictures).picture1;
		} catch(e) {}
		try {
			picture4 = JSON.parse(this.state["health & beauty"][3].pictures).picture1;
		} catch(e) {}		
		try {
			picture5 = JSON.parse(this.state.others[0].pictures).picture1;
		} catch(e) {}
		try {
			picture6 = JSON.parse(this.state.others[1].pictures).picture1;
		} catch(e) {}
		
		
		
		return (
			<div class="grid-2b2 others">
				<div onClick={() => {window.location.href = "/items/" + this.state["health & beauty"][0].id}}>
					<img src={`https://deal-corner.com/server/image/${picture1}`}  />
					<div class="tr-center w-100" style={{fontSize: "10px"}}>
						{getAdjustedTitle(this.state["health & beauty"][0].title)}
					</div>
					<div class="tr-center w-100">{parseFloat(this.state["health & beauty"][0].price).toLocaleString('en-US')} ETB</div>
				</div>
				<div onClick={() => {window.location.href = "/items/" + this.state["health & beauty"][1].id}}>
					<img src={`https://deal-corner.com/server/image/${picture2}`}  />
					<div class="tr-center w-100" style={{fontSize: "10px"}}>
						{getAdjustedTitle(this.state["health & beauty"][1].title)}
					</div>
					<div class="tr-center w-100">{parseFloat(this.state["health & beauty"][1].price).toLocaleString('en-US')} ETB</div>
				</div>
				<div onClick={() => {window.location.href = "/items/" + this.state["health & beauty"][2].id}}>
					<img src={`https://deal-corner.com/server/image/${picture3}`}  />
					<div class="tr-center w-100" style={{fontSize: "10px"}}>
						{getAdjustedTitle(this.state["health & beauty"][2].title)}
					</div>
					<div class="tr-center w-100">{parseFloat(this.state["health & beauty"][2].price).toLocaleString('en-US')} ETB</div>
				</div>
				<div onClick={() => {window.location.href = "/items/" + this.state["health & beauty"][3].id}}>
					<img src={`https://deal-corner.com/server/image/${picture4}`}  />
					<div class="tr-center w-100" style={{fontSize: "10px"}}>
						{getAdjustedTitle(this.state["health & beauty"][3].title)}
					</div>
					<div class="tr-center w-100">{parseFloat(this.state["health & beauty"][3].price).toLocaleString('en-US')} ETB</div>
				</div>				
				<div onClick={() => {window.location.href = "/items/" + this.state.others[0].id}}>
					<img src={`https://deal-corner.com/server/image/${picture5}`}  />
					<div class="tr-center w-100" style={{fontSize: "10px"}}>
						{getAdjustedTitle(this.state.others[0].title)}
					</div>
					{!isNaN(parseFloat(this.state.others[0].price)) && 
						<div class="tr-center w-100">{parseFloat(this.state.others[0].price).toLocaleString('en-US')} ETB</div>
					}
				</div>
				<div onClick={() => {window.location.href = "/items/" + this.state.others[1].id}}>
					<img src={`https://deal-corner.com/server/image/${picture6}`}  />
					<div class="tr-center w-100" style={{fontSize: "10px"}}>
						{getAdjustedTitle(this.state.others[1].title)}
					</div>
					{!isNaN(parseFloat(this.state.others[1].price)) && 
						<div class="tr-center w-100">{parseFloat(this.state.others[1].price).toLocaleString('en-US')} ETB</div>
					}
				</div>				
			</div>
		);
	}
	
	OthersActivity = () => {
		return (
			<div class="grid-2b2 others loading">
				{Array(8).map(() =>
					<div>
						<div class="w-100" style={{height: "95%"}} />
						<div class="w-100" style={{fontSize: "10px", color: "transparent", backgroundColor: "#ccc"}}></div>
						<div class="w-100" style={{color: "transparent", backgroundColor: "#ccc"}}></div>
					</div>
				)}
			</div>
		);
	}
	
	ElectronicsIcons = (props) => {
		return (
			<div class={`electronics-icons e-icons${props.index}`} style={{width: "100vw", minWidth: "100vw"}}>
			{DETAILS.electronics.Type.slice(props.index*9, (props.index+1)*9).map((item) => 
				<div onClick={() => {window.location.href = `/list?category=electronics&Type=${item}`;}}>
					<div></div>
					<div>{item}</div>
				</div>
			)}
			</div>
		);
	}	
	
	changeDisplayDetails = (e, cat) => {
		e.preventDefault();
		e.stopPropagation();
		const detail = this.state.displayDetails == cat ? "" : cat;
		this.setState({displayDetails: detail });
	}
	
	eIconsTouchStart = (e) => {
		const firstTouchEvent = e.touches[0];			
		this._touchStart = firstTouchEvent.clientX;
	}
	
	eIconsTouchEnd = (e) => {
		const firstTouchEvent = e.changedTouches[0];
		const end = firstTouchEvent.clientX;
		
		if (end - this._touchStart > 50) {
			this.setState({eIconsPage: 0});
		} else if (end - this._touchStart < -50) {
			this.setState({eIconsPage: 1});
		}
	}
	
	getClothingType = (item) => {
		var temp = item.charAt(0);
		for (var i = 1; i < item.length; i++) {
			temp = temp + item.toLowerCase().charAt(i);
		}
		
		this.setState({clothingType: temp});
		this.fetchRecommendations({
			category: "clothing", 
			type: temp, 
			details: "",
			exclude: "Shoes,Watches",
			id: "abc", 
			limit: 4, 
			page: 0}, 
			"Cultural"
		);
	}
	
	onScroll = (e) => {
		sessionStorage.setItem('deal_corner_scroll', e.target.scrollTop);		
		if(e.target && e.target.scrollTop > 400 && !this.state.scrolledDown) {
			this.setState({scrolledDown: true});
		} else if(e.target && e.target.scrollTop < 400 && this.state.scrolledDown) {
			this.setState({scrolledDown: false});
		}
	}
	
	runAnalytics = () => {
		const url = `https://deal-corner.com/server/visitors/analytics`;		
		fetch(url, {headers: { "Content-Type": "application/json" }})
		.then((e) => {}).catch((e) => {});
	}
	
	componentWillUnmount() {
		sessionStorage.removeItem('deal_corner_scroll');
	}
	
	componentDidMount() {		
		const scrollPosition = sessionStorage.getItem('deal_corner_scroll');
	
		if (scrollPosition) {
			const element = document.getElementsByClassName("home-container");
			if (element && element.length > 0) {
				element[0].scrollTop = parseInt(scrollPosition) - 90;
			}
		}
		
		const body = {
			"Cultural":{category: "clothing", type: "Cultural", details: "", id: "abc", limit: 4, page: 0},
			"Shoes":{category: "clothing", type: "Men", details: "Shoes", id: "abc", limit: 10, page: 0},
			"Watches": {category: "clothing", type: "Men", details: "Watches", id: "abc", limit: 10, page: 0},
			"Clothes": {category: "clothing", type: "Women", exclude: "Jackets,Dresses", id: "abc", limit: 10, page: 0}, 
			"Electronics": {category: "electronics", type: "", details: "", id: "abc", limit: 10, page: 0},
			"Cars": {category: "cars", type: "", details: "", id: "abc", limit: 10, page: 0},
			"Houses": {category: "houses", type: "", details: "", id: "abc", limit: 10, page: 0}
		};
		
		["Cultural","Shoes","Watches","Clothes", "Electronics", "Cars", "Houses"].forEach((item) => {
			this.fetchRecommendations(body[item], item);
		});			
				
		//this.fetchPosts("health & beauty", null, 4);
		//this.fetchPosts("others", null, 2);
		this.getInfo();
		this.getShopNames();
		this.runAnalytics();
		
	}
	
	render() {
		const WIDTH = window.screen.width;
		const now = Date.now();
		
		var TEMP_CATEGORIES = JSON.parse(JSON.stringify(CATEGORIES)).slice(0,9).filter((item) => 
		item.toLowerCase() != "hobbies" && item.toLowerCase() != "education" && item.toLowerCase() != "machineries");
		//TEMP_CATEGORIES.splice(6,0,{});
		TEMP_CATEGORIES.splice(5,0,{"type": "others"});
		TEMP_CATEGORIES.splice(3,0,{"type": "houses"});
		TEMP_CATEGORIES.splice(1,0,{"type": "electronics"});
		
		TEMP_CATEGORIES.splice(3,0,{});
		
		//if (this.state.isPageLoading) {return (<HomeActivity />);}
		
		return (
			<div class="home-container rel w-100" style={{overflowX: "hidden"}} onScroll={this.onScroll}>
			
				<Header filter={this.filter} setCategory={this.setCategory} showForm={this.showForm} scrolledDown={this.state.scrolledDown} />
				{this.state.showFilteredItems && this.state.filteredItems && 
					<this.FilteredItems />
				}
				{(!this.state.showFilteredItems || !this.state.filteredItems) &&
				<div style={{maxWidth: "100vw", marginTop: "50px", height: "40vh"}}>
					<div id="home-message" style={{padding: 0}}>
						<div  class="home-pic" style={{padding: 0, height:"40vh"}}>
							<div class={this.state.scrolledDown ? "home-image" : "home-image home-image-anim"}>
						<div></div>
						<div></div>
						</div></div></div>
						<ClothingOptions getSelected={this.getClothingType} />
					<Cultural data={this.state.Cultural} clothingType={this.state.clothingType} />
					<Shoes data={this.state.Shoes} />	
						
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="3228008347" />
						
					<Info data={this.state.information} />
					<Watches data={this.state.Watches} />
					<Clothes data={this.state.Clothes} />
					
					<div class="intro-images electronics flex-center">Buy new and used electronics!</div>
					<div class="flex scroll-container" style={{whiteSpace: "no-wrap", overflowX: "scroll"}} onTouchStart={this.eIconsTouchStart}  onTouchEnd={this.eIconsTouchEnd}>
						<this.ElectronicsIcons index={0} />
						<this.ElectronicsIcons index={1} />
					</div>
					<div class="w-100 flex-center" style={{padding: "10px"}}>
						<div class="e-icons-scroll">
							<div class={this.state.eIconsPage == 0 ? "e-scroller" : ""}></div>
							<div class={this.state.eIconsPage == 1 ? "e-scroller" : ""}></div>
						</div>
					</div>
					<div style={{borderBottom: "1px solid #ccc", margin: "10px 15px", width: "calc(100% - 30px)"}}></div>
					<Electronics data={this.state.Electronics} />
					
					
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="5457787418" />
					

					<Cars data={this.state.Cars} />
					<Houses data={this.state.Houses} />
					<div style={{borderBottom: "1px solid #ccc", margin: "10px 15px", width: "calc(100% - 30px)"}}></div>
					<div style={{margin: "15px", backgroundColor: "#fff"}}>
					<div class="all-others">
						<div class="flex-center col" onClick={() => {window.location.href = `/list?category=${encodeURIComponent('health & beauty')}&Type=`;}}>
							<div></div>
							<div>Health & beauty</div>
						</div>
						<div class="flex-center col" onClick={() => {window.location.href = "/list?category=machineries&Type=";}}>
							<div></div>
							<div>Machineries</div>
						</div>
						<div class="flex-center col" onClick={() => {window.location.href = "/list?category=education&Type=";}}>
							<div></div>
							<div>Education</div>
						</div>
						<div class="flex-center col" onClick={() => {window.location.href = "/list?category=others&Type=";}}>
							<div></div>
							<div>Others</div>
						</div>
					</div>
				{	false && this.state["health & beauty"] && this.state["health & beauty"].length > 3 &&					
					this.state.others && this.state.others.length > 1 &&
					<this.Others />
				}
				{ false && (!this.state["health & beauty"] || this.state["health & beauty"].length <= 3 ||
					!this.state.others || this.state.others.length == 1) && 
					<this.OthersActivity /> 
				}
				
			</div>
					
					
					
					<div class="intro-images flex-center shops">
						<span style={{transform: "translateX(40%)", width: "60%"}}>Visit shops from your home</span>
					</div>
					<div class="deals-container">
						
						{
							this.state.shopNames.map((shop, nameIndex) => {
								const name = shop.name;								
								var DATA2 = this.state.shopItems[name] || [];
								const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;								
								const start = 0;
								if (DATA2.length < 3) return (<div style={{display: "none"}}></div>);
								return (
									<div class="deals-category w-100" style={{backgroundColor: "#fff", marginTop: "25px"}}>
										<div onClick={() => {window.location.href = `/list?Shop=${name}&Id=${shop.id}`;}}>
											<this.shopInfo data={shop}/>
											<div style={{borderBottom: "1px solid #fff", margin: "0 25px 10px 25px", width: "calc(100% - 50px)"}} /></div>
										
											
				<div class="grid-2b2 grid-1b2 electronics-list">
					{ 	DATA2.slice(0,10).map((item, index) => {
							var pictures = {};
							try {
								pictures = JSON.parse(item.pictures);
							} catch(e) {}
							return (
								<div class="rel" onClick={() => {window.location.href = `/shop/items?shopname=${encodeURIComponent(name)}&id=${item.itemid}`}}>
									<img src={`https://deal-corner.com/server/shopItem/image/${pictures.picture1}`}  />
									<div class="tr-center w-100" style={{width: "100%", fontSize: "10px", color: "#aaa"}}>{item.title}</div>
									{ item.quantity == 0 && typeof parseFloat(item.price) == 'number' &&
										<div class="abs" style={{left: "auto", width: "auto", right: "15px", bottom: 0, fontSize: "8px", color: "#f1f1f1", padding: "2px", backgroundColor: "orange", borderRadius: "2px"}}>
											SOLD													
										</div>
									}
									<div class="tr-center w-100">{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
								</div>
							)
						})
					}				
				</div>
			
										</div>
								)
							})
						}
					</div>					
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="7098952395" />
					<div class="footer">   
						<div class="footer-contents">		
							<div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/about">About the website</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/vacancy">Work with us</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/tech">Tech stuff</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/terms">Terms of Use</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/privacy">Privacy policy</a>
								</div>								
							</div>							
							<div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Email:</div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>info@deal-corner.com</div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Phone:</div>
								<div class="flex col" style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>
									<span>+251-974-374148</span>
									<span>+251-956-148721</span>
								</div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Address:</div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>...</div>
							</div>
							<div class="flex-center w-100" style={{padding: "0px"}}>
							<div style={{width: "30px", height: "30px", margin: "10px"}}>
								<a href="https://www.facebook.com/profile.php?id=100086577856191" target="_blank"><Facebook /></a>
							</div>
							<div style={{width: "30px", height: "30px", margin: "10px"}}>
								<a href="https://www.linkedin.com/in/deal-corner-221150247/" target="_blank"><Linkedin /></a>
							</div>
							<div style={{width: "30px", height: "30px", margin: "10px"}}>
								<a href="https://twitter.com/corner_deal" target="_blank"><Twitter /></a>
							</div>
							<div style={{width: "30px", height: "30px", margin: "10px"}}>
								<a href="https://t.me/ethiodealcorner" target="_blank"><Telegram /></a>
							</div>
							<div style={{width: "30px", height: "30px", margin: "10px"}}>
								<a href="https://www.linkedin.com/in/deal-corner-221150247/" target="_blank"><Instagram /></a>
							</div>
							<div style={{width: "30px", height: "30px", margin: "10px"}}>
								<a href="https://www.tiktok.com/@dealcorner?lang=en" target="_blank"><Tiktok /></a>
							</div>
						</div>
						
							<div style={{display: "flex", justifyContent: "center"}}>
								<Feedback />
							</div>
						</div>
						<small style={{color: "#aaa", textAlign: "center"}}>&#169; Copyright 2022, All Rights Reserved</small>
					</div>
				
				</div>
				}
				{
					this.state.isPosting &&
						<div class="form-bg purple-bg">
							<div><Form hideForm={this.closeForm}/></div>
						</div>
				}
				
			</div>		
		); 
	}
}

export default Home;
