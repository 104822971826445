import React from 'react';
import Form from "./Form";
import Header from "./Header";
import Adsense from "./Adsense";

import ExpandedItem from "./ExpandedItem";
import details from '../constants/details.json';
import Badge from "./Badge";
import Activity from "./Activity";
import '../css/main.css';
import OutlineLogo from '../img/logo-outline.svg';


var CRITERIA = "", TYPE="", DETAILS="", SHOW_TYPES = true, EXCLUDE="", PASSWORD = "", SHOP = "", ID = "";

var CATEGORY = "", DEVICE = "mobile";
const CATEGORIES = ["Jobs", "Electronics", "Houses", "Clothing", "Cars", "Machineries", "Hobbies", "Education", "Others"];

const video = ["mp4","mpg","mpeg","ogg","m4v","wmv","avi","flv","swf","mkv","rm","3g2","3gp","h264","mov","vob"];  
const audio = ["aif","cda","mid","midi","mp3","wav","wma", "wpl"];

var CONTENT_LENGTH = window.screen.width < 768 ? 60 : 90;
var CRITERIA = "", TYPE="", DETAILS="", EXCLUDE="";
class List extends React.Component {
	state={
		ad: "",
		city: "",
		action: "",
		password: "",
		popMessage: "",
		editIndex: -1,
		pictureIndex: -1,
		data: {},
		page: 0,
		temp: {},
		isPosting: false,
		isDeleting: false,
		canEdit: false,
		showActivity: true,
		showDetails: false,
		activeData: null,
		showElectronicsType: false,
		electronicsType: ""
	}
	
	showForm = () => {
		this.setState({isPosting: true});
	}
		
	closeForm = () => {
		this.setState({isPosting: false, showDetails: false, activeData: null});
	}
	
	picIndex = (pictures) => {
		var index = -1;
		
		
		Object.keys(pictures).forEach((key, i) => {
			if (pictures[key] && pictures[key].length > 0) {
				if (index == -1) {
					index = parseInt(key.split("picture")[1]);
				}
			}
		});
		
		 
		return index;
	}
	
	/* picIndex = (data) => {		
		const hasPicture1 = data.picture1.length > 0;
		const hasPicture2 = data.picture2.length > 0;
		const hasPicture3 = data.picture3.length > 0;
		var index = -1;
		
		if (hasPicture1) {
			index = 1;
		} else if (hasPicture2) {
			index = 2;
		} else if (hasPicture3) {
			index = 3;
		}
		 
		return index;
	}	
	
 */	
	viewMore = () => {
		this.fetchPosts(CATEGORY, this.state.page + 1);
	    this.setState({page: this.state.page + 1});		
	}
	
	
	
	filter = (txt) => {
		const cat = CATEGORY.toLowerCase();
		CRITERIA = txt ? txt.toLowerCase() : "";
		//if (!txt || txt.length <= 1) {
			var temp = {};
			temp[cat] = [];
			this.setState({data: temp});
		//}
		if (this.state.page > 0) {
			this.setState({page: 0});
		}
		this.fetchPosts(cat);
	}
	
	fetchPosts = (txt, page) => {		
		var url;
		if (SHOP && SHOP.length > 0) {
			url = `https://deal-corner.com/server/shopItems/get/recommendations`;
		} else if (CATEGORY == "information") {
			url = `https://deal-corner.com/server/get/information/recommendations/${PASSWORD}`;
		} else {
			url = `https://deal-corner.com/server/get/item/recommendations`;
		}
		
		var d = [""];
		if (DETAILS) {
			d = DETAILS.split(",");
		}
		
		d.forEach((item) => {
			var body = {};
			if (SHOP && SHOP.length > 0) {
				body.id = ID;
			} else {
				body = {category: CATEGORY, type: TYPE, details: item, page: page ? page : 0, limit: 20};
			}
			if (TYPE && TYPE.length > 0) {			
				body.criteria = TYPE;
			}
			
			const data = {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
					"Accept-Charset": "UTF-8"
				}			
			};
		
			fetch(url, data).then((e) => e.json())
			.then((e) => {	
				if (SHOP && SHOP.length > 0) {				
					const temp = this.state.data[CATEGORY] || [];
					if (e.data && e.data.length > 10) {
						e.data.splice(10, {}, 0);
					}
					const temp2 = [...temp, ...e.data];
					const temp3 = {};
					temp3[CATEGORY] = temp2;
					this.setState({data: temp3, temp: temp3, showActivity: false});
				} else if (typeof e.data !== "string") {				
					const temp = this.state.data[CATEGORY] || [];
					if (e.data && e.data.length > 10) {
						e.data.splice(10, {}, 0);
					}
					const temp2 = [...temp, ...e.data];
					const temp3 = {};
					temp3[CATEGORY] = temp2;
					this.setState({data: temp3, temp: temp3, showActivity: false});
				} else {
					const city = this.state.city.length > 0 ? this.state.city : "Ethiopia";
					this.setState({showActivity: false, message: `There are no ${txt} posted in ${city} currently.`});
				}
			})
			.catch((e) => {
				const city = this.state.city.length > 0 ? this.state.city : "Ethiopia";
				this.setState({showActivity: false, message: `There are no ${txt} posted in ${city} currently.`});
			});
		});		
	}
	
	/* filter = (txt) => {
		if (txt.length == 0) {
			this.setState({temp: this.state.data});
			return;
		} 
		txt = txt.toLowerCase();
		var selected = {};
				
		if (this.state.data[CATEGORY.toLowerCase()]) {
			selected[CATEGORY.toLowerCase()] = this.state.data[CATEGORY.toLowerCase()].filter((item) => {
				return item.title.toLowerCase().indexOf(txt) != -1 ||
				item.content.toLowerCase().indexOf(txt) != -1 ||	
				item.details.toLowerCase().indexOf(txt) != -1 ||	
				item.city.toLowerCase().indexOf(txt) != -1;
			});
		}
			
		
		this.setState({temp: selected});
	} */	
	
	setElectronicsType = (item) => {
		var temp;
		if (item.length > 0) {
			temp = this.state.temp.filter((i) => {
				const array = i.category.split("-");
				
				if (array.length > 1 && array[1].length > 0) {
					return array[1].toLowerCase() == item.toLowerCase();
				}
				return false;
			});
		} else {
			temp = this.state.list;
		}
		this.setState({showElectronicsType: false, electronicsType: item.toLowerCase(), temp: temp});
	}
	
	showETypeMenu = (e) => {
		e.stopPropagation();
		this.setState({showElectronicsType: !this.state.showElectronicsType});
	}
	
	content = (txt) => {		
		var modified = txt.replace(/\n/g, "<br />");
		return modified;
	}
	
	Types = () => {		
		return(
			<div class="list-types tr-center">
				<div style={{borderBottom: this.state.listType == "All" ? "3px solid #000": "none"}}
					onClick={() => {this.setState({listType: "All", data: {}});TYPE = ""; DETAILS="";this.fetchPosts();}}>All
				</div>
				{ 	details[CATEGORY].Type.map((item) => 
						<div style={{borderBottom: this.state.listType == item ? "3px solid #000": "none"}}
							onClick={() => {this.setState({listType: item, page: 0, data: {}});TYPE = item;DETAILS=""; this.fetchPosts();}}>{item}
						</div>
					)
				}
			</div>
		);
	}
	
	TypeDetails = () => {
		return(
			<div class="list-types tr-center">
				<div style={{borderBottom: this.state.listType == " " ? "3px solid #aa0000": "none"}}
					onClick={() => {this.setState({typeDetail: "All", data: {}});DETAILS = ""; this.fetchPosts();}}>&nbsp;&nbsp;
				</div>
				{ 	details[CATEGORY].Details[this.state.listType].map((item) => 
						<div style={{borderBottom: this.state.typeDetail == item ? "3px solid #00aa00": "none"}}
							onClick={() => {this.setState({typeDetail: item, page: 0, data: {}});DETAILS = item; this.fetchPosts();}}>{item}
						</div>
					)
				}
			</div>
		);
	}
	
	componentDidMount() {		
		const paramsString = window.location.search.substring(1);
		let searchParams = new URLSearchParams(paramsString);
		//(window.adsbygoogle = window.adsbygoogle || []).push({})
        
		CATEGORY = searchParams.get("category");
		TYPE = searchParams.get("Type");
SHOP = searchParams.get("Shop");
		ID = searchParams.get("Id");
		if (SHOP && SHOP.length > 0) {
			CATEGORY = SHOP;
		}		
		DETAILS = searchParams.get("Details");
		PASSWORD = searchParams.get("password");
		SHOW_TYPES = !TYPE || TYPE.length == 0;
		
		if (!SHOW_TYPES) {
			this.setState({ listType: TYPE });
		}
		
		EXCLUDE = searchParams.get("exclude");
		const id = "";
		window.addEventListener("scroll", this.onScroll);
				
		this.fetchPosts();
		
		if (window.screen.width < 768) {
			DEVICE = "mobile";
			CONTENT_LENGTH = 40;
		} else if (window.screen.width < 992) {
			DEVICE = "tab";
			CONTENT_LENGTH = 150;
		} else {
			DEVICE = "pc";
			CONTENT_LENGTH = 250;
		}
	}
	
	render() {
		if (this.state.showActivity) {
			return (
				<div class="fullscreen">
					<Activity />
				</div>
			);
		}	
		
		const now = Date.now();
		
		return (
			<div class="home-container rel" style={{paddingTop: "50px"}}>
				<Header filter={this.filter} showForm={this.showForm} />
				{	SHOW_TYPES && details[CATEGORY] &&
					<div class="w-100 flex-center"><this.Types /></div>
				}
				{	this.state.listType != "All" && details[CATEGORY] && details[CATEGORY].Details[this.state.listType] && 
					details[CATEGORY].Details[this.state.listType].length > 0 &&
					<div class="w-100 flex-center"><this.TypeDetails /></div>
				}
				{ ((this.state.temp[CATEGORY.toLowerCase()] && this.state.temp[CATEGORY.toLowerCase()].length > 0) || 
				(SHOP && SHOP.length > 0 && this.state.temp[CATEGORY] && this.state.temp[CATEGORY].length > 0)) &&
					<div class="cat-list-container" style={{maxWidth: "100vw", overflowX: "hidden"}}>
						<div class="list-ad">
							
					<Adsense style={{display: "block", minWidth:"300px", width:"100%",minHeight:"600px", height:"100%"}} slot="6841067568" />
						
						</div>
						<div class="cat-list-category w-100">	
									
					<Adsense style={{display: "block", minWidth:"200px", width:"100%"}} slot="4115440688" />
					
								
							{
								(this.state.temp[CATEGORY.toLowerCase()] || this.state.temp[CATEGORY]).map((item, index) => {
									
									if (Object.keys(item).length == 0) {
											return (
												
					<Adsense style={{display: "block", width:"100%"}} slot="2008935913" format="fluid" />
					
											)
										}
									
									
									
									if (CATEGORY == "information") {
										const ext = item.file_name.substring(item.file_name.lastIndexOf("."));
										return (
											<div>
												<div class="cat-list">
													<div class="flex">
														<div class="w-100 ln-center rel">
															{	ext && ext == ".txt" &&
																<img class="bg bg-icon" style={{objectFit: "cover", padding: 0}} src={`${OutlineLogo.toString()})`}/>
															}
															{	ext && ext != ".txt" &&
																<video controls>
																	<source src={`https://deal-corner.com/server/information/items/${item.file_name}`} />
																</video> 
															}
															<div style={{padding: "10px 0 10px 10px", width: "50%"}}>
																<div class="list-title">{item.title.toUpperCase()}</div>
																<div class="list-content">
																	{item.title}...
																</div>
																<div class="ln-center" style={{padding: "10px", marginTop: "10px"}}>
																	<div class="flex-center list-button" style={{wordBreak: "break-all", backgroundColor: "#333"}}>
																		{ext == ".txt" ? "Text " : audio.indexOf(ext.substring(1)) != -1 ? "Audio " : "Video"} file
																	</div>
																	<div class="flex-center list-button" style={{cursor: "pointer", backgroundColor: "#aa0"}} 
																		onClick={() => {window.location.href = `/info?category=${item.category}&title=${item.title}&shopName=${SHOP}&keywords=${item.keywords}&author=${item.author}&phone=${item.phone}&isApproved=${item.approved}&telegram=${item.telegram}&
									date=${item.date}&views=${item.views}&likes=${item.likes}&dislikes=${item.dislikes}&file_name=${item.file_name}&id=${item.itemid || item.id}&password=${item.password}`}}>More...
																	</div>
																</div>
															</div>
														</div>
													</div>
													
					<Adsense style={{display: "block", width:"100%"}} slot="6094477005" format="autorelaxed" />
					
												</div>
											</div>
										)
									} else {										
										var details = {}, pictures = {};										
										try {
											pictures = JSON.parse(item.pictures);
										} catch(e) {}
										try {
											details = JSON.parse(item.details);
										} catch(e) {}
										const picIndex = this.picIndex(pictures);
										var src;
if (SHOP && SHOP.length > 0) {										
										src = `https://deal-corner.com/server/shopItem/image/${pictures["picture"+picIndex]}`;
} else {
	src = `https://deal-corner.com/server/image/${pictures["picture"+picIndex]}`;
}
										return (
											<div>
												<div class="cat-list">
													<div class="flex">
														<div class="w-100 ln-center rel">
															<img class={picIndex > 0 ? "bg bg-pic" : "bg bg-icon"} style={{objectFit: "cover", padding: 0}}
																src={picIndex > 0 ? 
																src: `${OutlineLogo.toString()})`}/>
															{ details["Has delivery"] == "Yes" &&
																<Badge x1="100" y1="120" x2="50" y2="250" text1= "Free" text2= "delivery" />
															}
															<div style={{padding: "10px 0 10px 10px", width: "50%"}}>
																<div class="list-title">{item.title.toUpperCase()}</div>
																	{item.content && 
																	<div class="list-content">
																	{item.content.substring(0, CONTENT_LENGTH)}...
																</div>
																	}
																<div class="ln-center" style={{padding: "10px", marginTop: "10px"}}>
																	<div class="flex-center list-button" style={{wordBreak: "break-all", backgroundColor: "#333"}}>
																		{parseFloat(item.price).toLocaleString()}
																	</div>
																	<div class="flex-center list-button" style={{cursor: "pointer", backgroundColor: "#aa0"}} 
																		onClick={(e) => {this.setState({showDetails: true, activeData: item});
																		window.location.href = `${SHOP && SHOP.length > 0 ? "/shop" : ""}/items?category=${item.category}&title=${item.title}&shopName=${SHOP}&type=${details.Type}&details=${details.Details}&id=${item.itemid || item.id}`;}}>More...
																	</div>
																</div>
															</div>
														</div>
													</div>
													
					<Adsense style={{display: "block", width:"100%"}} slot="6094477005" format="autorelaxed" />
					
												</div>
											</div>
										)
									}
								})
							}
							{ (this.state.temp[CATEGORY.toLowerCase()] || this.state.temp[CATEGORY]).length >= 20 && 
								<div class="view-more" onClick={this.viewMore}>View More ...</div>
							}							
						</div>
						<div class="list-ad">
							
					<Adsense style={{display: "block", minWidth:"300px", width:"100%",minHeight:"600px", height:"100%"}} slot="1588740885" />
				
						</div>
					</div>
				}
				{(!this.state.temp[CATEGORY.toLowerCase()] || this.state.temp[CATEGORY.toLowerCase()].length == 0) &&	
					<div class="purple-cl flex-center" 
						style={{fontSize: "24px", flex: 1, height: "75vh"}}>
						There are no results.
					</div>
				}
				{ this.state.popMessage.length > 0 && 
					<div class = "pop-up">
						<small>{this.state.popMessage}</small>
					</div>
				}
				{ this.state.isPosting &&
					<div class="form-bg purple-bg">
						<div><Form hideForm={this.closeForm}/></div>
					</div>
				}
			</div>	
		); 
	}
}

export default List;
