import React from 'react';
import '../css/home.css';

class Details extends React.Component {	
	
	render() {	
	    const data = this.props.detail;
		return (
			<div class="form-container">
				<div class="form">
					<div class="close btn" onClick={() => this.props.close()}><span>&#10006;</span></div>
					<div class="bold">{data.title}</div>
					<div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", margin: "10px"}}>
						<small><strong>Location:</strong>&nbsp;{data.city}</small>
						<small><strong>Date posted:</strong>&nbsp;{data.date}</small>
					</div>
					<div style={{fontSize: "14px", width: "100%", border: "1px solid #e1e1e1", padding: "10px", 
					borderRadius: "4px", fontStyle: "italic", display: "flex",
					flexDirection: "column"}}><span style={{marginBottom: "15px"}}>{data.content}</span>
						{data.picture1.length > 0 && 
							<img width="350px" height="350px" src={`https://deal-corner.com/server/image/${data.picture1}`} />
						}
					</div>
				</div>
			</div>
		); 
	}
}

export default Details;
