import React from 'react';
import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';
import Activity from "./Activity";
import Header from "./Header";
import Adsense from "./Adsense";

import { ReactComponent as Eye } from '../img/eye.svg';
import { ReactComponent as Check } from '../img/check.svg';
import { ReactComponent as Delete } from '../img/delete.svg';
import { ReactComponent as Undo } from '../img/undo.svg';
import { ReactComponent as Add } from '../img/add-plus.svg';
import { ReactComponent as Logo } from '../img/logo.svg';
import CATEGORIES from '../constants/categories.json';
import DETAILS from '../constants/details.json';
import '../css/main.css';

const init = {
		id: null,
		title: "",
		city: "",
		category: "",
		content: "",
		details: {},
		invalids: [],
		price: "",
		quantity: "",
		phone: "",
		telegram: "",
		password: "",
		showActivity: false,
		userTypeSelected: false,
		shopOwner: false,
		isSending: false,
		nPictures: 3,
		deletedPics: [],
		shops: [],
		successMessage: "",
		errorMessage: ""
	};
	
class Form extends React.Component {
	state=init
	
	reset = () => {
		this.setState({
			id: null,
			title: "",
			content: "",
			price: "",
			quantity: "",
			showActivity: false,
			userTypeSelected: false,
			shopOwner: false,
			isSending: false,
			nPictures: 3,
			deletedPics: [],
			shops: [],
			successMessage: "",
			errorMessage: ""
		});
	}
	
	showPassword = () => {
		const element = document.getElementById("password");
		if (element) {
			element.type = element.type == "password" ? "text" : "password";
		}
	}
	
	submit = () => {
		if (this.state.shopOwner) {
			if (!this.state.itemid) {
				this.addShopItem();
			} else {
				this.updateShopItem();
			}
		} else {
			if (!this.state.id) {
				this.add();
			} else {
				this.update();
			}
		}
	}
	
	/* changeDetailType = (e) => {
		const type = e.target.value;
		var details = this.state.details;
		details.type = type;		
		this.setState({ details: details, delivery: "No" });
	}
	 */
	changeDetail = (key, e) => {
		const type = e.target.value;
		var details = this.state.details;
		details[key] = type;		
		this.setState({ details: details});
	}
	restorePicture = (pic) => {
		var deletedPics = this.state.deletedPics;
		var index = deletedPics.indexOf(pic);
		if (index > -1) {
			deletedPics.splice(index, 1);
		} else {
			deletedPics.push(pic);
		}
		this.setState({ deletedPics: deletedPics });
	}
	/* changeClothCategory = (e) => {
		const cat = e.target.value;
		var details = this.state.details;
		details.category = cat;
		this.setState({ details: details });
	}
	 */
	changeDelivery = (e) => {
		const hasDelivery = e.target.value;
		var details = this.state.details;
		details.hasDelivery = hasDelivery;
		this.setState({ details: details });
	}
	
	requiredUpdate = (type, txt) => {
		var invalids = this.state.invalids; 
		var index = invalids.indexOf(type);
		var temp = {errorMessage: ""};
		temp[type] = txt;
		if (invalids.length > 0 && txt && txt.length > 0 && index >= 0) {
			invalids.splice(index, 1);
			temp.invalids = invalids;				
		}
		this.setState(temp);
	}
	
	add = () => {
		var category = this.state.category.toLowerCase();
		let pattern = /^\d+$/;
		
		if (category != "hobbies" && category != "others" && !pattern.test(this.state.price)) {
			var invalids = this.state.invalids;
			invalids.push("price");
			if (!this.state.title || this.state.title.length == 0) invalids.push("title");
			if (!this.state.city || this.state.city.length == 0) invalids.push("city");
			this.setState({ errorMessage: "Price should only be a number.", invalids: invalids });
			return;
		}
	
	    if (this.state.title.length > 0 && this.state.city.length > 0) {
			const url = `https://deal-corner.com/server/deal/add`;	
            const id = uuidv4();
			var inputs = document.getElementsByClassName('picture');
			
			
			var formData = new FormData();			
			formData.append("id", id);
			formData.append("title", this.state.title);
			formData.append("city", this.state.city);
			formData.append("category", category);
			formData.append("details", JSON.stringify(this.state.details));
			if (this.state.password.length > 0) {
				formData.append("password", jwt.sign(this.state.password, "_deal_corner"));
			}
			formData.append("content", this.state.content);
			formData.append("price", this.state.price);
			formData.append("phone", this.state.phone);
			formData.append("telegram", this.state.telegram);
								
			for (const input of inputs) {
				if (input.files) {
					for (const file of input.files) {
						const temp = input.files;
						formData.append("picture",file,file.name.replace(/\s/g,""));	
					}	
				}				
			}   
			
			const data = {
				"method": "POST",
				"accept-charset": "UTF-8",
				"accept-language": "am,en",
				"body": formData
			};
			this.setState({ isSending: true });
			fetch(url, data).then((e) => e.json())
			.then((e) => {	
				this.setState({successMessage: "Your data is submitted successfully!"});
			})
			.catch((e) => {				
				this.setState({isSending: false, errorMessage: "Check if you entered all required fields or uploaded only image files."});
			});
			
		} else {
			var invalids = [];
			if (!this.state.title || this.state.title.length == 0) invalids.push("title");
			if (!this.state.city || this.state.city.length == 0) invalids.push("city");
			this.setState({errorMessage: "Fill all required fields.", invalids: invalids});
		}
	}	
	
	addShopItem = () => {
		const data = this.state.shops.filter((item) => item.id == this.state.name);
		var pwd = data.length > 0 ? data[0].password : ""; 
		jwt.verify(pwd, "_deal_corner", (err, decoded) => {					
			if (this.state.password == "_deal_123_corner" || (!err && decoded == this.state.password)) {
					
				let pattern = /^\d+$/;
				
				if (!pattern.test(this.state.price)) {
					this.setState({ errorMessage: "Price should only be a number." });
					return;
				}
	
				if (this.state.title.length > 0) {
					const url = `https://deal-corner.com/server/shopItem/add`;	
					const itemid = uuidv4();
					var inputs = document.getElementsByClassName('picture');
					
					
					var formData = new FormData();
					formData.append("id", this.state.name);
					formData.append("itemid", itemid);
					formData.append("title", this.state.title);
					formData.append("details", JSON.stringify(this.state.details));	
					formData.append("price", this.state.price);					
										
					for (const input of inputs) {
						if (input.files) {
							for (const file of input.files) {
								const temp = input.files;
								formData.append("picture",file,file.name.replace(/\s/g,""));	
							}	
						}						
					}   
					
					const data = {
						"method": "POST",
						"accept-charset": "UTF-8",
						"accept-language": "am,en",
						"body": formData
					};
					this.setState({ isSending: true });
					fetch(url, data).then((e) => e.json())
					.then((e) => {	
						this.setState({successMessage: "Your data is submitted successfully!"});
					})
					.catch((e) => {
						this.setState({isSending: false, errorMessage: "Check if you entered all required fields or uploaded only image files."});
					});
					
				} else {
					this.setState({errorMessage: "Fill all required fields."});
				}
			} else {
				this.setState({errorMessage: "Incorrect password!"});
			}
		});
	}	
	
	update = () => {
		const category = this.state.category.toLowerCase();
		let pattern = /^\d+$/;
		
		if (category != "hobbies" && category != "others" && !pattern.test(this.state.price)) {
			this.setState({ errorMessage: "Price should only be a number." });
			return;
		}
		
	    if (this.state.title.length > 0 && this.state.city.length > 0) {
			const url = `https://deal-corner.com/server/deal/update`;	
            
			var inputs = document.getElementsByClassName('picture');
			
			var formData = new FormData();			
			
			formData.append("id", this.state.id);
			formData.append("title", this.state.title);
			formData.append("city", this.state.city);
			formData.append("category", category);			
			formData.append("content", this.state.content);
			formData.append("details", JSON.stringify(this.state.details));
			formData.append("price", this.state.price);
			formData.append("phone", this.state.phone);	
			formData.append("telegram", this.state.telegram);	
			
			if (this.state.id) {
				var pictures = this.state.pictures;
				
				const keys = Object.keys(pictures);				
				keys.forEach((key) => {					
					if (this.state.deletedPics.indexOf(pictures[key]) != -1) {
						delete pictures[key];
					}
				});
				
				var updated = {};
				Object.keys(pictures).forEach((key, index) => {
					updated["picture" + (index + 1)] = pictures[key];
				});
				
				formData.append("pictures", JSON.stringify(updated));
				formData.append("deletedPictures", JSON.stringify(this.state.deletedPics));
			}
						
			for (const input of inputs) {
				if (input.files) {
					for (const file of input.files) {
						const temp = input.files;
						formData.append("picture",file,file.name.replace(/\s/g,""));	
					}			
				}   
			}
			
			const data = {
				"method": "POST",
				"accept-charset": "UTF-8",
				"accept-language": "am,en",
				"body": formData
			};
			this.setState({ isSending: true });
			fetch(url, data).then((e) => e.json())
			.then((e) => {	
				this.setState({successMessage: "Your data is updated successfully!" });				
			})
			.catch((e) => {
				this.setState({isSending: false, errorMessage: "Check if you entered all required fields or uploaded only image files."});
			}); 
			
		} else {
			this.setState({errorMessage: "Fill all required fields."});
		}
	}
	
	updateShopItem = () => {
		let pattern = /^\d+$/;
		
		if (!pattern.test(this.state.price)) {
			this.setState({ errorMessage: "Price should only be a number." });
			return;
		}
		
	    if (this.state.title.length > 0) {
			const url = `https://deal-corner.com/server/shopItem/update`;	
            
			var inputs = document.getElementsByClassName('picture');
			
			var formData = new FormData();			
		
			if (this.state.itemid) {
				formData.append("id", this.state.id);
				formData.append("itemid", this.state.itemid);
				formData.append("title", this.state.title);
				formData.append("content", this.state.content);
				formData.append("details", JSON.stringify(this.state.details));
				formData.append("price", this.state.price);
				formData.append("quantity", this.state.quantity);
				
				var pictures = this.state.pictures;
				
				const keys = Object.keys(pictures);				
				keys.forEach((key) => {					
					if (this.state.deletedPics.indexOf(pictures[key]) != -1) {
						delete pictures[key];
					}
				});
				
				var updated = {};
				Object.keys(pictures).forEach((key, index) => {
					updated["picture" + (index + 1)] = pictures[key];
				});
				
				formData.append("pictures", JSON.stringify(updated));
				formData.append("deletedPictures", JSON.stringify(this.state.deletedPics));			
			}		
			for (const input of inputs) {
				if (input.files) {
					for (const file of input.files) {
						const temp = input.files;
						formData.append("picture",file,file.name.replace(/\s/g,""));	
					}			
				}   
			}
			
			const data = {
				"method": "POST",
				"accept-charset": "UTF-8",
				"accept-language": "am,en",
				"body": formData
			};
			this.setState({ isSending: true });
			fetch(url, data).then((e) => e.json())
			.then((e) => {	
				this.setState({successMessage: "Your data is updated successfully!" });				
			})
			.catch((e) => {
				this.setState({isSending: false, errorMessage: "Check if you entered all required fields or uploaded only image files."});
			}); 
			
		} else {
			this.setState({errorMessage: "Fill all required fields."});
		}
	}
	
	changeShopName = (e) => {
		var id = e.target.value;
		const selected = this.state.shops.filter((item) => item.id == parseInt(id));		
		var cat = selected.length == 1 ? selected[0].category: "";		
		cat = cat.length > 0 ? cat.split(" - ") : [];		
		this.setState({ name: e.target.value, category: cat[0] ? cat[0].toLowerCase() : "" });
	}
	
	fetchShops = () => {
		const url = "https://deal-corner.com/server/shops/get";
					
		const data = {
			headers: {
				"Content-Type": "application/json"
			}			
		};
		 
		fetch(url, data).then((e) => e.json())
		.then((e) => {						
			if (e.data && Array.isArray(e.data)) {
				this.setState({shops: e.data});
			} 
		})
		.catch((e) => {
			//this.setState({showActivity: false, successMessage: "", errorMessage: e.message});
		});
	}
	
	fetchData = (id, type) => {
		var url;
		
		if (type == "shop") {
			url= `https://deal-corner.com/server/shopItems/get`;
		} else {
			url= `https://deal-corner.com/server/get/deals/items`;
		}
		var body = { id: id };	
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			const data = e.data.items ? e.data.items[0] : e.data[0];
			var details = {}, pictures = {};
			try {
				details = JSON.parse(data.details);
			} catch(e) {}
			try {
				pictures = JSON.parse(data.pictures);
			} catch(e) {}
			console.log(data);
			this.setState({
				shopOwner: data.itemid ? true : false,
				name: data.name,
				itemid: data.itemid,
				title: data.title,
				city: data.city,
				category: data.category,
				content: data.content,
				price: data.price,
				quantity: data.quantity,
				details: details,
				phone: data.phone,
				telegram: data.telegram,
				pictures: pictures,
				showActivity: false
			});					
		}).catch((e) => {
			
		});
	}
	
	componentDidMount() {
		if (this.props.data) {
			const id = sessionStorage.getItem(this.props.data);
			const type = sessionStorage.getItem("type");
			const itemid = sessionStorage.getItem("itemid");			
			this.setState({showActivity: true, id: id });			
			if (itemid && itemid != "undefined") {
				this.fetchData(itemid, type);
			} else if (id) {
				this.fetchData(id, type);
			}
		}
		
	}
	
	render() {
		if (this.state.showActivity) {
			return (
				<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
						<Header filter={() => {}}  />
						<div class="flex-center col" style={{flex: 1,width: "100vw", maxWidth: "400px", alignSelf: "center", marginTop: "50px", 
						padding: "15px 25px", boxSizing: "border-box"}}>
						<Activity small="small" />
						<small>processing...</small>
					</div>
				</div>
			);
		}
		
		if (!this.state.userTypeSelected && !this.state.id) {
			return (
				<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh"}}>
						<Header filter={() => {}}  />
						
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="2577291448" />
					
						<div class="flex-center col" style={{flex: 1,marginTop: "30px", width: "100vw", maxWidth: "468px", alignSelf: "center", 
						padding: "15px"}}>
					
					<div class="flex-center w-100">
					<div style={{width: "200px", height: "200px", border: "1px solid purple", borderRadius: "5px"}} class="flex-center" >
						<div style={{width: "100px", height: "100px"}}>
							<Logo />
						</div>
					</div>
					</div>
					<div class="flex-center w-100">
					<div class="ln-center col" style={{margin: "20px 0"}}>
						<div class="tr-center" style={{marginTop: "10px",marginLeft: "15px"}}>
							<div class="flex-center purple-cl radio" onClick={() => {this.setState({shopOwner: false })}}>
								{ !this.state.shopOwner && <div></div> }
							</div>
							<div class="purple-cl" style={{padding: "5px"}}>I am individual poster.</div>
						</div>
						<div class="tr-center" style={{marginTop: "10px",marginLeft: "15px"}}>
							<div class="flex-center purple-cl radio" 
								onClick={() => {this.setState({shopOwner: true });this.fetchShops();}}>
								{ this.state.shopOwner && <div></div> }
							</div>
							<div class="purple-cl" style={{padding: "5px"}}>I am a registered shop owner.</div>
						</div>
					</div>
					</div>
					<div class="button" style={{margin: "20px 0", alignSelf: "center"}} onClick={() => {this.setState({ userTypeSelected: true });}}>
						Show form
					</div>
				</div></div>
			);
		}
		
		if (this.state.successMessage.length > 0) {
			return (
				<div class="w-100 flex-center col" style={{color: "#444", minHeight: "100vh"}}>
						<Header filter={() => {}}  />
						<div class="flex col" style={{flex: 1, width: "100vw", maxWidth: "468px", alignSelf: "center",marginTop: "50px", 
						padding: "15px"}}>
					
					<div class="form-message" style={{color: "#000", marginTop: "100px", padding: "30px", textAlign: "center"}}>
						{this.state.successMessage}
					</div>
					<div style={{color: "#000", fontSize: "16px", margin: "50px 20px", textAlign: "center"}}>
						<a onClick={this.reset} style={{textDecoration: "underline", cursor: "pointer"}}>Post</a> another one.
					</div>
					<div class="button" style={{alignSelf: "center"}}
						onClick={() => {window.location.href = this.props.refreshTo || "/";}}>
						Close
					</div>
				</div>
					
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="2577291448" />
						
				</div>
			);
		}
		const cat = this.state.category ? this.state.category.toLowerCase(): "";
		const arrays = DETAILS[cat] ? Object.keys(DETAILS[cat]).filter((item) => Array.isArray(DETAILS[cat][item])): null;
		const details = DETAILS[cat] ? DETAILS[cat].Details[this.state.details.Type]: null;
		var pictures = this.state.pictures;
		const picturesKeys = pictures && typeof pictures == "object" ? Object.keys(pictures) : [];
		
				
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
						<Header filter={() => {}}  />
						<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
							
				
				{ !this.state.shopOwner &&
					<div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
						<div style={{padding: "5px", color: "#000", width:"30%"}}>Category:</div>
						<select class="purple-cl" value={this.state.category} 
							onChange={(e) => this.setState({category: e.target.value})}>
							<option value=""></option>
							{
								CATEGORIES.map((item) => <option value={item.toLowerCase()}>{item}</option>)
							}
						</select>
					</div>
				}
				{ this.state.shopOwner &&
					<div class="select-container">
						<div class="purple-cl select-label">Shop name:</div>
						<select class="purple-cl" value={this.state.name} 
							onChange={this.changeShopName}>
							{ (this.state.shops && this.state.shops.length > 0) && <option value=""></option> }
							{ (this.state.name && (!this.state.shops || this.state.shops.length == 0)) && <option value={this.state.name}>{this.state.name}</option> }
							{
								this.state.shops.map((item) => <option value={item.id}>{item.name}</option>)
							}
						</select>
					</div>
				}
				{ arrays && 
					<div>
						{
							arrays.map((item) => {
								return (
									<div class="select-container">
										<div class="purple-cl select-label">{item}:</div>
										<select class="purple-cl" value={this.state.details[item]} 
											onChange={(e) => {this.changeDetail(item,e);}}>
											<option value=""></option>
											{
												DETAILS[cat][item].map((i) => <option value={i}>{i}</option>)
											}
										</select>
									</div>
								);
							})
						}
					</div>
				}
				{ details && 
					<div class="select-container">
						<div class="purple-cl select-label">Details:</div>
						<select class="purple-cl" value={this.state.details.Details} 
							onChange={(e) => {this.changeDetail("Details",e);}}>
							<option value=""></option>
							{
								details.map((i) => <option value={i}>{i}</option>)
							}
						</select>
					</div>	
				}
				
				<input class="full-width form" type="text" placeholder="Title" required value={this.state.title} 
					style={{borderColor: this.state.invalids.indexOf("title") == -1 ? "#777" : "#c00"}}
					onChange={(e)=> {this.requiredUpdate("title", e.target.value)}} />
				
				{ !this.state.shopOwner &&
					<input class="full-width form" type="text" placeholder="City" required value={this.state.city} 
						style={{borderColor: this.state.invalids.indexOf("city") == -1 ? "#777" : "#c00"}}
						onChange={(e)=> {this.requiredUpdate("city", e.target.value)}} />
				}
				
				{ !this.state.shopOwner &&
					<textarea class="full-width form" rows="6" type="text" placeholder="Message" value={this.state.content} 
						onChange={(e)=> this.setState({content: e.target.value, errorMessage: ""})}></textarea>
				}
				
				<input class="full-width form" type="text" placeholder="Price/Salary" value={this.state.price}
					style={{borderColor: this.state.invalids.indexOf("price") == -1 ? "#777" : "#c00"}}
					onChange={(e)=> {this.requiredUpdate("price", e.target.value)}} />
				
				{ this.state.shopOwner &&
					<input class="full-width form" type="text" placeholder="Quantity" required value={this.state.quantity} 
						style={{borderColor: this.state.invalids.indexOf("quantity") == -1 ? "#777" : "#c00"}}
						onChange={(e)=> {this.requiredUpdate("quantity", e.target.value)}} />
				}
				
				
				{ !this.state.shopOwner &&
					<input class="full-width form" type="text" placeholder="Phone no." value={this.state.phone} 
						onChange={(e)=> this.setState({phone: e.target.value, errorMessage: ""})} />
				}
				{ !this.state.shopOwner &&
					<div class="flex w-100">
					<div class="full-width form flex-center" style={{border: "none", flex: 1, 
						backgroundColor: "rgb(0, 0, 0, 0.7)", borderBottomRightRadius: "0px", 
						borderTopRightRadius: "0px", color: "#fff", padding: "2px 15px"}}>
						{"https://t.me/"}
					</div>
					<input class="full-width form" style={{flex: 1,borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px"}} 
						type="text" placeholder="Telegram" value={this.state.telegram} 
						onChange={(e)=> this.setState({telegram: e.target.value, errorMessage: ""})} />
					</div>
				}
				
				{ (!this.state.id || this.state.name) &&
					<div class="rel w-100 tr-center">
						<input class="full-width form w-100" type="password" id="password" 
							placeholder={!this.state.shopOwner ? "Password for editting (Optional)": "Password (required)"} 
							value={this.state.password} onChange={(e)=> this.setState({password: e.target.value, errorMessage: ""})} />
						<div class="side-icon" onClick={this.showPassword}><Eye /></div>
					</div>
				}
				
				{ 	picturesKeys.length > 0 && <small class="upload-text">Uploaded files.</small>}
				
				{ 	picturesKeys.length > 0 && 
					Object.keys(pictures).filter((num) => pictures[num].length > 0).map((name) => {
						var counter = 0;
						while (!isNaN(pictures[name].charAt(counter)) && counter < 20) {
							counter++;
						}
						
						return (
							<div class="picture full-width form tr-center rel" 
								style={{color: this.state.deletedPics.indexOf(pictures[name]) > -1 ? "#aa0000" : "#000",
								backgroundColor: "#fff"}}>
								{pictures[name].substring(counter+1)}	
								<div class="delete-icon" onClick={(e) => {this.restorePicture(pictures[name]);}}>
									{	this.state.deletedPics.indexOf(pictures[name]) > -1 && <Undo /> }
									{	this.state.deletedPics.indexOf(pictures[name]) == -1 && <Delete /> }
								</div>
							</div>
						)
					})					
				}
				<small class="upload-text">Upload image files.</small>
				{ 	Array(this.state.nPictures).fill().map(() => 
						<input class="picture full-width form" type="file" name="images[]" multiple />
					)				
				}				
				<div style={{width: "25px", height: "25px", margin: "0 0 15px 0"}} 
					onClick={() => {this.setState({ nPictures: this.state.nPictures + 1 });}}>
					<Add />
				</div>
				{ this.state.errorMessage.length > 0 && 
					<small style={{color: "#aa0000"}}>{this.state.errorMessage}</small>
				}
				
				{ this.state.isSending && this.state.errorMessage.length == 0 && 
					<div class="w-100 purple-cl tr-center" style={{padding: "0 15px"}}>
						<Activity small="small"/>
						<small style={{flex: 1}}>Sending...</small>
					</div>
				}
				<div class="button" onClick={this.submit}>
					Submit
				</div>
				
						<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="3124086353" />
							
			</div>
			</div>
		); 
	}
}

export default Form;
