import React from 'react';


class About extends React.Component {
	
	render() {
		
		return (
			<div class="v-list-container">
				<div class="v-list-header">About</div>
				<div class="v-container">
						<p style={{textAlign: "justify"}}>Finding some specific stuff is difficult in many cities in Ethiopia. Poeple often rely on brokers who ask for high commission fees. In some cases, the process consists of several chains and the person who wants to get something may waste a considerable time while looking for it.</p>
						<p style={{textAlign: "justify"}}>With this in mind, we have developed a simple web application where anyone can search for what he wants to get or he can post whatever he wants mentioning the specific details. While posting, he can attach a picture or write any contact details. There is no middleman and buyers and sellers or employers and job seekers can communicate with one another directly. No registration is required and neither is any kind of service fee paid to use the website.</p>
						<p style={{textAlign: "justify"}}>Users can also filter their searches by city or keyword to narrow down their search ranges. The results are posted in reverse chronological order and this can also be reversed through the right side arrowed buttons.</p>
				</div>
			</div>
		);
	}
}
         
export default About;
