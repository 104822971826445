import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../css/main.css';


var SERVICES =  // from backend
[
"Buildings - interior design",
"Buildings - finishing works",
"Buildings - design review",
"Buildings - material supply",
];



class Services extends React.Component {
	state = {
		name: "",
		phone: "",
		category: "",
		categories: [],
		statusMessage: "",
		isSending: false
	}
		
	submit = () => {
		const url = `https://deal-corner.com/server/services/insert`;	
		const data = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept-Charset": "UTF-8"
			},
			body: JSON.stringify({
				id: uuidv4(),
				name: this.state.name,
				phone: this.state.phone,
				category: this.state.category			
			})
		};
		this.setState({isSending: true});
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			this.setState({isSending: false, statusMessage: "Your request is registered!"});			
		})
		.catch((e) => {
			this.setState({isSending: false, statusMessage: "Error occured!"});
		}); 
	}
	
	getCategories = () => {
		
		const url = `https://deal-corner.com/server/services/categories`;	
		const data = {
			headers: {
				"Content-Type": "application/json",
				"Accept-Charset": "UTF-8"
			}
		};
		
		fetch(url, data).then((e) => e.json())
		.then((e) => {	
	
			if (typeof e.data !== "string") {	
				this.setState({categories: e.data});
			} else {
				
			}
		})
		.catch((e) => {
			
		}); 
	}
	
	componentDidMount() {
		this.getCategories();		
	}
	
	render() {		
		return (
			<div style={{backgroundColor: "#555", width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "15px", boxSizing: "border-box"}}>
				<div class="close btn"  style={{alignSelf: "flex-end", cursor: "pointer"}} onClick={() => this.props.hideForm()}>
					<span>&#10006;</span>
				</div>
				<div style={{color: "#f0ffff", textAlign: "center", fontSize: "20px", fontWeight: "bold"}}>Get expert level consultancies by submitting this form.</div>				
				<input style={{borderRadius: "2px", border: "1px solid #aaa", padding: "5px 15px", margin: "5px 15px", height: "25px"}} 
				value={this.state.name} onChange={(e) => {this.setState({name: e.target.value})}} type="text" placeholder="Full name as on bank account" />
				<input  style={{borderRadius: "2px", border: "1px solid #aaa", padding: "5px 15px", margin: "5px 15px", height: "25px"}} 
				value={this.state.phone} onChange={(e) => {this.setState({phone: e.target.value})}} type="text" placeholder="Phone number" />				
				<select style={{borderRadius: "2px", border: "1px solid #aaa", padding: "5px 15px", margin: "5px 15px", height: "25px", boxSizing: "content-box"}} 
					onChange={(e) => this.setState({category: e.target.value})}>
					{
						SERVICES.map((item) =>
							<option value={item}>{item}</option>
						)
					}
				</select>
				<div style={{border: "1px solid #ccc", borderRadius: "5px", backgroundColor: "#ccc", padding: "5px 15px", margin: "5px 15px"}}>
					<p style={{textAlign: "justify", margin: 0}}>
						<small>
							You will soon get a call from one of our staffs to further discuss your specific interest.									
						</small>
					</p>
				</div>
				<div style={{backgroundColor: "blue", padding: "5px 15px", margin: "5px 15px", height: "25px", textAlign: "center",
					border: "1px solid #ccc", borderRadius: "3px",color: "#f0ffff"}}  onClick={this.submit}>
					Submit
				</div>
				<small style={{margin: "5px 15px"}}>{this.state.statusMessage}</small>
			</div>
		); 
	}
}

export default Services;
