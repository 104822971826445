import React from 'react';
import Form from "./Form";
import Header from "./Header";
import Utils from "./Utils";
import ExpandedItem from "./ExpandedItem";
import Adsense from "./Adsense";

import Badge from "./Badge";
import Activity from "./Activity";
import details from '../constants/details.json';
import { ReactComponent as Location } from '../img/location.svg';
import { ReactComponent as Views } from '../img/views.svg';
import { ReactComponent as Likes } from '../img/like.svg';
import { ReactComponent as Dislikes } from '../img/dislike.svg';
import '../css/main.css';


var CRITERIA = "", TYPE="", DETAILS="", PASSWORD = "", SHOW_TYPES = true, EXCLUDE="", SHOP = "", ID = "";

const video = ["mp4","mpg","mpeg","ogg","m4v","wmv","avi","flv","swf","mkv","rm","3g2","3gp","h264","mov","vob"];  
const audio = ["aif","cda","mid","midi","mp3","wav","wma", "wpl"];

var CATEGORY = "", DEVICE = "mobile";
const CATEGORIES = ["Jobs", "Electronics", "Houses", "Clothing", "Cars", "Machineries", "Hobbies", "Education", "Others"];
var TITLE_LENGTH = 20;
if (window.screen.width < 768) {
	TITLE_LENGTH = 20;
} else if (window.screen.width < 1024) {
	TITLE_LENGTH = 30;
} else {
	TITLE_LENGTH = 35;
}

const getAdjustedTitle = (title) => {
	if (title.length < TITLE_LENGTH) {
		return title;
	} else if (title.lastIndexOf(" ") == -1) {
		return title.substring(0, TITLE_LENGTH) + "...";		
	} else if (title.lastIndexOf(" ") < TITLE_LENGTH) {		
		return title.substring(0,title.lastIndexOf(" ")) + "...";		
	} else {
		var tIndex = title.indexOf(" ", 0), temp = tIndex, counter = 0;	
		while (temp > 0 && temp < TITLE_LENGTH && temp <= title.lastIndexOf(" ") && counter < 20) {
			tIndex = title.indexOf(" ", tIndex + 1);
			temp = title.indexOf(" ", tIndex + 1);
			counter++;
		}
		
		return title.substring(0,tIndex) + "...";
	}
};

var CONTENT_LENGTH = window.screen.width < 768 ? 60 : 90;

class List extends React.Component {
	state={
		ad: "",
		city: "",
		action: "",
		password: "",
		popMessage: "",
		editIndex: -1,
		pictureIndex: -1,
		listType: "All",
		data: {},
		page: 0,
		temp: {},
		showScrollToTop: false,
		isPosting: false,
		isDeleting: false,
		canEdit: false,
		showActivity: true,
		showDetails: false,
		activeData: null,
		showElectronicsType: false,
		electronicsType: ""
	}
	
	constructor(props) {
		super(props);
		const paramsString = window.location.search.substring(1);
		let searchParams = new URLSearchParams(paramsString);
		
		CATEGORY = searchParams.get("category");
		PASSWORD = searchParams.get("password");
		TYPE = searchParams.get("Type");	
		SHOP = searchParams.get("Shop");
		ID = searchParams.get("Id");
		if (SHOP && SHOP.length > 0) {
			CATEGORY = SHOP;
		}
		DETAILS = searchParams.get("Details");
		SHOW_TYPES = !TYPE || TYPE.length == 0;
		//document.title = CATEGORY;
		//document.querySelector('meta[property="og:title"]')
		//.setAttribute('content', CATEGORY);
		if (!SHOW_TYPES) {
			this.setState({ listType: TYPE });
		}
		
		EXCLUDE = searchParams.get("exclude");		
	}
	
	showForm = () => {
		this.setState({isPosting: true});
	}
		
	closeForm = () => {
		this.setState({isPosting: false, showDetails: false, activeData: null});
	}
	
	picIndex = (pictures) => {
		var index = -1;
		
		
		Object.keys(pictures).forEach((key, i) => {
			if (pictures[key] && pictures[key].length > 0) {
				if (index == -1) {
					index = parseInt(key.split("picture")[1]);
				}
			}
		});
		
		 
		return index;
	}
	
	/* picIndex = (data) => {		
		const hasPicture1 = data.picture1.length > 0;
		const hasPicture2 = data.picture2.length > 0;
		const hasPicture3 = data.picture3.length > 0;
		var index = -1;
		
		if (hasPicture1) {
			index = 1;
		} else if (hasPicture2) {
			index = 2;
		} else if (hasPicture3) {
			index = 3;
		}
		 
		return index;
	}	
	
 */	
	viewMore = () => {
		this.fetchPosts(CATEGORY, this.state.page + 1);
	    this.setState({page: this.state.page + 1});		
	}
	
	
	
	filter = (txt) => {
		const cat = CATEGORY.toLowerCase();
		CRITERIA = txt ? txt.toLowerCase() : "";
		//if (!txt || txt.length <= 1) {
			var temp = {};
			temp[cat] = [];
			this.setState({data: temp});
		//}
		if (this.state.page > 0) {
			this.setState({page: 0});
		}
		this.fetchPosts(cat);
	}
	
	fetchPosts = (txt, page) => {		
		var url;
		if (SHOP && SHOP.length > 0) {
			url = `https://deal-corner.com/server/shopItems/get/recommendations`;
		} else if (CATEGORY == "information") {
			url = `https://deal-corner.com/server/get/information/recommendations/${PASSWORD}`;
		} else {
			url = `https://deal-corner.com/server/get/item/recommendations`;
		}
		var d = [""];
		if (DETAILS) {
			d = DETAILS.split(",");
		}
		
		d.forEach((item) => {
			var body = {};
			if (SHOP && SHOP.length > 0) {
				body.id = ID;
			} else {
				body = {category: CATEGORY, type: TYPE, details: item, page: page ? page : 0, limit: 20};
			}
			if (TYPE && TYPE.length > 0) {			
				body.criteria = TYPE;
			}
			
			const data = {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
					"Accept-Charset": "UTF-8"
				}			
			};
		
			fetch(url, data).then((e) => e.json())
			.then((e) => {
				if (SHOP && SHOP.length > 0) {				
					const temp = this.state.data[CATEGORY] || [];
					if (e.data && e.data.length > 10) {
						e.data.splice(10, {}, 0);
					}
					const temp2 = [...temp, ...e.data];
					const temp3 = {};
					temp3[CATEGORY] = temp2;
					this.setState({data: temp3, temp: temp3, showActivity: false});
				} else if (typeof e.data !== "string") {				
					const temp = this.state.data[CATEGORY] || [];
					const temp2 = [...temp, ...e.data];
					const temp3 = {};
					temp3[CATEGORY] = temp2;					
					this.setState({data: temp3, temp: temp3, showActivity: false});
				} else {
					const city = this.state.city.length > 0 ? this.state.city : "Ethiopia";
					this.setState({showActivity: false, message: `There are no ${txt} posted in ${city} currently.`});
				}
			})
			.catch((e) => {
				const city = this.state.city.length > 0 ? this.state.city : "Ethiopia";
				this.setState({showActivity: false, message: `There are no ${txt} posted in ${city} currently.`});
			});
		});		
	}
	
	/* filter = (txt) => {
		if (txt.length == 0) {
			this.setState({temp: this.state.data});
			return;
		} 
		txt = txt.toLowerCase();
		var selected = {};
				
		if (this.state.data[CATEGORY.toLowerCase()]) {
			selected[CATEGORY.toLowerCase()] = this.state.data[CATEGORY.toLowerCase()].filter((item) => {
				return item.title.toLowerCase().indexOf(txt) != -1 ||
				item.content.toLowerCase().indexOf(txt) != -1 ||	
				item.details.toLowerCase().indexOf(txt) != -1 ||	
				item.city.toLowerCase().indexOf(txt) != -1;
			});
		}
			
		
		this.setState({temp: selected});
	} */	
	
	setElectronicsType = (item) => {
		var temp;
		if (item.length > 0) {
			temp = this.state.temp.filter((i) => {
				const array = i.category.split("-");
				
				if (array.length > 1 && array[1].length > 0) {
					return array[1].toLowerCase() == item.toLowerCase();
				}
				return false;
			});
		} else {
			temp = this.state.list;
		}
		this.setState({showElectronicsType: false, electronicsType: item.toLowerCase(), temp: temp});
	}
	
	showETypeMenu = (e) => {
		e.stopPropagation();
		this.setState({showElectronicsType: !this.state.showElectronicsType});
	}
	
	content = (txt) => {		
		var modified = txt.replace(/\n/g, "<br />");
		return modified;
	}
	
	Types = () => {
		return(
			<div class="list-types tr-center">
				<div style={{borderBottom: this.state.listType == "All" ? "3px solid #000": "none"}}
					onClick={() => {this.setState({listType: "All", data: {}});TYPE = ""; DETAILS="";this.fetchPosts();}}>All
				</div>
				{ 	details[CATEGORY].Type.map((item) => 
						<div style={{borderBottom: this.state.listType == item ? "3px solid #000": "none"}}
							onClick={() => {this.setState({listType: item, page: 0, data: {}});TYPE = item.substring(0, item.length - 1);DETAILS=""; this.fetchPosts();}}>{item}
						</div>
					)
				}
			</div>
		);
	}
	
	TypeDetails = () => {
		return(
			<div class="list-types tr-center">
				<div style={{borderBottom: this.state.listType == " " ? "3px solid #aa0000": "none"}}
					onClick={() => {this.setState({typeDetail: "All", data: {}});DETAILS = ""; this.fetchPosts();}}>&nbsp;&nbsp;
				</div>
				{ 	details[CATEGORY].Details[this.state.listType].map((item) => 
						<div style={{borderBottom: this.state.typeDetail == item ? "3px solid #00aa00": "none"}}
							onClick={() => {this.setState({typeDetail: item, page: 0, data: {}});DETAILS = item; this.fetchPosts();}}>{item}
						</div>
					)
				}
			</div>
		);
	}
	
	openArticle = (item) => {		
		window.location.href = `/info?category=${item.category}&title=${item.title}&shopName=${SHOP}&keywords=${item.keywords}&
								author=${item.author}&phone=${item.phone}&isApproved=${item.approved}&
								telegram=${item.telegram}&date=${item.date}&views=${item.views}&
								likes=${item.likes}&dislikes=${item.dislikes}&file_name=${item.file_name}&
								id=${item.itemid || item.id}&password=${item.password}`;
													
	}
	
	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScroll);
	}
	
	componentDidMount() {		
		
		window.addEventListener("scroll", this.onScroll);
				
		this.fetchPosts();
		
		if (window.screen.width < 768) {
			DEVICE = "mobile";
			CONTENT_LENGTH = 40;
		} else if (window.screen.width < 992) {
			DEVICE = "tab";
			CONTENT_LENGTH = 150;
		} else {
			DEVICE = "pc";
			CONTENT_LENGTH = 250;
		}
		
		const url2 = `https://deal-corner.com/server/ads/${DEVICE}/${CATEGORY}`;	
		
		fetch(url2, {method: "GET"}).then((e) => e.text())
		.then((e) => {			
			this.setState({ad: e});
		})
		.catch((e) => {			
		});
	}
	
	onScroll = () => {
		if (window.scrollY > 1000 && !this.state.showScrollToTop) {
			this.setState({showScrollToTop: true});
		} else if (window.scrollY < 1000) {
			this.setState({showScrollToTop: false});
		}
	}
	
	goToTop = (e) => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		//const element = document.getElementById("container");
		//if (element) {
		//	console.log(element.getBoundingClientRect());
		//}
	}
	
	render() {
		if (this.state.showActivity) {
			return (
				<div class="fullscreen">
					<Activity />
				</div>
			);
		}	
		
		const now = Date.now();
		const cat = SHOP && SHOP.length > 0 ? CATEGORY : CATEGORY.toLowerCase();
		return (
			<div class="home-container rel" id="container" style={{overflowY: "scroll", marginTop: "50px"}} onScroll={this.onScroll} onTouchMove={this.onScroll}>
				<Header filter={this.filter} showForm={this.showForm} />				
				{	SHOW_TYPES && details[CATEGORY] &&
					<this.Types />
				}
				{	this.state.listType != "All" && details[CATEGORY] && details[CATEGORY].Details[this.state.listType] && 
					details[CATEGORY].Details[this.state.listType].length > 0 &&
					<this.TypeDetails />
				}
					
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="4115440688" />
				
				{ this.state.temp[cat] && this.state.temp[cat].length > 0 &&
					<div class="cat-list-container" style={{maxWidth: "100vw", overflowX: "hidden"}}>
						<div class="list-ad"></div>
						<div class="cat-list-category w-100">							
							<div class="grid-2b2 electronics-list items-list">
							{ this.state.showScrollToTop &&
								<span class="flex-center" onClick={this.goToTop} style={{position: "fixed", right: 0, bottom: "50px", 
								width: "30px", height: "35px", 
									color: "#222", zIndex: 1010, backgroundColor:"rgb(255, 255, 255, 0.7)"}}>&#10514;</span>
							}
								{ 	this.state.temp[cat].map((item, index) => {
										var pictures = {}, details = {};
										try {
											pictures = JSON.parse(item.pictures);
										} catch(e) {}
										try {
											details = JSON.parse(item.details);
										} catch(e) {}
										if (CATEGORY == "information") {
											const ext = item.file_name.substring(item.file_name.lastIndexOf("."));
											
											return (
												<div class="rel"  style={{backgroundColor: "#fff"}} 
													onClick={() => {this.openArticle(item);}}>
													{	ext && ext == ".txt" &&
														<div class="info-article flex-center info-cover w-100" 
															style={{padding: "10px", boxSizing: "border-box", textAlign: "center"}}>{item.title}</div>
													}
													
													{	ext && ext != ".txt" &&
														<video controls>
															<source src={`https://deal-corner.com/server/information/items/${item.file_name}`} />
															<div class="abs info-article flex-center info-cover" style={{left: 0, top: 0, right: 0, bottom: 0, overFlow: "hidden", opacity: 0.5}}>{item.title}</div>
														</video> 
													}
													<div class="flex-center col w-100">
														<div class="flex-center col" style={{width: "95%"}}>
															<div class="flex-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px"}}> 
																{	ext && ext == ".txt" &&
																	<div class="tr-center w-100" style={{flex: 1, height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
																		Text file
																	</div>
																}
																{	ext && ext != ".txt" && item.title &&
																	<div class="tr-center w-100" style={{flex: 1, height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
																		{getAdjustedTitle(item.title)}
																	</div>
																}																
																<div class="tr-center" style={{fontSize: "10px", color: "#aaa"}}>
																	{Utils.RelativeTime(now, item.date)}
																</div>
															</div>
															<div class="flex-center w-100">
																<div style={{fontSize: "12px", color: "#aaa", flex: 1}}>{item.author}</div>
																<div class="flex-center" style={{color: "#aaa"}}>
																	<div style={{height: "20px"}} class="flex-center">
																		<div style={{width: "20px", height: "20px"}}><Views /></div>
																	</div>
																	<span>{item.views}</span>
																</div>
															</div>
															<div class="tr-center"  style={{width: "95%", flex: 1}}>
																<div class="flex-center" style={{color: "#aaa", marginRight: "20px"}}>
																	<div style={{height: "15px", color: "#00b7eb", marginRight: "5px"}} class="flex-center">
																		<div style={{width: "15px", height: "15px"}}><Likes /></div>
																	</div>
																	<span>{item.likes}</span>
																</div>
																<div class="flex-center" style={{color: "#aaa"}}>
																	<div style={{height: "15px", color: "#ff8c8c", marginRight: "5px"}} class="flex-center">
																		<div style={{width: "15px", height: "15px"}}><Dislikes /></div>
																	</div>
																	<span>{item.dislikes}</span>
																</div>
															</div>
														</div>
													</div>
													
													{!item.approved &&
														<Badge x1="100" y1="120" x2="50" y2="250" text1= "Not" text2= "approved" />
													}
												</div>
											)
										} else {
											var src;
											if (SHOP && SHOP.length > 0) {										
										src = `https://deal-corner.com/server/shopItem/image/${pictures.picture1}`;
} else {
	src = `https://deal-corner.com/server/image/${pictures.picture1}`;
}
											return (
												<div class="rel"  style={{backgroundColor: "#fff"}} 
													onClick={() => {window.location.href = `${SHOP && SHOP.length > 0 ? "/shop" : ""}/items?category=${item.category}&title=${item.title}&shopName=${SHOP}&type=${details.Type}&details=${details.Details}&id=${item.itemid || item.id}`}}>
													<img src={src}  />
													<div class="flex-center col w-100">
														<div class="flex-center col" style={{width: "95%"}}>
															<div class="flex-center w-100" style={{flex: 1, paddingLeft: "3px", marginRight: "5px"}}> 
																{item.title &&
																<div class="tr-center w-100" style={{flex: 1, height: "20px", width: "100%", fontSize: "10px", color: "#555"}}>
																	{getAdjustedTitle(item.title)}
																</div>
																}
																{ CATEGORY == "electronics" && details.Status == "New" && 
																	<span style={{fontSize: "10px", color: "orange", height: "15px", paddingLeft: "5px"}}>NEW</span>
																}
																{CATEGORY == "electronics" && details.Status == "Used" && 
																	<span style={{fontSize: "10px", color: "#a00", height: "15px", paddingLeft: "5px"}}>USED</span>
																}
																{CATEGORY != "electronics" &&  
																	<div class="tr-center" style={{fontSize: "10px", color: "#aaa"}}>
																		{Utils.RelativeTime(now, item.date)}
																	</div>
																}
															</div>
															<div class="flex-center w-100">
																<div class="tr-center w-100" style={{color: "#aaa", padding:"0", marginLeft: "-4px"}}>
																	<div style={{width: "15px", height: "15px"}}><Location /></div>
																	<div style={{fontSize: "12px"}}>{item.city}</div>
																</div>
																<div class="flex-center" style={{color: "#aaa"}}>
																	<div style={{height: "20px"}} class="flex-center">
																		<div style={{width: "20px", height: "20px"}}><Views /></div>
																	</div>
																	<span>{item.views}</span>
																</div>
															</div>
														</div>
														<div class="tr-center" style={{width: "95%", flex: 1, color: "#ff8a8a"}}>
															{parseFloat(item.price).toLocaleString()} ETB
														</div>
													</div>
													{details["Has delivery"] == "Yes" &&
														<Badge x1="100" y1="120" x2="50" y2="250" text1= "Free" text2= "delivery" />
													}
												</div>
											)
										}
									})
								}					
							</div>
							{ this.state.temp[cat].length % 20 == 0 && 
								<div class="view-more" onClick={this.viewMore}>View More ...</div>
							}							
						</div>
						<div class="list-ad"></div>
					</div>
				}
				{(!this.state.temp[cat] || this.state.temp[cat].length == 0) &&	
					<div class="purple-cl flex-center h-100" 
						style={{fontSize: "24px", flex: 1}}>
						There are no results.
					</div>
				}
				{ this.state.popMessage.length > 0 && 
					<div class = "pop-up">
						<small>{this.state.popMessage}</small>
					</div>
				}
				{ this.state.isPosting &&
					<div class="form-bg purple-bg">
						<div><Form hideForm={this.closeForm}/></div>
					</div>
				}
				
					<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="6094477005" format="autorelaxed" />
					
			</div>	
		); 
	}
}

export default List;
