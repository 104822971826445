import React from 'react';
import '../css/main.css';

class Error404 extends React.Component {	
	
	render() {	        
		return (
			<div style={{position: "fixed", top: 0, right: 0, bottom: 0, left: 0,backgroundColor: "rgb(128, 43, 177, 0.1)", 
				display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
				<div class="error-code">404</div>
				<div class="flex-center w-100" style={{flex: 1, 
				flexDirection: "column", color: "#64485c"}}>
					<div style={{fontSize: "70px", fontWeight: "bold"}}>Ooops!!!</div>
					<div style={{fontSize: "20px", margin: "30px 15px", fontFamily: "Orbitron", textAlign: "center"}}>
					Page not found.</div>
					<div class="button" style={{alignSelf: "center"}} onClick={this.submit}>
						<a style={{textDecoration: "none", color: "#fff"}} href="https://deal-corner.com/">Go to Home Page</a>
					</div>
				</div>
			</div>
		);
	}
}
         
export default Error404;
