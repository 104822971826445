import React from 'react';
import jwt from 'jsonwebtoken';
import Form from "./Form";
import Utils from "./Utils";
import SideInfo from "./SideInfo";
import Adsense from "./Adsense";

import Activity from "./Activity";
import Badge from "./Badge";
import Header from "./Header";
import { ReactComponent as Location } from '../img/location.svg';
import { ReactComponent as Eye } from '../img/eye.svg';
import { ReactComponent as Calendar } from '../img/calendar.svg';
import { ReactComponent as Price } from '../img/price-tag.svg';
import { ReactComponent as Chevron_left } from '../img/chevron-left.svg';
import { ReactComponent as Chevron_right } from '../img/chevron-right.svg';
import { ReactComponent as Call } from '../img/phone-call.svg';
import { ReactComponent as Message } from '../img/message.svg';
import { ReactComponent as Telegram } from '../img/telegram-sq.svg';
import { ReactComponent as Dislike } from '../img/dislike.svg';
import { ReactComponent as Like } from '../img/like.svg';

import '../css/main.css';
var TITLE_LENGTH = 15, PHONE = "", TELEGRAM = "", VIEWS = 0, DATE = "", ID = "", PASSWORD = "", LIKES = 0, DISLIKES = 0,
		CATEGORY = "", TITLE = "", AUTHOR = "", FILE_NAME = "", PATH = "", ISAPPROVED = "";

class ExpandedInfo extends React.Component {
	state={
		pictureIndex: -1,
		likes: 0,
		dislikes: 0,
		action: "",
		activePic: "picture1",
		popMessage: "",
		password: "",
		showActivity: false,
		canEdit: false,
		shouldEdit: false,
		isDeleting: false,
		recommendedItems: null
	}
	
	constructor(props) {
		super(props);
		const paramsString = window.location.search.substring(1);
		let searchParams = new URLSearchParams(paramsString);
		ID = searchParams.get("id");		
		CATEGORY = searchParams.get("category");
		TITLE = searchParams.get("title");
		ISAPPROVED = searchParams.get("isApproved");
		AUTHOR = searchParams.get("author");
		PASSWORD = searchParams.get("password");
		PHONE = searchParams.get("phone");
		DATE = searchParams.get("date");		
		TELEGRAM = searchParams.get("telegram");
		FILE_NAME = searchParams.get("file_name");		
		VIEWS = searchParams.get("views");
		LIKES = searchParams.get("likes");
		DISLIKES = searchParams.get("dislikes");
		
		PATH = CATEGORY.charAt(0).toUpperCase() + CATEGORY.substring(1).toLowerCase() + " / ";		
		
		if (FILE_NAME.substring(FILE_NAME.lastIndexOf(".") == ".txt")) {
			this.getFile(FILE_NAME);
		}
		/* document.title = TITLE;
		document.querySelector('meta[property="og:title"]')
		.setAttribute('content',TITLE); */
	}
		
	description = (props) => {
		const item = props.item;
		const category = props.category;
		const index = props.index;
		const shopItem = props.shopItem ? "shopItem/" : "";
		var pictures, details = {};
		try {
			pictures = JSON.parse(item.pictures);
		} catch(e) {
			pictures = {};
		}
		try {
			details = JSON.parse(item.details);
		} catch(e) {
			details = {};
		}
		var tIndex = item.title.indexOf(" ", 0), temp = tIndex, counter = 0;
		
		while (temp > 0 && temp < TITLE_LENGTH && temp <= item.title.lastIndexOf(" ") && counter < 20) {
			tIndex = item.title.indexOf(" ", tIndex + 1);
			temp = item.title.indexOf(" ", tIndex + 1);
			counter++;
		}
		if (tIndex < 0) tIndex = Math.min(item.title.length, TITLE_LENGTH);
		const pic = props.pic;
		return (
			<div class="w-100 flex-center col" style={{backgroundColor: "#ddd", height: "40px"}}>
				<div onClick={() => {window.location.href = `/items?category=${item.category}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}
					style={{fontSize: "12px", color: "#555", marginLeft: "5px"}}>
					{item.title.substring(0,tIndex)}
						{tIndex < item.title.length && <span>...</span>}
				</div>
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'number' &&
					<div style={{fontSize: "11px", color: "#777", marginLeft: "5px"}}>
						{parseFloat(item.price).toLocaleString('en-US')} ETB													
					</div>
				}
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'string' &&
					<div style={{fontSize: "11px", color: "#777", marginLeft: "5px"}}>
						{parseFloat(item.price.replace(/,/g,"")).toLocaleString('en-US')} ETB													
					</div>
				}
				{ (!item.price || isNaN(parseFloat(item.price))) && 
					<div style={{fontSize: "14px", color: "transparent", marginLeft: "5px"}}>
						PRICE NOT GIVEN													
					</div>
				}
			</div>
		);
	}
	
	content = (txt) => {
		if (!txt) return "";
		var modified = txt.replace(/\n/g, "<br />");
		return modified;
	}
	
	expandMenu = (e) => {
		e.stopPropagation();
		e.preventDefault(); 
		this.setState({shouldEdit: true}); 
	}
	
	submit = () => {
		const post = this.state.data;
		
		if (this.state.action == "edit") {
			jwt.verify(post.password, "_deal_corner", (err, decoded) => {
				if (this.state.password == "_deal_123_corner" || (!err && decoded == this.state.password)) {
					const pwd = jwt.sign(this.state.password, "_deal_corner");
					sessionStorage.setItem(pwd, this.props.type ? post.item_id : post.id);
					sessionStorage.setItem("type", this.props.type);
					window.location.href = "/form/" + pwd;
				} else {
					this.setState({ canEdit: false, popMessage: "Incorrect password!" });
					setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
				}
			});			
		} else if (this.state.action == "approve") {			
			this.setState({ isDeleting: true });
			const url = "https://deal-corner.com/server/information/approve/" + this.state.password;				
			const data = {
				"method": "POST",
				"headers": {"Content-Type": "application/json"},			
				"body": JSON.stringify({
					id: ID						
				}),
			};
			 
			fetch(url, data).then((e) => e.json())
			.then((e) => {
				if (e.data === "SUCCESS") {
					this.setState({ isDeleting: false, action: "", editIndex: -1, popMessage: "The post is deleted successufully!" });
					window.location.href = "/";
					//this.context.router.history.goBack
				} else {
					this.setState({isDeleting: false, action: "", editIndex: -1, popMessage: e.data});
				}
				setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
			})
			.catch((e) => {	
				this.setState({isDeleting: false, action: "", editIndex: -1, popMessage: "The post could not be deleted."});
				setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
			}); 
				
			
		} else {			
			this.setState({ isDeleting: true });
			
				jwt.verify(PASSWORD, "_deal_corner", (err, decoded) => {					
					if (this.state.password == "_deal_123_corner" || (!err && decoded == this.state.password)) {
						const url = "https://deal-corner.com/server/information/delete";				
						const data = {
							"method": "POST",
							"headers": {"Content-Type": "application/json"},			
							"body": JSON.stringify({
								id: ID,				
								file_name: FILE_NAME						
							}),
						};
					 
					fetch(url, data).then((e) => e.json())
					.then((e) => {
						this.setState({ isDeleting: false, action: "", editIndex: -1, popMessage: "The post is deleted successufully!" });
						window.location.href = "/";
						//this.context.router.history.goBack
						setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
					})
					.catch((e) => {	
						this.setState({isDeleting: false, action: "", editIndex: -1, popMessage: "The post could not be deleted."});
						setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
					}); 
				} else {
					this.setState({ isDeleting: false, popMessage: "Incorrect password!" });
					setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
				}
			});
		}		
	}
	
	additionalItems = (props) => {
		
		return (
			<div class="w-100 hide-on-zoom">
				<div style={{margin: "15px 0", borderBottom: "1px solid #fff"}}></div>
				<div style={{fontSize: "18px", fontWeight: "bold", marginBottom: "15px", paddingLeft: "5px", color: "#333", fontFamily: "sans-serif"}}>You May Also Like</div>
					<div class="deals-category">
					{	this.state.recommendedItems &&
						<div style={{display: "grid", paddingTop: "15px",gridAutoRows: "1fr",
											gridTemplateColumns: "repeat(auto-fill, minmax(40%, 1fr))"}}>
							{ this.state.recommendedItems.map((item, index) => {
							const pictures = JSON.parse(item.pictures);
							const pic = "picture1";					
							const imgClass = "bg-pic";
							//const isImageSelected = this.state.selected == (category + index);
							const details = item.details ? JSON.parse(item.details) : {};
							
							const src = `https://deal-corner.com/server/image/${pictures[pic]}`;
							return (
							
								<div class="flex col rel ptr mh-15" style={{backgroundColor: "#eee", justifyContent: "flex-end", marginBottom: "15px"}}>
									<img src={src} class={imgClass} style={{backgroundColor: "#eee"}}/>	
									<this.description item={item} category={item.category} index={index} pic={pic} />
									{details["Has delivery"] == "Yes" &&
										<Badge x1="100" y1="120" x2="50" y2="250" text1= "Free" text2= "delivery" />
									}															
								</div>
							
							)
							})}
						</div>
					}
					</div>
			</div>
		);
	}
	
	fetchRecommendations = () => {
		const url = `https://deal-corner.com/server/get/item/recommendations`;
		var info, d = this.state.data;
		try {
			info = JSON.parse(d.details);
		} catch(e) {}
		var body = { category: d.category, title: d.title, id: d.id };
		if (typeof info == "object") {			
			body.type = info.Type;
			body.details = info.Details;
		}
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			
			if (e.data && Array.isArray(e.data)) {
				this.setState({recommendedItems: e.data });
			}
		}).catch((e) => {
			
		});
	}
	
	update = (type) => {		
		var url = `https://deal-corner.com/server/info/${type}/update/${ID}`;
		
		const data = {	
			headers: {							
				"Content-Type": "application/json"
			} 
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			if (e.data == "SUCCESS") {				
			}			
		})
		.catch((e) => {			
		}); 
	}
	
	getFile = async (file_name) => {
		fetch(`https://deal-corner.com/server/information/items/${file_name}`)
		.then((e) => e.text())
		.then((res) => {
			this.setState({content: res});
		})
		.catch((e) => {
		});
	}
	
	viewerReaction = () => {
		
		return (
			<div id="side-info" style={{width: "50px", height: "100vh", position: "fixed", zIndex: 10000, right: 0, top: window.screen.height/2 + "px", width: "50px", height: "150px", color: "#fff"}}>
				<div class="side-info-container" style={{gridTemplateColumns: "1fr", rowGap: "50px"}}>
					<div style={{backgroundColor: "rgba(0,0,0, 0.7)"}}>
						<div class="side-info-icon" style={{padding: "3px"}} onClick={() => {this.update("likes");this.setState({likes: this.state.likes + 1});}}>
							<div>
								<Like />
							</div>
						</div>
						<div style={{color: "#fff"}} class="side-info-text">{Utils.formatNumber(this.state.likes)}</div>
					</div>
					<div style={{backgroundColor: "rgba(0,0,0, 0.7)"}}>
						<div class="side-info-icon" style={{padding: "3px"}} onClick={() => {this.update("dislikes");this.setState({likes: this.state.dislikes + 1});}}>
							<div>
								<Dislike />
							</div>
						</div>
						<div style={{color: "#fff"}} class="side-info-text">{Utils.formatNumber(this.state.dislikes)}</div>
					</div>
					
				</div>
			</div>
		);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if (this.state.data && Object.keys(this.state.data).length > 0 && !this.state.recommendedItems) {
			this.fetchRecommendations();
		}
	}
	
	componentDidMount() {
		this.setState({likes: parseInt(LIKES), dislikes: parseInt(DISLIKES)});
		this.update("views");	
		try {
			(window.adsbygoogle = window.adsbygoogle || []).push({});
			(window.adsbygoogle = window.adsbygoogle || []).push({});
		} catch(e) {}
	}
	
	render() {
		
		if (this.state.showActivity) {
			return (
				<div class="flex-center w-100" style={{height: "100vh", backgroundColor: "rgb(36, 48, 94, 0.9)"}}><Activity /></div>
			);
		}
		
		if (this.state.canEdit) {
			return (
				<div class="form-bg purple-bg">
					<div>
						<Form hideForm={() => {this.setState({ canEdit: false, shouldEdit: false, action: ""});}} 
							data={this.state.data} />
					</div>
				</div>
			);
		}
				
		const now = Date.now();
		const ext = FILE_NAME.substring(FILE_NAME.lastIndexOf("."));
			const src = `https://deal-corner.com/server/information/items/${FILE_NAME}`;
		return (
			
					
					<div class="w-100 flex col" style={{backgroundColor: "rgb(36, 48, 94, 0.9)", color: "#444", minHeight: "100vh"}}>
						<Header filter={() => {}}  />
						<this.viewerReaction />
						<div style={{flex: 1, width: "100vw", marginTop: "40px", maxWidth: "468px", alignSelf: "center", 
						backgroundColor: "#fff", padding: "15px", position: "relative", boxSizing: "border-box"}}>
						<div style={{color: "#999", fontWeight: "bold", padding: "10px 15px 0px 15px"}}>{"Blogs / " + CATEGORY}</div>
							<div class="rel tr-center expanded-content hide-on-zoom" 
								style={{fontSize: "18px", fontWeight: "bold", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px"}}>
								{TITLE.toUpperCase()}
								<div class="v-menu options-dots hide-on-zoom" onClick={this.expandMenu}>
									<div style={{height: "5px"}}>.</div>
									<div style={{height: "5px"}}>.</div>
									<div style={{height: "5px"}}>.</div>
								</div>
								{ this.state.shouldEdit &&
									<div class="v-menu menu-items flex-center col hide-on-zoom">
										<small style={{margin: "5px", minHeight: "15px"}} 
											onClick={() => {this.setState({ action: "", shouldEdit: false }); }}></small>
												{false && <small style={{margin: "5px"}} 
											onClick={() => {this.setState({ action: "edit", shouldEdit: false });}}>Edit</small>
												}
										<small style={{margin: "5px"}} 
											onClick={() => {this.setState({ action: "delete", shouldEdit: false });}}>Delete</small>									
									</div>
								}
							</div>
							<div class="w-100" style={{borderBottom: "1px solid #aaa"}}></div>
								<ins class="adsbygoogle"
								 style={{display: "block", minWidth:"300px", maxWidth: "768px", width:"100%"}}
								 data-ad-client="ca-pub-8810554256735099"
								 data-ad-slot="1686031545"
								 data-ad-format="auto"
								 data-full-width-responsive="true"></ins>
							
							<div class="flex-center rel" style={{boxSizing: "border-box", margin: 0, overflow: "hidden", zIndex: 100}}>
								{ ext && ext == ".txt" &&
									<div class="expanded-content hide-on-zoom" style={{margin: 0, padding: "0 10px", width: "100%"}} dangerouslySetInnerHTML={{ __html: this.state.content }} />
								}
								{	ext && ext != ".txt" &&
									<div style={{height: "50vh", maxHeight: "50vh", width: "100vw"}}>
										<video controls style={{height: "calc(100% - 30px)", maxHeight: "calc(100% - 30px)"}}>
											<source src={src} />
										</video>
										<div class="w-100" style={{fontSize: "18px", height: "30px", color: "#000", maxWidth: "100vw", textAlign: "center"}}>{TITLE}</div>
									</div>									
								}
							</div>
							<div class="hide-on-zoom w-100" style={{margin: "10px 0"}}>
								<SideInfo author={AUTHOR} views={parseInt(VIEWS) + 1} now={now} date={DATE}  />
							</div>
							{ (PHONE || TELEGRAM) &&
								<div class="w-100 rel" style={{borderBottom: "1px solid #aaa", height: "15px", marginBottom: "15px",  marginTop: "15px"}}>
									<span class="abs" style={{top: "2px", backgroundColor: "#fff", 
										padding: "2px 10px", marginLeft: "15px", fontWeight: "bold"}}>Contact author</span>
								</div>
							}
							<div class="hide-on-zoom w-100 flex-center">
								{ PHONE && PHONE.length > 0 &&
									<div class="icon-md" title={PHONE}>
										<a href={`tel:${PHONE}`}>
											<Call />
										</a>
									</div>
								}
								{ PHONE && PHONE.length > 0 &&
									<div class="icon-md"  title={PHONE}>
										<a href={`sms:${PHONE}`}>
											<Message />
										</a>
									</div>
								}
								{ TELEGRAM && TELEGRAM.length > 0 &&
									<div class="icon-md"  title={TELEGRAM}>
										<a href={`https://t.me/${TELEGRAM}`}>
											<Telegram />
										</a>
									</div>
								}
							</div>
							<div class="w-100" style={{borderBottom: "1px solid #aaa", paddingBottom: "2px"}}></div>
							{ this.state.recommendedItems && this.state.recommendedItems.length > 0 && 
								<div class="w-100 h-100 hide-on-zoom" style={{display: "inline-block"}}>
									<div style={{margin: "15px 0", borderBottom: "1px solid #fff"}}></div>
									<div style={{fontSize: "18px", fontWeight: "bold", marginBottom: "15px", paddingLeft: "5px", color: "#333", fontFamily: "sans-serif"}}>You May Also Like</div>
										<div class="deals-category">
										<div style={{display: "grid", paddingTop: "15px",gridAutoRows: "1fr",
															gridTemplateColumns: "repeat(auto-fill, minmax(40%, 1fr))"}}>
										{ this.state.recommendedItems.map((item, index) => {
										const pictures = JSON.parse(item.pictures);
										const pic = "picture1";
										const picIndex = 1;
										const imgClass = picIndex > 0 ? "bg-pic" : "bg-icon";
										//const isImageSelected = this.state.selected == (category + index);
										const details = item.details ? JSON.parse(item.details) : {};
										
										const src = `https://deal-corner.com/server/image/${pictures[pic]}`;
										return (
										
											<div class="flex col rel ptr mh-15" 
											style={{backgroundColor: "#eee", justifyContent: "flex-end", marginBottom: "15px", height: "170px"}}>
												<img src={src} class={imgClass} style={{backgroundColor: "#eee", objectFit: "cover", height: "130px"}}/>	
												<this.description item={item} category={item.category} index={index} pic={pic} />
												{details["Has delivery"] == "Yes" && false &&
													
														<Badge x1="100" y1="120" x2="50" y2="250" text1= "Free" text2= "delivery" />
													
												}															
											</div>
										
										)
									})}</div></div>
								</div>
							}
							{ this.state.action.length > 0 && 
								<div class="full-screen flex-center" style={{position: "fixed", zIndex: 1002, backgroundColor: "rgb(36, 48, 94, 0.9)"}}>
									<div class="form-container" style={{backgroundColor: "#777"}}>
										<div class="close btn purple-cl"  style={{alignSelf: "flex-end", cursor: "pointer"}}
											onClick={() => {this.setState({ action: "", shouldEdit: false, isDeleting: false })}}>
											<span>&#10006;</span>
										</div>
										<input class="full-width form" type="password" placeholder="Enter your password" style={{margin: "15px 0"}} 
											onChange={(e)=> this.setState({password: e.target.value, errorMessage: ""})} />
										{ ((this.state.action == "delete" || this.state.action == "approve") && this.state.isDeleting) && 
											<div class="w-100 tr-center purple-cl" style={{padding: "0 15px"}}>
												<Activity small="small"/>
												<small style={{flex: 1}}>Sending...</small>
											</div> 
										}
										<div class="button" onClick={this.submit}>
											Submit
										</div>
									</div>
								</div>
							}
							{
								ISAPPROVED == "0" &&
								<div class="button" style={{textAlign: "center", marginTop: "15px"}} onClick={() => {this.setState({ action: "approve", shouldEdit: false });}}>
									Approve
								</div>
							}
							{ this.state.popMessage.length > 0 &&
								<div class="pop-up">
									<small>{this.state.popMessage}</small>
								</div>
							}
							<ins class="adsbygoogle"
							 style={{display: "block", minWidth:"300px", maxWidth: "768px", width:"100%"}}
							 data-ad-client="ca-pub-8810554256735099"
							 data-ad-slot="6094477005"
							 data-ad-format="autorelaxed"
							 data-full-width-responsive="true"></ins>
						
						</div>
					</div>
				
			
		);
	}
}
         
export default ExpandedInfo;
