import React from 'react';


class TechStuff extends React.Component {	
	render() {
		
		return (
			<div class="v-list-container">
				<div class="v-list-header">Prohibited posts</div>
				<div class="v-container">						
					<p style={{textAlign: "justify"}}>	

Users must comply with all applicable laws, the Deal Corner terms of use, and all posted site rules.

Here is a partial list of goods, services, and content prohibited on deal-corner.com:
<ul>
    <li style={{lineHeight: "30px"}}>
    weapons; firearms/guns and components; BB/pellet, stun, and spear guns; etc</li>
    <li style={{lineHeight: "30px"}}>
	ammunition, clips, cartridges, reloading materials, gunpowder, fireworks, explosives</li>
    <li style={{lineHeight: "30px"}}>
	offers, solicitation, or facilitation of illegal prostitution and/or sex trafficking</li>
    <li style={{lineHeight: "30px"}}>
	exploitation or endangerment of minors; child pornography</li>
    <li style={{lineHeight: "30px"}}>
	recalled items; hazardous materials; body parts/fluids; unsanitized bedding/clothing</li>
    <li style={{lineHeight: "30px"}}>
	prescription drugs, medical devices; controlled substances and related items</li>
    <li style={{lineHeight: "30px"}}>
	alcohol or tobacco; unpackaged or adulterated food or cosmetics</li>
    <li style={{lineHeight: "30px"}}>
	pet sales (re-homing with small adoption fee ok), animal parts, stud service</li>
    <li style={{lineHeight: "30px"}}>
	endangered, imperiled and/or protected species and any parts thereof, e.g. ivory</li>
    <li style={{lineHeight: "30px"}}>
	false, misleading, deceptive, or fraudulent content; bait and switch; keyword spam</li>
    <li style={{lineHeight: "30px"}}>
	offensive, obscene, defamatory, threatening, or malicious postings or email</li>
    <li style={{lineHeight: "30px"}}>
	anyone’s personal, identifying, confidential or proprietary information</li>
    
    <li style={{lineHeight: "30px"}}>
	stolen property, property with serial number removed/altered, burglary tools, etc</li>
    <li style={{lineHeight: "30px"}}>
	ID cards, licenses, police insignia, government documents, birth certificates, etc</li>    
    <li style={{lineHeight: "30px"}}>
	counterfeit, replica, or pirated items; tickets or gift cards that restrict transfer</li>
    <li style={{lineHeight: "30px"}}>
	lottery or raffle tickets, sweepstakes entries, slot machines, gambling items</li>
    <li style={{lineHeight: "30px"}}>
	spam; miscategorized, overposted, cross-posted, or nonlocal content</li>
    <li style={{lineHeight: "30px"}}>
	postings or email the primary purpose of which is to drive traffic to a website</li>
    <li style={{lineHeight: "30px"}}>
	postings or email offering, promoting, or linking to unsolicited products or services</li>
    <li style={{lineHeight: "30px"}}>
	affiliate marketing; network, or multi-level marketing; pyramid schemes</li>
    <li style={{lineHeight: "30px"}}>
	any good, service, or content that violates the law or legal rights of others</li>
</ul>
Please don't use Deal Corner for these purposes, and flag anyone else you see doing so.

Thanks for helping keep deal-corner.com safe and useful for everyone.

					</p>
				</div>
			</div>
		);
	}
}
         
export default TechStuff;
