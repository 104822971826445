import React from 'react';
import '../css/vacancy-list.css';

class VacancyList extends React.Component {	
	
	render() {
		var data = this.props.data;
		return (
			<div class="v-list-container">
				<div class="v-list-header">Work with us</div>
				<div class="v-container">
				{false && <p class="v-message">{txt}</p>}
						<h2 class="ml-100 mb-15 text-left bold">Current open positions</h2>
							<ul class="ml-150">
								{  data && data.length > 0 &&
									data.map((item) =>     
									  <li class="v-link"><a href={"/vacancy/" + item}>{item}</a></li>
									)
								}
							</ul>
							{
								(!data || data.length == 0) &&
								<div>
									Currently there are no vacancies.
								</div>  
							}
								
				</div>
			</div>
		);
	}
}

const txt = `We are a rapidly growing company. The scope of the works we are 
             involved in is also expanding day by day. Along with this, we need 
			 to maintain our good reputation which made us gain recognitions from 
			 our clients. Thus, we are always eager to work with the best talents 
			 in the market. You are welcome to join us if you meet the requirements 
			 for the open positions listed below.`;
                
export default VacancyList;
