import React from 'react';
import Form from "./Form";
import Header from "./Header";
import Details from "./Details";
import '../css/main.css';

import { ReactComponent as Chevron_down } from '../img/chevron-down.svg';
import { ReactComponent as Chevron_up } from '../img/chevron-up.svg';
import { ReactComponent as Search } from '../img/search.svg';
import { ReactComponent as Edit } from '../img/edit.svg';
import { ReactComponent as List } from '../img/list.svg';


import Jobs from '../img/jobs.jpeg';
import Electronics from '../img/electronics.webp';
import Houses from '../img/houses.jpeg';
import Clothing from '../img/clothing.webp';
import Cars from '../img/cars.jpeg';
import Machineries from '../img/machineries.png';
import Hobbies from '../img/hobbies.png';
import Blogs from '../img/blogs.png';
import Others from '../img/others.webp';
import CATEGORIES from '../constants/categories.json';
var ad_image;


const IMAGES = {
	"Jobs": Jobs, 
	"Electronics": Electronics, 
	"Houses": Houses, 
	"Clothing": Clothing, 
	"Cars": Cars, 
	"Machineries": Machineries, 
	"Hobbies": Hobbies, 
	
	"Others": Others	
};
	


class Categories extends React.Component {
	
	
	render() {					
		return (
			<div style={{position: "fixed", zIndex: 15000, backgroundColor:"rgb(18, 60, 105, 0.8)" ,width: "100vw", 
			height: "100vh", textAlign: "center", top: "0", 
				boxSizing: "border-box", }}>
				
				<div class="flex col rel"  style={{backgroundColor:"#123c69" ,width: "80vw", minHeight: "100vh",
				textAlign: "center", top: "0", 
				padding: "10px 10px 0 10px", boxSizing: "border-box"}}>
					<div class="flex-center abs" 
					style={{right: "-30px", top: 0,width: "30px", height: "30px", backgroundColor: "#123c69", color: "#ddd"}}><span>&#10006;</span></div>
					<div style={{fontSize: "20px", padding: "5px 15px", color: "#fff", textAlign: "left"}}>Pick a category</div>
						<div style={{borderBottom: "1px solid #fff", margin:"10px 0"}}></div>
					<div class="ln-center col">
						{
							CATEGORIES.slice(0,9).map((item, index) => 	
								<div style={{padding: "5px 15px"}} 
									onClick={() => {window.location.href = `/list?category=${item.toLowerCase()}&Type=`;}}>
								<div class="w-100 h-100 tr-center">
									<div class="flex-center nav-image">
										<img width="30px" height="30px" src={IMAGES[item]} />
									</div>
										<div style={{color: "#ddd"}}>{item}</div>
									</div>
								</div>
							)
						}
						<div style={{padding: "5px 15px"}} 
									onClick={() => {window.location.href = "https://info.deal-corner.com";}}>
								<div class="w-100 h-100 tr-center">
									<div class="flex-center nav-image">
										<img width="30px" height="30px" src={Blogs} />
									</div>
										<div style={{color: "#ddd"}}>Blogs and info</div>
									</div>
								</div>
					</div>
				</div>					
			</div>
		); 
	}
}

export default Categories;
