import React from 'react';
import Utils from "./Utils";
import { ReactComponent as Location } from '../img/location.svg';
import { ReactComponent as Author } from '../img/author.svg';
import { ReactComponent as Eye } from '../img/eye.svg';
import { ReactComponent as Calendar } from '../img/calendar.svg';
import { ReactComponent as Price } from '../img/price-tag.svg';


import '../css/main.css';

class SideInfo extends React.Component {	
	
	render() {	        
		return (
			<div id="side-info">
				<div class="side-info-container">
					{this.props.price && 
						<div>
							<div class="side-info-icon">
								<div>
									<Price />
								</div>
							</div>
							<div class="side-info-text price">{parseFloat(this.props.price).toLocaleString('en-US')}</div>
						</div>
					}
					{this.props.author &&
						<div>
							<div class="side-info-icon" style={{padding: "3px"}}>
								<div>
									<Author />
								</div>
								
							</div>
							<div class="side-info-text">{this.props.author}</div>
						</div>
					}
					{this.props.city &&
						<div>
							<div class="side-info-icon" style={{padding: "3px"}}>
								<div>
									<Location />
								</div>
								
							</div>
							<div class="side-info-text">{this.props.city}</div>
						</div>
					}
					<div>
						<div class="side-info-icon">
							<div>
								<Calendar />
							</div>
							
						</div>
						<div class="side-info-text">{Utils.RelativeTime(this.props.now, this.props.date)}</div>
					</div>
					<div>
						<div class="side-info-icon" style={{padding: "4px"}}>
							<div>
								<Eye />
							</div>
						</div>
						<div class="side-info-text">{this.props.views}</div>
					</div>
				</div>
			</div>
		);
	}
}
         
export default SideInfo;
