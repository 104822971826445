import React from 'react';
import jwt from 'jsonwebtoken';
import docx2html from 'docx2html';
import { v4 as uuidv4 } from 'uuid';
import Activity from "./Activity";
import Header from "./Header";
import { ReactComponent as Eye } from '../img/eye.svg';
import { ReactComponent as Check } from '../img/check.svg';
import { ReactComponent as Delete } from '../img/delete.svg';
import { ReactComponent as Undo } from '../img/undo.svg';
import { ReactComponent as Add } from '../img/add-plus.svg';
import { ReactComponent as Logo } from '../img/logo.svg';
import CATEGORIES from '../constants/info_categories.json';
import DETAILS from '../constants/details.json';
import '../css/main.css';

const init = {
		id: null,
		title: "",
		author: "",
		category: "",
		password: "",
		showActivity: false,
		isSending: false,
		successMessage: "",
		errorMessage: ""
	};
	
class InfoForm extends React.Component {
	state=init
	
	reset = () => {
		this.setState(init);
	}
	
	showPassword = () => {
		const element = document.getElementById("password");
		if (element) {
			element.type = element.type == "password" ? "text" : "password";
		}
	}
	
	send = (formData) => {
		const url = `https://deal-corner.com/server/information/add`;
		const data = {
			"method": "POST",
			"accept-charset": "UTF-8",
			"accept-language": "am,en",
			"body": formData
		};
			
		this.setState({ isSending: true });
		fetch(url, data).then((e) => e.json())
		.then((e) => {	
			if (e.data == "SUCCESS") {
				this.setState({successMessage: "Your data is submitted successfully!"});
			} else {
				this.setState({errorMessage: e.data});
			}
		})
		.catch((e) => {	
			this.setState({isSending: false, errorMessage: e.message || e.data});
		}); 
	}
	
	submit = () => {
	    if (this.state.title.length > 0 && this.state.author.length > 0 && this.state.phone.length > 0) {
			
			const id = uuidv4();
			var input = document.getElementById('file');			
			var category = this.state.category.toLowerCase();
			var formData = new FormData();			
			formData.append("id", id);
			formData.append("title", this.state.title);
			formData.append("keyWords", this.state.keyWords);
			formData.append("author", this.state.author);
			formData.append("phone", this.state.phone);
			formData.append("category", category);
			if (this.state.password.length > 0) {
				formData.append("password", jwt.sign(this.state.password, "_deal_corner"));
			}								
			
			if (input.files) {
				for (const file of input.files) {
					
					if (file.type == "application/msword" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
						docx2html(input.files[0])
						.then(html=>{
							const txt = html.toString();
							if (txt) {
								const body = txt.substring(txt.indexOf("<body>") + 6, txt.indexOf("</body>"));
								var f_name = file.name.replace(/\s/g,"");
								var temp = f_name.substring(f_name.lastIndexOf("."));
								temp = f_name.replace(temp, ".txt");
								const f = new File([body], temp, {type:"text/plain"});								
								formData.append("picture",f,temp);
								this.send(formData);
							}
						});
					} else {
						formData.append("picture",file,file.name.replace(/\s/g,""));
						this.send(formData);
					}
				}	
			}
		} else {
			this.setState({errorMessage: "Fill all required fields."});
		}
	}	
	
	componentDidMount() {		
		try {
			(window.adsbygoogle = window.adsbygoogle || []).push({})
		} catch(e) {}
	}
				
	render() {
		if (this.state.showActivity) {
			return (
				<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
						<Header filter={() => {}}  />
						<div class="flex-center col" style={{flex: 1,width: "100vw", maxWidth: "400px", alignSelf: "center", marginTop: "50px", 
						padding: "15px 25px", boxSizing: "border-box"}}>
						<Activity small="small" />
						<small>processing...</small>
					</div>
				</div>
			);
		}
		
		if (this.state.successMessage.length > 0) {
			return (
				<div class="w-100 flex-center col" style={{color: "#444", minHeight: "100vh"}}>
						<Header filter={() => {}}  />
						<ins class="adsbygoogle"
								 style={{display: "block", minWidth:"300px", maxWidth: "768px", width:"100%", height:"100px"}}
								 data-ad-client="ca-pub-8810554256735099"
								 data-ad-slot="2577291448"
								 data-ad-format="auto"
								 data-full-width-responsive="true"></ins>
				
						<div class="flex col" style={{flex: 1, width: "100vw", maxWidth: "468px", alignSelf: "center",marginTop: "50px", 
						padding: "15px"}}>
					
					<div class="form-message" style={{color: "#000", marginTop: "100px", padding: "30px", textAlign: "center"}}>
						{this.state.successMessage}
					</div>
					<div style={{color: "#000", fontSize: "16px", margin: "50px 20px", textAlign: "center"}}>
						<a onClick={this.reset} style={{textDecoration: "underline", cursor: "pointer"}}>Post</a> another one.
					</div>
					<div class="button" style={{alignSelf: "center"}}
						onClick={() => {window.location.href = this.props.refreshTo || "/";}}>
						Close
					</div>
				</div>
				</div>
			);
		}
				
		return (
			<div class="w-100 flex col" style={{color: "#444", minHeight: "100vh", boxSizing: "border-box"}}>
				<Header filter={() => {}}  />
				<div class="flex col" style={{flex: 1, justifyContent: "space-between", width: "100vw", maxWidth: "468px", alignSelf: "center", marginTop: "50px",
						padding: "15px 25px", boxSizing: "border-box"}}>
					<div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
						<div style={{padding: "5px", color: "#000", width:"30%"}}>Category:</div>
						<select class="purple-cl" value={this.state.category} 
							onChange={(e) => this.setState({category: e.target.value})}>
							<option value=""></option>
							{
								CATEGORIES.map((item) => <option value={item.toLowerCase()}>{item}</option>)
							}
						</select>
					</div>
					
				<input class="full-width form" type="text" placeholder="Title" required value={this.state.title} 
					onChange={(e)=> this.setState({title: e.target.value, errorMessage: ""})} />	
				<input class="full-width form" type="text" placeholder="Key words (comma separated)" required value={this.state.keyWords} 
					onChange={(e)=> this.setState({keyWords: e.target.value, errorMessage: ""})} />	
				<input class="full-width form" type="text" placeholder="Author" required value={this.state.author} 
					onChange={(e)=> this.setState({author: e.target.value, errorMessage: ""})} />
				<input class="full-width form" type="text" placeholder="Phone" required value={this.state.phone} 
					onChange={(e)=> this.setState({phone: e.target.value, errorMessage: ""})} />
				
				<div class="rel w-100 tr-center">
					<input class="full-width form w-100" type="password" id="password" 
						placeholder={!this.state.shopOwner ? "Password for editting (Optional)": "Password (required)"} 
						value={this.state.password} onChange={(e)=> this.setState({password: e.target.value, errorMessage: ""})} />
					<div class="side-icon" onClick={this.showPassword}><Eye /></div>
				</div>
				
				<small class="upload-text">Upload audio or video or ms word file.</small>
				<input id="file" class="picture full-width form" type="file" name="image" />				
				
				{ this.state.errorMessage.length > 0 && 
					<small style={{color: "#aa0000"}}>{this.state.errorMessage}</small>
				}
				
				{ this.state.isSending && this.state.errorMessage.length == 0 && 
					<div class="w-100 purple-cl tr-center" style={{padding: "0 15px"}}>
						<Activity small="small"/>
						<small style={{flex: 1}}>Sending...</small>
					</div>
				}
				<div class="button" onClick={this.submit}>
					Submit
				</div>
				<ins class="adsbygoogle"
								 style={{display: "block", minWidth:"300px", maxWidth: "768px", width:"100%", height:"250px"}}
								 data-ad-client="ca-pub-8810554256735099"
								 data-ad-slot="2577291448"
								 data-ad-format="auto"
								 data-full-width-responsive="true"></ins>
				
			</div>
			</div>
		); 
	}
}

export default InfoForm;
