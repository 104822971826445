import React from 'react';
import Form from "./Form";
import Activity from "./Activity";
import Adsense from "./Adsense";

import HomeActivityRow from './HomeActivityRow';
import Badge from "./Badge";
import Menu from "./Menu";
import Feedback from "./Feedback";
import HomeActivity from "./HomeActivity";
import Header2 from "./Header2";
import ExpandedItem from "./ExpandedItem";

import SideInfo from "./SideInfo";
import Services from "./Services";
//import Consultancies from "./Consultancies";
//import Deliveries from "./Deliveries";

import Utils from "./Utils";
import CATEGORIES from '../constants/categories.json';
import DETAILS from '../constants/details.json';
import '../css/main.css';

import { ReactComponent as OutlineLogo } from '../img/logo-outline.svg';
import { ReactComponent as Right } from '../img/chevron-right.svg';
import { ReactComponent as DownArrow } from '../img/down-arrow.svg';
import { ReactComponent as Call } from '../img/phone-call.svg';
import { ReactComponent as MoreInfo } from '../img/more-info.svg';
import { ReactComponent as Message } from '../img/message.svg';
import { ReactComponent as Location } from '../img/location.svg';
import { ReactComponent as Chevron_left } from '../img/chevron-left.svg';
import { ReactComponent as Chevron_right } from '../img/chevron-right.svg';
import { ReactComponent as Chevron_down } from '../img/chevron-down.svg';
import { ReactComponent as Post } from '../img/add.svg';
import Jobs from '../img/jobs.jpeg';
import EL from '../img/electronics.webp';
import Houses from '../img/houses.jpeg';
import Clothing from '../img/clothing.webp';
import Cars from '../img/cars.jpeg';
import Machineries from '../img/machineries.png';
import Hobbies from '../img/hobbies.png';
import Education from '../img/education.png';
import HealthNBeauty from '../img/health&beauty.PNG';
import Others from '../img/others.webp';
import Electronics_en from '../img/electronics.webp';
import ShopItem from '../img/shop-item.svg';
import LogoOutline from '../img/logo-outline.svg';
import HAndB from '../img/health-and-beauty.JPG';
import Men from '../img/men.JPG';
import Kids from '../img/kids.JPG';
 
var STARTS = {};
CATEGORIES.forEach((item) => {STARTS[item.toLowerCase()] = 0;});
var ADS_INTERVAL = 20;

var DEVICE = "mobile";
var TITLE_LENGTH = 20;
if (window.screen.width < 768) {
	DEVICE = "mobile";
	TITLE_LENGTH = 20;
} else if (window.screen.width < 1024) {
	DEVICE = "tab";
	TITLE_LENGTH = 25;
} else {
	DEVICE = "pc";
	TITLE_LENGTH = 35;
}

const width = window.screen.width;
var electronicsLimit = 5, hAndBLimit = 4, carsLimit = 3;
if (width < 872) {
	electronicsLimit = 2;
	hAndBLimit = 2;
	carsLimit = 2;
} else if (width < 1024) {
	electronicsLimit = 3;
	hAndBLimit = 2;
	carsLimit = 2;
} else if (width < 1346) {
	electronicsLimit = 4;
	hAndBLimit = 2;
	carsLimit = 3;
} 

const IMAGES = {
	"Jobs": Jobs, 
	"Electronics": EL, 
	"Houses": Houses, 
	"Clothing": Clothing, 
	"Cars": Cars,
	"Health & beauty": HealthNBeauty,
	"Machineries": Machineries, 
	"Hobbies": Hobbies, 
	"Education": Education, 
	"Others": Others	
};

const Cultural = (props) => {
		return (
			<div style={{margin: "15px", backgroundColor: "#fff"}}>
				<div class="w-100" style={{fontWeight: "bold", padding: "10px 30px 0 15px"}}>{props.clothingType}&nbsp;clothes</div>
				{	props.data && props.data.length > 0 && 
					<div class="grid-2b2" style={{height: "420px"}}>
						{ 	props.data.map((item) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								
								return (
									<div style={{height: "170px"}} class="rel" 
										onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										<img style={{height: "90%"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="tr-center w-100" style={{width: "100%", fontSize: "10px"}}>
											{getAdjustedTitle(item.title)}
										</div>
										<div class="tr-center w-100" style={{color: "#777"}}>{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
									</div>
								)
							})
						}				
					</div>
				}
				{ (!props.data || props.data.length == 0) && <CulturalActivity /> }
				{ props.data && props.data.length > 0 && 
					<div class="see-more" onClick={() => {window.location.href = "/list?category=clothing&Type=" + props.clothingType;}}>See more</div>
				}
			</div>
		);
	};
	
	const HealthAndBeauty = (props) => {
		return (
			<div style={{margin: "15px", backgroundColor: "#fff"}}>				
				{	props.data && props.data.length > 0 && 
					<div class="grid-2b2 health-beauty" style={{height: "420px"}}>
					<div style={{"background": `no-repeat center/cover url('${HAndB.toString()}')`}}></div>
						{ 	props.data.map((item) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								
								return (
									<div style={{height: "170px"}} class="rel"  
										onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										<img style={{height: "90%"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="tr-center w-100" style={{width: "100%", fontSize: "10px"}}>
											{getAdjustedTitle(item.title)}
										</div>
										<div class="tr-center w-100" style={{color: "#777"}}>{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
									</div>
								)
							})
						}				
					</div>
				}				
				{ props.data && props.data.length > 0 && 
					<div class="see-more" 
						onClick={() => {window.location.href = `/list?category=${encodeURIComponent('health & beauty')}&Type=`;}}>See more
					</div>
				}
			</div>
		);
	};
	
	const CarsAndHouses = (props) => {
		return (
			<div style={{margin: "15px", backgroundColor: "#fff"}}>				
				{	props.data && props.data.length > 0 && 
					<div class="cars-houses grid-2b2">
						<div></div>
						{ 	props.data.map((item) => {
								var pictures = {}, details = {};
								try {
									pictures = JSON.parse(item.pictures);
								} catch(e) {}
								try {
									details = JSON.parse(item.details);
								} catch(e) {}
								
								return (
									<div style={{height: "170px"}} class="rel"  
										onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
										<img style={{height: "90%"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
										<div class="tr-center w-100" style={{width: "100%", fontSize: "10px"}}>
											{getAdjustedTitle(item.title)}
										</div>
										<div class="tr-center w-100" style={{color: "#777"}}>{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
										{details["Has delivery"] == "Yes" && <FreeDelivery />}
									</div>
								)
							})
						}				
					</div>
				}				
				{ props.data && props.data.length > 0 && 
					<div class="see-more" onClick={() => {window.location.href = "/list?category=cars&Type=";}}>See more</div>
				}
			</div>
		);
	};	
	
	
	const Electronics = (props) => {
	return (
		<div class="electronics-items">
			<div style={{fontWeight: "bold", fontSize: "22px", paddingLeft: "15px"}}>Just for you</div>
						{ props.data && props.data.length > 0 && 
						<div class="grid-2b2 grid-1b2 electronics-list">
				{ 	props.data.map((item, index) => {
						var pictures = {}, details = {};
						try {
							pictures = JSON.parse(item.pictures);
						} catch(e) {}
						try {
							details = JSON.parse(item.details);
						} catch(e) {}
						
						return (
							<div style={{height: "100%", maxWidth: "150px",minWidth: "150px", width: "150px"}} 
								 onClick={() => {window.location.href = `/items?category=${item.category}&title=${item.title}&type=${details.Type}&details=${details.Details}&id=${item.id}`}}>
								<img style={{minHeight: "150px"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
								<div class="tr-center w-100" style={{fontSize: "10px"}}>
									{getAdjustedTitle(item.title)}
									{details.Status == "New" && 
										<span style={{fontSize: "10px", color: "orange", height: "15px", paddingLeft: "5px"}}>NEW</span>
									}
									{details.Status == "Used" && 
										<span style={{fontSize: "10px", color: "#a00", height: "15px", paddingLeft: "5px"}}>USED</span>
									}
								</div>
								<div class="tr-center w-100">{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
								<div class="tr-center w-100" style={{color: "#777"}}>{item.city}</div>
								{details["Has delivery"] == "Yes" && <FreeDelivery />}
							</div>
						)
					})
				}					
			</div> }
						{ (!props.data || props.data.length == 0) && <OneRowMultiColumnActivity /> }
						{ props.data && props.data.length > 0 && 
							<div class="see-more" onClick={() => {window.location.href = "/list?category=electronics&Type=";}}>See more</div>
						}
					</div>
					
	);
};
const OneRowActivity = () => {
		return (
			<div class="grid-2b2 grid-1b2 electronics-list loading" style={{height: "210px", minHeight: "210px"}}>
				{ Array(2).fill().map(() => 
					<div style={{height: "100%"}}>
						<div style={{width: "100%", height: "95%", minHeight: "95%", backgroundColor: "#ddd", borderRadius: 0, marginBottom: "5px"}}>
						</div>
						<div class="w-100" style={{width: "100%", fontSize: "10px", color: "tranparent", backgroundColor: "#ccc"}}></div>
						<div class="w-100" style={{color: "transparent", backgroundColor: "#ccc"}}></div>
					</div>
				)}
			</div>
		);
	};
	
	const OneRowMultiColumnActivity = () => {
		return (
			<div class="grid-2b2 grid-1b2 loading" style={{height: "210px", minHeight: "210px", gridTemplateColumns: `repeat(${electronicsLimit}, 1fr)`}}>
				{ Array(electronicsLimit).fill().map(() => 
					<div style={{height: "100%", width: "auto"}}>
						<div style={{width: "100%", height: "95%", minHeight: "95%", backgroundColor: "#ddd", borderRadius: 0, marginBottom: "5px"}}>
						</div>
						<div class="w-100" style={{width: "100%", fontSize: "10px", color: "tranparent", backgroundColor: "#ccc"}}></div>
						<div class="w-100" style={{color: "transparent", backgroundColor: "#ccc"}}></div>
					</div>
				)}
			</div>
		);
	};

const FreeDelivery = () => {
	return (
		<div style={{width: "30px", height: "30px", position: "absolute", left: "20px", top: "15px"}}>
			<svg viewBox="0 0 455 455">
				<polygon fill="yellow" stroke="yellow" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" 
					points="455,0 0,0 0,300 227,455 455,300"/>
				<text x="100" y="120" fontSize="100px">Free</text>
				<text x="48" y="250" fontSize="100px">delivery</text>
			</svg>
		</div>
	);
};
const getAdjustedTitle = (title) => {
	if (title.length < TITLE_LENGTH) {
		return title;
	} else if (title.lastIndexOf(" ") == -1) {
		return title.substring(0, TITLE_LENGTH) + "...";		
	} else if (title.lastIndexOf(" ") < TITLE_LENGTH) {		
		return title.substring(0,title.lastIndexOf(" ")) + "...";		
	} else {
		var tIndex = title.indexOf(" ", 0), temp = tIndex, counter = 0;	
		while (temp > 0 && temp < TITLE_LENGTH && temp <= title.lastIndexOf(" ") && counter < 20) {
			tIndex = title.indexOf(" ", tIndex + 1);
			temp = title.indexOf(" ", tIndex + 1);
			counter++;
		}
		
		return title.substring(0,tIndex) + "...";
	}
};
const CulturalActivity = () => {		
		return (
			<div class="grid-2b2 loading" style={{height: "420px"}}>
				{ Array(4).fill().map(() => 
				<div style={{height: "170px"}}>
					<div style={{width: "100%", height: "90%", backgroundColor: "#ddd", borderRadius: 0, marginBottom: "5px"}}></div>
					<div class="tr-center w-100" style={{color: "transparent", backgroundColor: "#ccc"}}>abcde ETB</div>
				</div>
				)}					
			</div>
		);		
	};
	
	
class Home extends React.Component {
	state={
		ad: '',
		home_message: '',
		page: 0,
		data: [],
		temp: [],
		shopNames: [],
		shopItems: {},
		activePics: {},
		message: "",
		displayDetails: "",
		showCategories: false,
		eIconsPage: 0,
		isPageLoading: true,
		fetchingFilteredData: "",		
		starts: STARTS,
		selected: "",
		isPosting: false
	}
	
	setCategory = (txt) => {
		const cat = txt.split("-")[0].toLowerCase();
		this.setState({active: cat, showCategories: false});
		this.props.history.push(`/list/${cat}/`)
	}
	
	setActivePic = (e, cat, index, num) => {
		e.preventDefault();
		e.stopPropagation();
		var pics = this.state.activePics;
		pics[cat+index] = num;
		this.setState({activePics: pics});
	}
	
	select = (category, index) => {
		this.setState({selected: category + index});
	}
	
	unselect = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({selected: "" });
	}	
	
	fetchRecommendations = (body, title) => {
		const url = `https://deal-corner.com/server/get/item/recommendations`;
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {			
			if (e.data && Array.isArray(e.data)) {
				var temp = {};
				if (title && title.length > 0) {				
					temp[title] = e.data;
					this.setState(temp);
				} 
			}
		}).catch((e) => {
			
		});
	}
	
	filter = (txt) => {		
		if (txt.length == 0) {
			this.setState({ filteredItems: null, showFilteredItems: false });						
			return;
		} else {
			txt = txt.toLowerCase();
			this.setState({ showFilteredItems: true });	
			this.fetchFilteredData(txt);
		}
	}
	
	fetchFilteredData = (txt) => {
		const url = `https://deal-corner.com/server/get/filtered/deals`;
		var body = {page: 0, limit: 20, text: txt};		
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			this.setState({ filteredItems: e.data });
		}).catch((e) => {
			console.log(e.message);
		});
	}
	
	fetchPosts = (category, criteria) => {
		const url = `https://deal-corner.com/server/get/deals/${category.toLowerCase()}`;
		var body = {page: 0, limit: 10};
		
		if (criteria && criteria.length > 0) {			
			body.criteria = criteria;
		}
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {			
			var temp = {...this.state.data, ... e.data};				
			this.setState({data: temp, temp: temp, isLoading: false, isPageLoading: false, fetchingFilteredData: "" });
		}).catch((e) => {
			
		});
	}
	
	getPosts = (txt, page) => {
		CATEGORIES.slice(0,9).forEach((category) => {
			this.fetchPosts(category);
		});
	}
	
	getShopNames = (txt, page) => {		
		const url = `https://deal-corner.com/server/shops/get`;
		const data = {
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			this.setState({shopNames: e.data});
			this.getShopItems(e.data);
		}).catch((e) => {
			
		});
	}
	
	getShopItems = (data) => {		
		data.forEach((item) => {
			const url = `https://deal-corner.com/server/shopItems/get`;
			const name = item.name;
			const data = {
				method: 'POST',
				body: JSON.stringify({page: 0, limit: 10, name: name }),
				headers: {
					"Content-Type": "application/json"
				}			
			};
			fetch(url, data).then((e) => e.json())
			.then((e) => {
				if (Array.isArray(e.data)) {
					var temp = this.state.shopItems
					temp[name] = e.data;
					this.setState({shopItems: temp});
				}
			}).catch((e) => {
				
			});
		});
		
	}
	
	picIndex = (data) => {		
		const hasPicture1 = data && data.picture1 && data.picture1.length > 0;
		const hasPicture2 = data && data.picture2 && data.picture2.length > 0;
		const hasPicture3 = data && data.picture3 && data.picture3.length > 0;
		var index = -1;
		
		if (hasPicture1) {
			index = 1;
		} else if (hasPicture2) {
			index = 2;
		} else if (hasPicture3) {
			index = 3;
		}
		 
		return index;
	}
	
	showForm = () => {
		this.setState({isPosting: true});
	}
	
	closeForm = () => {
		this.setState({isPosting: false, selected: ""});
	}	
	
	moveRow = (cat, increment) => {
		const start = this.state.starts[cat.toLowerCase()] + increment;
		if (start >= 0 && start < (10- start)) {
			var starts =this.state.starts; 
			starts[cat.toLowerCase()] = start; 
			this.setState({starts: starts});
		}
	}
	
	ElectronicsIcons = () => {
		return (
		<div class="flex-center">
			{	this.state.eIconsPage == 1 && 
				<div class="flex-center" style={{width:"25px", height:"25px", color: "#555", margin: "5px"}} 
					onClick={() => {this.setState({eIconsPage: 0});}}>
					<Chevron_left />
				</div>
			}
			<div class={`electronics-icons e-icons${this.state.eIconsPage}`} style={{flex: 1}}>			
				{	DETAILS.electronics.Type.slice(this.state.eIconsPage*9, (this.state.eIconsPage+1)*9).map((item) => 
					<div onClick={() => {window.location.href = `/list?category=electronics&Type=${item}`;}}>
						<div></div>
						<div>{item}</div>
					</div>
				)}			
			</div>
			{	this.state.eIconsPage == 0 && 
				<div class="flex-center" style={{width:"25px", height:"25px", color: "#555", margin: "5px"}} onClick={() => {this.setState({eIconsPage: 1});}}>
					<Chevron_right />
				</div>
			}
		</div>
		);
	}
	
	titles = (props) => {
		const category = props.category;
		return (
			<div class="rel tr-center" style={{justifyContent: "space-between", padding: "5px 20px"}}>
				<div class="purple-cl tr-center" style={{fontWeight: "bold", fontSize: "22px",cursor: "pointer"}} 
					onClick={() => {this.props.history.push(`/list?category=${category.toLowerCase()}&Type=`)}}>
					{ category.toUpperCase()} 
					{ DETAILS[category.toLowerCase()] &&
						<span style={{fontSize: "14px", marginLeft: "10px"}} 
							onClick={(e) => {this.changeDisplayDetails(e, category);}}>&#9660;
						</span>
					}
					{ this.state.fetchingFilteredData == category && 
						<div class="w-100 purple-cl tr-center" style={{padding: "0 15px"}}>
							<Activity small="small"/>
							<small style={{flex: 1, fontWeight: 400, fontSize: "12px"}}>Processing...</small>
						</div>
					}
				</div>
				
				{ DETAILS[category.toLowerCase()] && this.state.displayDetails == category &&
					<Menu array={DETAILS[category.toLowerCase()].Type} 
						subarray={DETAILS[category.toLowerCase()].Details} 
						getSelected={(detail) => {this.fetchFilteredData(category, detail);}}/>
				}
			</div>
		);
	}
	
	description = (props) => {
		const item = props.item;
		const category = props.category;
		const index = props.index;
		const shopItem = props.shopItem ? "shopItem/" : "";
		var pictures, keys;
		try {
			pictures = JSON.parse(item.pictures);
			keys = Object.keys(pictures).filter((key) => pictures[key].length > 0);
		} catch(e) {
			pictures = {};
			keys = [];
		}
		var tIndex = item.title.indexOf(" ", 0), temp = tIndex, counter = 0;
		while (temp > 0 && temp < TITLE_LENGTH && temp <= item.title.lastIndexOf(" ") && counter < 20) {
			tIndex = item.title.indexOf(" ", tIndex + 1);
			temp = item.title.indexOf(" ", tIndex + 1);
			counter++;
		}
		if (tIndex < 0) tIndex = Math.min(item.title.length, TITLE_LENGTH);
		const pic = props.pic;
		return (
			<div class="rel w-100" style={{marginTop: "5px"}}>
				<div onClick={() => {window.location.href = "/items/" + item.id;}} 
					style={{fontSize: "14px", color: "#BFA5B8", marginLeft: "5px"}}>
					{item.title.substring(0,tIndex)}
				</div>
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'number' &&
					<div style={{fontSize: "14px", color: "#512A44", marginLeft: "5px"}}>
						{parseFloat(item.price).toLocaleString('en-US')} ETB													
					</div>
				}
				{ item.quantity == 0 && typeof parseFloat(item.price) == 'number' &&
					<div class="abs" style={{right: "5px", top: 0, fontSize: "8px", color: "#f1f1f1", padding: "2px", backgroundColor: "orange", borderRadius: "2px"}}>
						SOLD													
					</div>
				}
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'string' &&
					<div style={{fontSize: "14px", color: "#512A44", marginLeft: "5px"}}>
						{parseFloat(item.price.replace(/,/g,"")).toLocaleString('en-US')} ETB													
					</div>
				}
				{ (!item.price || isNaN(parseFloat(item.price))) && 
					<div style={{fontSize: "14px", color: "transparent", marginLeft: "5px"}}>
						PRICE NOT GIVEN													
					</div>
				}
				<div class="tr-center" style={{margin: "5px 0"}}>
					{ keys.slice(0, 10).map((key) => 
						<div onClick={(e) => {this.setActivePic(e, category, index, key)}} 
							class="pic-protype" 
							style={{backgroundImage: `url(https://deal-corner.com/server/${shopItem}image/${pictures[key]})`,
							outline:  pic == key ? "1px solid #777": "none", outlineOffset: "1px"}}>
						</div>
					)}
				</div>
			</div>
		);
	}
	
	itemInfo = (props) => {
		const item = props.item;
		return (
			<div class="flex-center col highlight" 
				onClick={(e) => {this.unselect(e)}}>
				<div class="flex-center col" style={{flex: 1}}>
					<div class="side-info-icon">
						<div>
							<Location />
						</div>
					</div>
					<div class="side-info-text" style={{flexBasis: "auto", color: "#fff"}}>{item.city}</div>
				</div>
				<div class="ln-center" style={{height: "40px", paddingBottom: "15px"}}>
					{ item.phone && item.phone.length > 0 &&
						<div title={item.phone} class="description-icon" >
							<a href={`tel:${item.phone}`}>
								<Call />
							</a>
						</div>
					}
					{ item.phone && item.phone.length > 0 &&
						<div title={item.phone} class="description-icon">
							<a href={`sms:${item.phone}`}>
								<Message />
							</a>
						</div>
					}
				
					<div title={"More info..."} class="description-icon purple-bg flex-center" 
						onClick={() => {window.location.href = "/items/" + item.id;}} 
						style={{color: "#fff", borderRadius: "3px"}}>
						<div class="dot"></div>
						<div class="dot"></div>
						<div class="dot"></div>
					</div>
				</div>
			</div>
		);
	}
	
	goToMap = (location) => {
		var point;
		try {
			point = JSON.parse(location)[0];
		} catch(e) {}
		if (point) {
			const url = `https://maps.google.com/?q=${point.lat},${point.lng}&ll=${point.lat},${point.lng}&z=15`;
			window.location.href = url;
		}
	}
	
	shopInfo = (props) => {
		const item = props.data;
		return (
			<div class="tr-center">
				<div class="tr-center" style={{height: "40px", padding: "15px 0 5px 30px", textShadow: "1px 1px #000"}}>
					<div>
						<div style={{fontSize: "30px", fontWeight: "bold", color: "#802bb1", margin: "0 15px"}}>
						{item.name}
						</div>
					</div>					
					<div class="description-icon" style={{backgroundColor: "rgb(247, 136, 136)",cursor: "pointer", borderRadius: "3px", margin: "5px"}}>
						<div onClick={() => {this.goToMap(item.locations)}}>
							<Location />
						</div>
					</div>
					<div title={item.phone} class="description-icon" style={{margin: "5px", cursor: "pointer"}}>
						<a href={`sms:${item.phone}`}>
							<Call />
						</a>
					</div>
				</div>
			</div>
		);
	}
	
	homeMessage = () => {
		return (
			<div id="home-message">
				<div class="side-menu">
					{
							CATEGORIES.slice(0,10).map((item, index) => 	
								<div 
									onClick={() => {window.location.href = `/list?category=${encodeURIComponent(item.toLowerCase())}&Type=`;}}>
								<div class="w-100 flex ptr">
										<div class="ln-center col w-100">
											<div class="w-100 tr-center">
												<div class="flex-center nav-image" style={{padding: "5px 15px"}}>
													<img width="30px" height="30px" src={IMAGES[item]} />
												</div>
												<div style={{color: "#000", flex: 1, textAlign: "left"}}>{item}</div>
												{   DETAILS[item.toLowerCase()] && DETAILS[item.toLowerCase()].Type && 
													<div style={{width:"25px", height: "25px"}} 
														onClick={(e) => {e.preventDefault();e.stopPropagation();this.setState({expand: this.state.expand == item ? "" : item});}}>
															{this.state.expand != item&& <Chevron_right /> }
															{this.state.expand == item&& <Chevron_down /> }
													</div>
												}
											</div>
											{	DETAILS[item.toLowerCase()] && DETAILS[item.toLowerCase()].Type && this.state.expand == item&&
												<div style={{textAlign: "left", marginLeft: "45px", padding: "5px 0"}}>
													{DETAILS[item.toLowerCase()].Type.map((type) => 
														<div style={{padding: "2px 0", color: "#777"}}
														onClick={(e) => {e.preventDefault();e.stopPropagation();window.location.href = `/list?category=${item.toLowerCase()}&Type=${type}`;}}>{type}</div>
													)}
												</div>
											}
										</div>
									</div>									
								</div>
							)
						}
				</div>
				<div class="home-image home2" id="himages" style={{flex: 1, marginLeft: "15px", overflowX: "hidden"}}>
					<div class="h-100"></div>
				</div>
			</div>
		);
	}
	
	
	
	pictures = () => {
		const url = `https://deal-corner.com/server/get/deals/pictureUpdate`;
		var body = {page: 0, limit: 1000};		
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			e.data.forEach((item) => {
				alert("data inserted!");				
			});
		}).catch((e) => {
			
		});
	}
	
	freeDelivery = () => {
		return (
			<div style={{width: "30px", height: "30px", position: "absolute", left: "5px", top: "1px"}}>
				<svg viewBox="0 0 455 455">
					<polygon fill="yellow" stroke="yellow" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" 
						points="455,0 0,0 0,300 227,455 455,300"/>
					<text x="100" y="120" fontSize="100px">Free</text>
					<text x="50" y="250" fontSize="100px">delivery</text>
				</svg>
			</div>
		);
	}
	
	FilteredItems = () => {
		return (
			<div class="grid-2b2" style={{marginTop: "100px", height: "auto", gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"}}>
				{ 	this.state.filteredItems.length > 0 && this.state.filteredItems.map((item) => {
						var pictures = {};
						try {
							pictures = JSON.parse(item.pictures);
						} catch(e) {}
						return (
							<div style={{height: "180px"}} onClick={() => {window.location.href = "/items/" + item.id}}>
								<img style={{height: "90%"}} src={`https://deal-corner.com/server/image/${pictures.picture1}`}  />
								<div class="tr-center w-100" style={{width: "100%", fontSize: "10px"}}>{item.title}</div>
								<div class="flex-center">{parseFloat(item.price).toLocaleString('en-US')} ETB</div>
							</div>
						)
					})
				}
				{ 	this.state.filteredItems.length == 0 && 
					<div class="flex-center" style={{width: "100vw", height: "75vh", fontSize: "22px"}}>There is no data for your search.</div>
				}
			</div>
		);
	}
	
	changeDisplayDetails = (e, cat) => {
		e.preventDefault();
		e.stopPropagation();
		const detail = this.state.displayDetails == cat ? "" : cat;
		this.setState({displayDetails: detail });
	}	
	
	componentDidMount() {
		this.getPosts("all", 0);
		this.getShopNames();
		
		const body = {
			"Cultural":{category: "clothing", type: "Cultural", details: "", id: "abc", limit: 4, page: 0},
			"Women":{category: "clothing", type: "Women", details: "", id: "abc", limit: 4, page: 0},
			"Men":{category: "clothing", type: "Men", details: "", id: "abc", limit: 4, page: 0},
			"Kids":{category: "clothing", type: "Kids", details: "", id: "abc", limit: 4, page: 0},
			"Electronics": {category: "electronics", type: "", details: "", id: "abc", limit: electronicsLimit, page: 0},
			"Health & beauty":{category: "health & beauty", type: "", details: "", id: "abc", limit: hAndBLimit, page: 0},
			"Cars":{category: "cars", type: "", details: "", id: "abc", limit: carsLimit, page: 0},
			"Houses":{category: "houses", type: "", details: "", id: "abc", limit: carsLimit, page: 0},
		};
		
		["Cultural","Women","Men","Kids", "Electronics", "Health & beauty", "Cars", "Houses"].forEach((item) => {
			this.fetchRecommendations(body[item], item);
		});	
		
		const element = document.getElementsByClassName("side-menu");
		if (element && element[0]) {			
			const h = element[0].scrollHeight;
			const images = document.getElementById("himages");			
			console.log(element[0]);
			if (images) {		
				images.style.height = h + "px";						
			}			
		}
		
	}
	
	render() {
		
		return (
			<div class="home-container rel w-100" style={{overflowX: "hidden"}}>
				<Header2 filter={this.filter} setCategory={this.setCategory} showForm={this.showForm} />
				{this.state.showFilteredItems && this.state.filteredItems &&
						<this.FilteredItems />
					}
				{(!this.state.showFilteredItems || !this.state.filteredItems) &&
				<div style={{maxWidth: "100vw", marginTop: "60px"}}>
					<this.homeMessage />					
					<div class="flex-center">
						<div style={{width: "25vw", minWidth: "400px", backgroundColor: "#eee"}}>
							<Cultural data={this.state.Cultural} clothingType={"Cultural"} />
						</div>
						<div class="men rel"  style={{"background": `no-repeat center/cover url('${Men.toString()}')`}}>
							<div style={{fontSize: "14px", bottom: "20px", right: "15px", color: "#191970"}} 
								onClick={() => {window.location.href = "/list?category=clothing&Type=Men"}}>{"Explore >>"}</div>
						</div>
						<div style={{width: "25vw", minWidth: "400px", backgroundColor: "#eee"}}>
							<Cultural data={this.state.Women} clothingType={"Women"} />
						</div>
						<div class="kids rel" style={{"background": `no-repeat center/cover url('${Kids.toString()}')`}}>
							<div onClick={() => {window.location.href = "/list?category=clothing&Type=Kids";}}>{"Proceed >>"}</div>
						</div>											
					</div>
					<div class="w-100 flex-center col" style={{height: "100px", backgroundColor: "#000", color: "orange", fontSize: "24px", margin: "15px 0"}}>
						<div>
							<span style={{textDecoration: "underline", margin: "0 5px", fontWeight: "bold"}} 
							onClick={() => {window.location.href = "https://info.deal-corner.com";}}>
							Get
							</span> detailed information and blogs on selected topics. If you have one, 
							<span style={{fontSize: "20px", textDecoration: "underline", margin: "0 5px", fontWeight: "bold"}}
							onClick={() => {window.location.href = "https://info.deal-corner.com/info-form"}}>share</span>it here.
						</div>
							
					</div>
					<div class="intro-images electronics flex-center">Buy new and used electronics!</div>
					<div class="tr-center" style={{padding: "15px", boxSizing: "border-box"}}>
						<div style={{minWidth: "300px", width: "25vw", backgroundColor: "#eee"}}><this.ElectronicsIcons/></div>
						<div><Electronics data={this.state.Electronics} /></div>
					</div>
						
					<Adsense style={{display:"block", minWidth: "300px", width: "100%"}} slot="3228008347" />
					
					{this.state.Cars && this.state.Houses && this.state["Health & beauty"] &&
					<div class="intro-images flex-center others">Find everything else here!</div>
					}
					<div id="others-container" class="flex-center">
						<div style={{width: "37.5vw", backgroundColor: "#eee"}}>
							<HealthAndBeauty data={this.state["Health & beauty"]} clothingType={"Health & beauty"} />
						</div>						
						{this.state.Cars && this.state.Houses &&
							<div style={{width: "62.5vw", backgroundColor: "#eee"}}>
								<CarsAndHouses data={[...this.state.Cars, ...this.state.Houses]} clothingType={"Kids"} />
							</div>
						}
					</div>
					
					<div class="deals-container">
						{	this.state.shopNames && this.state.shopNames.length > 0 && 
							<div class="intro-images flex-center shops">You can now visit shops from your home</div>
						}
						{
							this.state.shopNames.map((shop, nameIndex) => {
								const name = shop.name;								
								var DATA2 = this.state.shopItems[name] || [];
								const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;								
								const start = 0;
								if (DATA2.length < 3) return (<div style={{display: "none"}}></div>);
								return (
									<div class="deals-category w-100" style={{backgroundColor: "rgb(128, 43, 177,0.1)", marginTop: "25px"}}>
										<div  onClick={() => {window.location.href = `/list?Shop=${name}&Id=${shop.id}`;}}><this.shopInfo data={shop}/>
											<div style={{borderBottom: "1px solid #fff", margin: "0 25px 10px 25px", width: "calc(100% - 50px)"}} /></div>
										<div class="deals-grid rel w-100 flex" style={{ 
											overflowY: "hidden",paddingRight: "10px", 
											overflowX: "auto", whiteSpace: "nowrap"}}>
											
											{
												DATA2.slice(start).map((item, index) => {
													var pictures;
													try {
														pictures = JSON.parse(item.pictures);
													} catch(e) {
														pictures = {};
													}
													const pic = this.state.activePics[name+index] || "picture1";
													const picIndex = this.picIndex(pictures);
													const imgClass = picIndex > 0 ? "home-bg bg-pic" : "home-bg bg-icon";								
													const isImageSelected = this.state.selected == (name + index);													
													const src = picIndex > 0 ? 
																`https://deal-corner.com/server/shopItem/image/${pictures[pic]}`: 
																ShopItem.toString();
														
													return (
														<div class="flex col rel h-100 ptr mh-15" 
															onClick={() => {window.location.href = `/shop/items?shopname=${encodeURIComponent(name)}&id=${item.itemid}`}}>
															<img src={src} class={imgClass} />
															<this.description shopItem="true" item={item} category={name} index={index} pic={pic} />
															{ this.state.selected == (name + index) && 
																<this.itemInfo item={item} />
															}
														</div>
													)
												})
											}
											{ DATA2.length > 0 && 
												<div class="flex-center purple-cl ptr" 
													onClick={() => {this.props.history.push(`/list/${name.toLowerCase()}`)}} 
													style={{height: "100%", marginLeft: "5px", fontWeight: "bold"}}>more...
												</div>
											}
											{ DATA2.length > 0 && !isTouchDevice && this.state.starts[name.toLowerCase()] > 0 &&
												<div onClick={() => {this.moveRow(name,-1)}} style={{left: "25px"}}
												class="flex-center home-arrow ptr">
													<div><Chevron_left /></div>
												</div> 
											}
											{ DATA2.length > 0 && !isTouchDevice && this.state.starts[name.toLowerCase()] < 4 &&
												<div onClick={() => {this.moveRow(name, 1)}} 
												class="flex-center home-arrow ptr" style={{right: "5px"}}>
													<div><Chevron_right /></div>
												</div> 
											}
										</div>
									</div>
								)
							})
						}
					</div>
				
					<div class="footer">   
						<div class="footer-contents">		
							<div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/about">About the website</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/vacancy">Work with us</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/tech">Tech stuff</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/terms">Terms of Use</a>
								</div>
								<div style={{marginBottom: "15px"}}>
									<a style={{textDecoration: "none", color: "#f0ffff"}} href="/privacy">Privacy policy</a>
								</div>								
							</div>							
							<div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Email:</div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>info@deal-corner.com</div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Phone:</div>
								<div class="flex col" style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>
									<span>+251-974-374148</span>
									<span>+251-956-148721</span>
								</div>
								<div style={{color: "#f0ffff", fontWeight: "bold"}}>Address:</div>
								<div style={{color: "#ddd", marginLeft: "30px", marginBottom: "15px"}}>...</div>
							</div>
							<div style={{display: "flex", justifyContent: "center"}}>
								<Feedback />
							</div>
						</div>
						<small style={{color: "#aaa", textAlign: "center"}}>&#169; Copyright 2022, All Rights Reserved</small>
					</div>
				
				</div>
				}
				{
					this.state.isPosting &&
						<div class="form-bg purple-bg">
							<div><Form hideForm={this.closeForm}/></div>
						</div>
				}
			</div>		
		); 
	}
}

export default Home;
