import React from 'react';
import '../css/main.css';

class HomeActivityRow extends React.Component {	
	
	render() {	        
		return (
			<div class="flex col rel home-activity" style={{
			  background: "white",height: "100%", 
			  marginLeft: "15px", marginRight: "15px"}}>
				<div class="flex-center w-100 h-100 loading" style={{backgroundColor: "rgb(128, 43, 177,0.3)"}}>
					<div class="bg-icon"></div>
				</div>
					<div class="w-100" style={{marginTop: "5px"}}>
						<div class="loading">
							Item title...
						</div>
						<div class="loading">
							PRICE NOT GIVEN													
						</div>										
						<div class="tr-center" style={{margin: "5px 0"}}>
							<div class="pic-protype" 
								style={{backgroundColor: "rgb(128, 43, 177, 0.2)"}}>
							</div>													
							<div class="pic-protype" 
								style={{backgroundColor: "rgb(128, 43, 177, 0.2)"}}>
							</div>													
							<div class="pic-protype" 
								style={{backgroundColor: "rgb(128, 43, 177, 0.2)"}}>
							</div>
						</div>
				</div>
			</div>
		);
	}
}
         
export default HomeActivityRow;
