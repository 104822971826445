import React from 'react';
import '../css/main.css';

class Badge extends React.Component {	
	
	render() {
		return (
			<div style={{width: "30px", height: "30px", zIndex: "1001", position: "absolute", 
						left: "20px", top: "15px"}}>
				<svg viewBox="0 0 455 455">
					<polygon fill="yellow" stroke="yellow" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" 
						points="455,0 0,0 0,300 227,455 455,300"/>
					<text x={this.props.x1} y={this.props.y1} fontSize="100px">{this.props.text1}</text>
					<text x={this.props.x2} y={this.props.y2} fontSize="100px">{this.props.text2}</text>
				</svg>
			</div>
		);
	}
}
         
export default Badge;
