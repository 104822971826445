import React from 'react';
import jwt from 'jsonwebtoken';
import Form from "./Form";
import Utils from "./Utils";
import SideInfo from "./SideInfo";
import Adsense from "./Adsense";

import Activity from "./Activity";
import Badge from "./Badge";
import Header from "./Header";
import { ReactComponent as Location } from '../img/location.svg';
import { ReactComponent as Eye } from '../img/eye.svg';
import { ReactComponent as Calendar } from '../img/calendar.svg';
import { ReactComponent as Price } from '../img/price-tag.svg';
import { ReactComponent as Chevron_left } from '../img/chevron-left.svg';
import { ReactComponent as Chevron_right } from '../img/chevron-right.svg';
import { ReactComponent as Call } from '../img/phone-call.svg';
import { ReactComponent as Message } from '../img/message.svg';
import { ReactComponent as Telegram } from '../img/telegram-sq.svg';
import { ReactComponent as Trash } from '../img/trash.svg';
import { ReactComponent as Edit } from '../img/edit-outline.svg';
import { ReactComponent as Views } from '../img/views.svg';


import '../css/main.css';
var N_PICTURES = 0, TITLE_LENGTH = 15, PICTURE_KEYS = [], ID,
		CATEGORY, TYPE, DETAILS, PATH, SHOP_NAME = "";

class ExpandedItem extends React.Component {
	state={
		pictureIndex: -1,
		action: "",
		activePic: "picture1",
		popMessage: "",
		password: "",
		showActivity: true,
		canEdit: false,
		shouldEdit: false,
		isDeleting: false,
		recommendedItems: null
	}
	
	constructor(props) {
		super(props);
		const paramsString = window.location.search.substring(1);
		let searchParams = new URLSearchParams(paramsString);			
		
		ID = searchParams.get("id");
		CATEGORY = searchParams.get("category");
		TYPE = searchParams.get("type");
		const title = searchParams.get("title");
		//document.title = title;		
		DETAILS = searchParams.get("details");
		SHOP_NAME = searchParams.get("shopname");
		if (CATEGORY) {
			PATH = CATEGORY.charAt(0).toUpperCase() + CATEGORY.substring(1).toLowerCase();
		}
		/* document.querySelector('meta[property="og:title"]')
		.setAttribute('content', title); */
	}
		
	hasPicture = () => {
		const data = this.state.data;
		var index = -1;
		const pictures = JSON.parse(data.pictures);
		N_PICTURES = 0;
		Object.keys(pictures).forEach((key, i) => {
			if (pictures[key] && pictures[key].length > 0) {
				N_PICTURES++;
				if (index == -1) {
					index = parseInt(key.split("picture")[1]) - 1;
				}
			}
		});
		//const hasPicture1 = pictures.picture1 && pictures.picture1.length > 0;
		//const hasPicture2 = pictures.picture2 && pictures.picture2.length > 0;
		//const hasPicture3 = pictures.picture3 && pictures.picture3.length > 0;
		
		//N_PICTURES = hasPicture1 + hasPicture2 + hasPicture3;
		/* if (hasPicture1) {
			index = 0;
		} else if (hasPicture2) {
			index = 1;
		} else if (hasPicture3) {
			index = 2;
		} */
		
/* 		if (this.state.pictureIndex < 0 && this.state.pictureIndex != index) {
			this.setState({ pictureIndex: index });
		} */ 
		return index >= 0;
	}
	
	description = (props) => {
		const item = props.item;
		const category = props.category;
		const index = props.index;
		const shopItem = SHOP_NAME && SHOP_NAME.length > 0 ? "shopItem/" : "";
		var pictures, details = {};
		try {
			pictures = JSON.parse(item.pictures);
		} catch(e) {
			pictures = {};
		}
		try {
			details = JSON.parse(item.details);
		} catch(e) {
			details = {};
		}
		var tIndex = item.title.indexOf(" ", 0), temp = tIndex, counter = 0;
		
		while (temp > 0 && temp < TITLE_LENGTH && temp <= item.title.lastIndexOf(" ") && counter < 20) {
			tIndex = item.title.indexOf(" ", tIndex + 1);
			temp = item.title.indexOf(" ", tIndex + 1);
			counter++;
		}
		if (tIndex < 0) tIndex = Math.min(item.title.length, TITLE_LENGTH);
		const pic = props.pic;
		var url;
		if (SHOP_NAME && SHOP_NAME.length > 0) {
			url = `/shop/items?id=${item.item_id}&shop_name=${SHOP_NAME}`;
		} else {
			url = `/items?category=${item.category}&type=${details.Type}&details=${details.Details}&id=${item.id}`;
		}

		return (
			<div class="w-100 flex-center col rel" style={{backgroundColor: "#ddd", height: "40px"}}>
				<div onClick={() => {window.location.href = url;}}
					style={{fontSize: "12px", color: "#555", marginLeft: "5px"}}>
					{item.title.substring(0,tIndex)}
						{tIndex < item.title.length && <span>...</span>}
				</div>
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'number' &&
					<div style={{fontSize: "11px", color: "#777", marginLeft: "5px"}}>
						{parseFloat(item.price).toLocaleString('en-US')} ETB													
					</div>
				}
				{ item.quantity > 0 &&
					<div style={{width: "30px", height: "30px", position: "absolute", right: "2px", top: "2px", fontSize: "11px", color: "#777", marginLeft: "5px"}}>
						<this.cart quantity={item.quantity} />													
					</div>
				}
				{ item.quantity == 0 &&
					<div style={{width: "25px", height: "25px", position: "absolute", right: "2px", top: "2px", fontSize: "11px", color: "#777", marginLeft: "5px"}}>
						<span style={{color: "red", fontWeight: "bold", fontSize: "8px"}}>SOLD</span>													
					</div>
				}
				{ item.price && !isNaN(parseFloat(item.price)) && typeof parseFloat(item.price) == 'string' &&
					<div style={{fontSize: "11px", color: "#777", marginLeft: "5px"}}>
						{parseFloat(item.price.replace(/,/g,"")).toLocaleString('en-US')} ETB													
					</div>
				}
				{ (!item.price || isNaN(parseFloat(item.price))) && 
					<div style={{fontSize: "14px", color: "transparent", marginLeft: "5px"}}>
						PRICE NOT GIVEN													
					</div>
				}
			</div>
		);
	}
	
	imageClick = (e, index) => {
		e.stopPropagation();
		this.setState({activePic: "picture"+(index+1)});
		//this.setState({ imageExpanded: !this.state.imageExpanded });
		/* const element = document.getElementById("pic");
		const element2 = document.getElementsByClassName("hide-on-zoom");		
		
		if (element2) {
			for (var el of element2) {
				if (el) {
					el.classList.toggle("hide");
					el.classList.toggle("invisible");
				}
			}
		}
		
		if (element) {
			element.classList.toggle("clicked");
		} 	 */	
	}
	
	content = (txt) => {
		if (!txt) return "";
		var modified = txt.replace(/\n/g, "<br />");
		return modified;
	}
	
	expandMenu = (e) => {
		e.stopPropagation();
		e.preventDefault(); 
		this.setState({shouldEdit: true}); 
	}
	
	cart = (props) => {
		return (
			<svg viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg">
				<path d="M882.7 305.3c11.6 14.7 15.7 33.5 11.3 51.6l-43.4 179.9c-8.1 33.5-37.8 56.9-72.3 56.9H454.2v-20.1h324.1c25.1 0 46.8-17.1 52.8-41.5l43.4-179.9c2.9-12.1 0.2-24.7-7.5-34.5s-19.3-15.4-31.8-15.4H336.4v-20.1H835.3c18.5 0 35.8 8.4 47.4 23.1z" fill="#8B7B5E" />
				<path d="M800.5 701.8v20.1H415.1c-28.3 0-53.1-19.1-60.4-46.5L233 215.7c-4.9-18.6-21.8-31.5-40.9-31.5h-84.2v-20.1H192c28.3 0 53.1 19.1 60.4 46.5l121.8 459.7c4.9 18.5 21.8 31.5 41 31.5h385.3zM750.2 757.1c38.1 0 69.1 31 69.1 69.1 0 38.1-31 69.1-69.1 69.1s-69.1-31-69.1-69.1c0-38.1 31-69.1 69.1-69.1z m49 69.1c0-27-22-49-49-49s-49 22-49 49 22 49 49 49 49-22 49-49z" fill="#8B7B5E" />
				<path d="M435.7 757.1c38.1 0 69.1 31 69.1 69.1 0 38.1-31 69.1-69.1 69.1s-69.1-31-69.1-69.1c0-38.1 31-69.1 69.1-69.1z m49 69.1c0-27-22-49-49-49s-49 22-49 49 22 49 49 49 49-22 49-49z" fill="#8B7B5E" />
				<text x="520" y="540" font-size="250px" fill="#f75990" stroke-width="3" style={{fontWeight: "bold"}}>{props.quantity}</text>
			</svg>
		);
	}
	
	submit = () => {
		const post = this.state.data;
		
		if (this.state.action == "edit") {
			jwt.verify(post.password, "_deal_corner", (err, decoded) => {
				if (this.state.password == "_deal_123_corner" || (!err && decoded == this.state.password)) {
					const pwd = jwt.sign(this.state.password, "_deal_corner");
					sessionStorage.setItem(pwd, this.props.type ? post.itemid : post.id);
					sessionStorage.setItem("itemid", post.itemid);
					sessionStorage.setItem("type", this.props.type);
					window.location.href = "/form/" + pwd;
				} else {
					this.setState({ canEdit: false, popMessage: "Incorrect password!" });
					setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
				}
			});			
		} else {			
			this.setState({ isDeleting: true });
				jwt.verify(post.password, "_deal_corner", (err, decoded) => {
					
					if (this.state.password == "_deal_123_corner" || (!err && decoded == this.state.password)) {
						var url;
						if (this.props.type == "shop") {
							url = "https://deal-corner.com/server/shopItem/delete";	
						} else {
							url = "https://deal-corner.com/server/post/delete";	
						}
						const data = {
							"method": "POST",
							"headers": {"Content-Type": "application/json"},			
							"body": JSON.stringify({
								id: this.props.type == "shop" ? post.itemid : post.id,				
								pictures: post.pictures						
							}),
						};
					fetch(url, data).then((e) => e.json())
					.then((e) => {	
						this.setState({ isDeleting: false, action: "", editIndex: -1, popMessage: "The post is deleted successufully!" });
						window.location.href = "/";
						setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
					})
					.catch((e) => {	
						this.setState({isDeleting: false, action: "", editIndex: -1, popMessage: "The post could not be deleted."});
						setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
					}); 
				} else {
					this.setState({ isDeleting: false, popMessage: "Incorrect password!" });
					setTimeout(() =>{this.setState({popMessage: ""});}, 3000);
				}
			});
		}		
	}
	
	additionalItems = (props) => {
		
		return (
			<div class="w-100 hide-on-zoom">
				<div style={{margin: "15px 0", borderBottom: "1px solid #fff"}}></div>
				<div style={{fontSize: "18px", fontWeight: "bold", marginBottom: "15px", paddingLeft: "5px", color: "#333", fontFamily: "sans-serif"}}>You May Also Like</div>
					<div class="deals-category">
					{	this.state.recommendedItems &&
						<div style={{display: "grid", paddingTop: "15px",gridAutoRows: "1fr",
											gridTemplateColumns: "repeat(auto-fill, minmax(40%, 1fr))"}}>
							{ this.state.recommendedItems.map((item, index) => {
							const pictures = JSON.parse(item.pictures);
							const pic = "picture1";					
							const imgClass = "bg-pic";
							//const isImageSelected = this.state.selected == (category + index);
							const details = item.details ? JSON.parse(item.details) : {};
							
							const src = `https://deal-corner.com/server/image/${pictures[pic]}`;
							return (
							
								<div class="flex col rel ptr mh-15" style={{backgroundColor: "#eee", justifyContent: "flex-end", marginBottom: "15px"}}>
									<img src={src} class={imgClass} style={{backgroundColor: "#eee"}}/>	
									<this.description item={item} category={item.category} index={index} pic={pic} />
									{details["Has delivery"] == "Yes" &&
										<Badge x1="100" y1="120" x2="50" y2="250" text1= "Free" text2= "delivery" />
									}															
								</div>
							
							)
							})}
						</div>
					}
					</div>
			</div>
		);
	}
	
	touchStart = (e) => {
		const event = e || window.event;
		this.startX = event.pageX;		
		if (isNaN(this.startX) && event.touches[0]) {
			this.startX = event.touches[0].pageX;
		} else if (isNaN(this.startX) && event.changedTouches[0]) {
			this.startX = event.changedTouches[0].pageX;
		} else if (isNaN(this.startX) && event.targetTouches) {
			this.startX = event.targetTouches[0].pageX;
		} else if (isNaN(this.startX) && event.originalEvent) {
			this.startX = event.originalEvent.x;
		}		
	}
	
	mouseDown = (e) => {
		const event = e || window.event;
		this.startX = event.pageX;		
		if (isNaN(this.startX) && event.touches[0]) {
			this.startX = event.touches[0].pageX;
		} else if (isNaN(this.startX) && event.changedTouches[0]) {
			this.startX = event.changedTouches[0].pageX;
		} else if (isNaN(this.startX) && event.targetTouches) {
			this.startX = event.targetTouches[0].pageX;
		} else if (isNaN(this.startX) && event.originalEvent) {
			this.startX = event.originalEvent.x;
		} 
	}
		
	mouseUp = (e) => {		
		const event = e || window.event;		
		const end =  event.pageX || event.clientX;
		var picIndex = PICTURE_KEYS.indexOf(this.state.activePic) + Math.sign(this.startX - end);
		
		if (picIndex < 0) {
			this.setState({activePic: PICTURE_KEYS.length > 0 ? PICTURE_KEYS[0]: ""});
		} else if (picIndex >=  N_PICTURES) {
			this.setState({activePic: PICTURE_KEYS.length > 0 ? PICTURE_KEYS[N_PICTURES - 1]: ""});
		} else {
			this.setState({activePic: PICTURE_KEYS.length > 0 ? PICTURE_KEYS[picIndex]: ""});
		}
	}
	
	touchEnd = (e) => {		
		const event = e || window.event;
		var end = event.pageX;
		if (isNaN(end) && event.touches[0]) {
			end = event.touches[0].pageX;
		} else if (isNaN(end) && event.changedTouches[0]) {
			end = event.changedTouches[0].pageX;
		} else if (isNaN(end) && event.targetTouches) {
			end = event.targetTouches[0].pageX;
		} else if (isNaN(end) && event.originalEvent) {
			end = event.originalEvent.x;
		} 
		
		var picIndex = PICTURE_KEYS.indexOf(this.state.activePic) + Math.sign(this.startX - end);
		
		if (picIndex < 0) {
			this.setState({activePic: PICTURE_KEYS.length > 0 ? PICTURE_KEYS[0]: ""});
		} else if (picIndex >=  N_PICTURES) {
			this.setState({activePic: PICTURE_KEYS.length > 0 ? PICTURE_KEYS[N_PICTURES - 1]: ""});
		} else {
			this.setState({activePic: PICTURE_KEYS.length > 0 ? PICTURE_KEYS[picIndex]: ""});
		}
	}
	
	fetchData = () => {
		var url;
		if (this.props.type == "shop") {
			url= `https://deal-corner.com/server/shopItems/get`;
		} else {
			url= `https://deal-corner.com/server/get/deals/items`;
		}
		
		var body = { id: ID };		
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			const d = e.data.items || e.data;	
			if (d && Array.isArray(d) && d.length > 0) {				
				try {
					const pics = JSON.parse(d[0].pictures);
					PICTURE_KEYS = Object.keys(pics).filter((key) => pics[key].length > 0);
				} catch(e) {
					PICTURE_KEYS = [];
				}
				
				this.setState({data: d[0], showActivity: false });
				this.fetchRecommendations(d[0]);
			}			
		}).catch((e) => {
			console.log(e.message);
		});
	}
	
	
	
	fetchRecommendations = (d) => {
		var url;
		var info;
		var body = { };
		console.log(d);
		if (SHOP_NAME && SHOP_NAME.length > 0) {
			return;
			url = `https://deal-corner.com/server/shopItems/get/recommendations`;
			body = { id: d.id };			
		} else {
			url = `https://deal-corner.com/server/get/item/recommendations`;
			body = { category: d.category, title: d.title, id: d.id };
		}
		
		
		try {
			info = JSON.parse(d.details);
		} catch(e) {}
		
		if (typeof info == "object") {			
			body.type = info.Type;
			body.details = info.Details;
		}
		
		const data = {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json"
			}			
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			try {
				if (e && e.data && Array.isArray(e.data)) {
					if (SHOP_NAME && SHOP_NAME.length > 0) {
						e.data.forEach((item) => {
							item.price = item.price + "";
						});
					} 
					this.setState({recommendedItems: e.data });
				} else {
					this.setState({recommendedItems: [] });
				}
			} catch(err) {
			}
		}).catch((e) => {
			
		});
	}
	
	updateView = () => {
		var url;
		if (this.props.type == "shop") {
			url= `https://deal-corner.com/server/shopItem/views/update`;
		} else {
			url= `https://deal-corner.com/server/views/update`;
		}
		const data = {
			"method": "POST",	
			headers: {							
				"Content-Type": "application/json"
			},
			body: JSON.stringify({"id": ID}), 
		};
		fetch(url, data).then((e) => e.json())
		.then((e) => {
			if (e.data == "SUCCESS") {
				
			}			
		})
		.catch((e) => {
			
		}); 
	}
	
	/* componentDidUpdate(prevProps, prevState, snapshot) {
		
		if (this.state.data && Object.keys(this.state.data).length > 0 && !prevState.recommendedItems) {
			this.fetchRecommendations();
		}
	} */		
	 
	componentDidMount() {
		if (TYPE && TYPE.length > 0 && TYPE != "undefined" && TYPE != "null") {
			PATH = PATH + " / " + TYPE;
		}
		
		if (DETAILS && DETAILS.length > 0 && DETAILS != "undefined" && DETAILS != "null") {
			PATH = PATH + " / " + DETAILS;
		}
		this.fetchData();
		this.updateView();	
		
	}
	
	getImageUrl = (filename, isShopItem) => {
		return isShopItem ? 
						`https://deal-corner.com/server/shopItem/image/${filename}` : 
						`https://deal-corner.com/server/image/${filename}`;
	}
	
	render() {
		if (this.state.showActivity) {
			return (
				<div class="flex-center w-100" style={{height: "100vh", backgroundColor: "rgb(36, 48, 94, 0.9)"}}><Activity /></div>
			);
		}
		
		if (this.state.canEdit) {
			return (
				<div class="form-bg purple-bg">
					<div>
						<Form hideForm={() => {this.setState({ canEdit: false, shouldEdit: false, action: ""});}} 
							data={this.state.data} />
					</div>
				</div>
			);
		}
		
		const i = this.state.data;
		var pictures = {}, details = {};
		try {
			pictures = JSON.parse(i.pictures);
		} catch(e) {}
		try {
			details = JSON.parse(i.details);
		} catch(e) {}
		
		const now = Date.now();
		const activePic = this.state.activePic;
		
		const src = i.name ? 
						`https://deal-corner.com/server/shopItem/image/${pictures[activePic]}` : 
						`https://deal-corner.com/server/image/${pictures[activePic]}`;
		if (this.state.imageExpanded) {
			return (<div class="w-100 flex col" style={{backgroundColor: "rgb(36, 48, 94, 0.9)", color: "#444", minHeight: "100vh"}}>
						<Header filter={() => {}}  />
						<div id="picture-container" draggable="true" 
									onMouseDown={this.mouseDown} onDragEnd={this.mouseUp} 
									onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} style={{flex: 1, marginTop: "50px", width: "100vw", maxWidth: "468px", alignSelf: "center", 
						backgroundColor: "#fff", position: "relative", boxSizing: "border-box"}}>
						<img src={this.getImageUrl(pictures[activePic], i.itemid)} style={{width: "100%", height: "100%", objectFit: "contain"}} onClick={() => {this.imageClick()}} id="pic"/></div></div>);
	}
		return (
			
					
					<div class="w-100 flex col" style={{backgroundColor: "rgb(36, 48, 94, 0.9)", color: "#444", minHeight: "100vh"}}>
						<Header filter={() => {}}  />
							
						<div style={{flex: 1, width: "100vw", marginTop: "50px", maxWidth: "468px", alignSelf: "center", 
						backgroundColor: "#fff", position: "relative", boxSizing: "border-box", overflow: "hidden"}}>
						{ this.hasPicture() && 
								<div id="picture-container" draggable="true" 
									onMouseDown={this.mouseDown} onDragEnd={this.mouseUp} 
									onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} 
									class="ln-center rel" style={{backgroundColor: "rgba(3, 37, 126, 0.7)", alignItems: "flex-end",
									boxSizing: "border-box", margin: 0, overflow: "hidden", zIndex: 100}}>
									{false&&<small class="tr-center abs" style={{top: 0, right: 0, left: 0, 
										backgroundColor: "rgba(3, 37, 126, 0.4)", color: "#fff", padding: "0px 15px", height: "30px"}}>{PATH}</small>
									}
											{ pictures[activePic] && pictures[activePic].length > 0 &&
												<img src={this.getImageUrl(pictures[activePic], i.itemid)} class="list-picture-expanded w-100" 
												style={{backgroundColor: "rgba(255,165,0,0.03)", objectFit: "cover", height: "320px", margin: 0}} onClick={() => {this.imageClick()}} id="pic"/>	
											}
											{PICTURE_KEYS.length > 1 && 
											<div class="abs tr-center w-100" style={{left: 0, right: 0, bottom: 0, height: "50px", width: "50px",
									backgroundColor: "rgba(3, 37, 126, 0.7)"}}>
																		
										{ pictures[activePic] && pictures[activePic].length > 0 && 
											<div class="hide-on-zoom tr-center" style={{borderTop: "2px solid #fff", width: "100%", whiteSpace: "no-wrap", overflowX: "scroll", alignSelf: "flex-end"}}>
												{ PICTURE_KEYS.map((key, index) =>
													<img src={this.getImageUrl(pictures[key], i.itemid)} 
													style={{outlineOffset: "-5px", outline: this.state.activePic == "picture" + (index+1) ? "5px solid #000" : "",borderRight: "2px solid #fff", height: "50px", width: "50px",  margin: 0}} 
													onClick={(e) => {this.imageClick(e, index)}} id="pic"/>	
												
												)}
											</div>
										}
									</div>
											}
								</div>
							}
							<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", boxSizing: "border-box", width: "100%", 
							padding: "5px 15px", gridRowGap: "5px"}}>
								<div style={{fontWeight:"bold", gridColumn: "1/3", fontSize: "18px"}}>{i.title}
									{details.Status == "New" && 
										<sup style={{fontSize: "10px", color: "#0a0", paddingLeft: "10px"}}>NEW</sup>
									}
									{details.Status == "Used" && 
										<sup style={{fontSize: "10px", color: "#a33", paddingLeft: "10px"}}>USED</sup>
									}
								</div>
								<div style={{color:"orange", fontWeight: "bold"}}>ETB {parseFloat(i.price).toLocaleString()}</div>
								<div style={{color: "#aaa", textAlign: "right", fontSize: "14px"}}>{Utils.RelativeTime(now, i.date)}</div>
								<div class="tr-center w-100" style={{color: "#aaa", padding:"3px", width: "95%", marginLeft: "-5px"}}>
									<div style={{width: "18px", height: "18px"}}><Location /></div>
									<div style={{fontSize: "14px"}}>{i.city}</div>
								</div>								
								<div class="tr-center" style={{color: "#aaa", justifyContent: "flex-end"}}>
									<div class="flex-center">
										<div style={{width: "28px", height: "28px"}}><Views /></div>
									</div>
									<span style={{fontSize: "14px"}}>{i.views}</span>
								</div>
							</div>
							{i.content && i.content.length > 0 &&
								<div class="rel" style={{borderBottom: "1px solid #aaa", height: "15px", margin: "15px", marginTop: 0, width: "calc(100% - 30px)"}}>
									<span class="abs" style={{top: "2px", backgroundColor: "#fff", color: "#555",
										padding: "2px 10px", marginLeft: "15px", fontWeight: "bold"}}>Description</span>
								</div>
							}
							<div class="expanded-content hide-on-zoom" style={{color: "#555", paddingLeft: "30px"}} dangerouslySetInnerHTML={{ __html: this.content(i.content) }} />
														
							{ (i.phone || i.telegram) &&
								<div class="rel" style={{borderBottom: "1px solid #aaa", height: "15px", margin: "15px", width: "calc(100% - 30px)"}}>
									<span class="abs" style={{top: "2px", backgroundColor: "#fff", 
										padding: "2px 10px", marginLeft: "15px", fontWeight: "bold"}}>Contact seller</span>
								</div>
							}
							<div class="hide-on-zoom w-100 flex-center">
								{ i.phone && i.phone.length > 0 &&
									<div class="icon-md" title={i.phone}>
										<a href={`tel:${i.phone}`}>
											<Call />
										</a>
									</div>
								}
								{ i.phone && i.phone.length > 0 &&
									<div class="icon-md"  title={i.phone}>
										<a href={`sms:${i.phone}`}>
											<Message />
										</a>
									</div>
								}
								{ i.telegram && i.telegram.length > 0 &&
									<div class="icon-md"  title={i.telegram}>
										<a href={`https://t.me/${i.telegram}`}>
											<Telegram />
										</a>
									</div>
								}
							</div>
							<div style={{borderBottom: "1px solid #aaa", width: "calc(100% - 30px)", margin: "15px"}}></div>
							<div class="flex-center" style={{height:"50px", marginTop: "10px", marginBottom: "15px"}}>
								<div class="flex-center" style={{backgroundColor: "#008CBA", height: "40px", width: "40%", color: "#fff",
								fontSize: "16px", borderRadius: "4px", margin: "5px"}} onClick={() => {this.setState({ action: "edit", shouldEdit: false });}}>
									<div  style={{width: "18px", height: "18px", marginRight: "5px", padding: "2px"}}>
										<Edit />        
									</div>
									<span>Edit Item</span>
								</div>
								<div class="flex-center" style={{backgroundColor: "#ff2e2e", height: "40px", width: "40%", color: "#fff",
								fontSize: "16px", borderRadius: "4px", margin: "5px"}} onClick={() => {this.setState({ action: "delete", shouldEdit: false });}}>
									<div  style={{width: "24px", height: "24px", marginRight: "5px"}}>
										<Trash />        
									</div>
									<span>Delete Item</span>
								</div>
							</div>
							
						<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="3124086353" />
					
							{ this.state.recommendedItems && this.state.recommendedItems.length > 0 && 
								<div class="w-100 h-100 hide-on-zoom" style={{boxSizing:"border-box", display: "inline-block", padding: "0 10px"}}>
									
									<div style={{fontSize: "18px", fontWeight: "bold", marginBottom: "15px", 
									paddingLeft: "5px", color: "#333", fontFamily: "sans-serif"}}>You May Also Like 
										{SHOP_NAME && SHOP_NAME.length > 0 ? ` These Items From ` : ""}
										{SHOP_NAME && SHOP_NAME.length > 0 && <span style={{color: "orange"}}>{decodeURIComponent(SHOP_NAME)}</span>}
									</div>
										<div class="deals-category">
										<div style={{display: "grid", paddingTop: "15px",gridAutoRows: "1fr",
															gridTemplateColumns: "repeat(auto-fill, minmax(40%, 1fr))"}}>
										{ this.state.recommendedItems.map((item, index) => {
										const pictures = JSON.parse(item.pictures);
										const pic = "picture1";
										const picIndex = 1;
										const imgClass = picIndex > 0 ? "bg-pic" : "bg-icon";
										//const isImageSelected = this.state.selected == (category + index);
										const details = item.details ? JSON.parse(item.details) : {};
										const shopItem = SHOP_NAME && SHOP_NAME.length > 0 ? "shopItem/" : "";
										const src = `https://deal-corner.com/server/${shopItem}image/${pictures[pic]}`;
										return (										
											<div class="flex col rel ptr mh-15" 
											style={{backgroundColor: "#eee", justifyContent: "flex-end", marginBottom: "15px", height: "170px"}}>
												<img src={src} class={imgClass} style={{backgroundColor: "#eee", objectFit: "cover", height: "130px"}}/>	
												<this.description item={item} category={item.category} index={index} pic={pic} />
												{	details["Has delivery"] == "Yes" && false &&
														<Badge x1="100" y1="120" x2="50" y2="250" text1= "Free" text2= "delivery" />
													
												}															
											</div>
										)
									})}</div></div>
								</div>
							}
							{ this.state.action.length > 0 && 
								<div class="full-screen flex-center" style={{position: "fixed", zIndex: 1002, backgroundColor: "rgb(36, 48, 94, 0.9)"}}>
									<div class="form-container" style={{backgroundColor: "#777"}}>
										<div class="close btn purple-cl"  style={{alignSelf: "flex-end", cursor: "pointer"}}
											onClick={() => {this.setState({ action: "", shouldEdit: false, isDeleting: false })}}>
											<span>&#10006;</span>
										</div>
										<input class="full-width form" type="password" placeholder="Enter your password" style={{margin: "15px 0"}} 
											onChange={(e)=> this.setState({password: e.target.value, errorMessage: ""})} />
										{ this.state.action == "delete" && this.state.isDeleting && 
											<div class="w-100 tr-center purple-cl" style={{padding: "0 15px"}}>
												<Activity small="small"/>
												<small style={{flex: 1}}>Sending...</small>
											</div> 
										}
										<div class="button" onClick={this.submit}>
											Submit
										</div>
									</div>
								</div>
							}
							{ this.state.popMessage.length > 0 &&
								<div class="pop-up">
									<small>{this.state.popMessage}</small>
								</div>
							}
							
						<Adsense style={{display:"block", minWidth: "300px", maxWidth: "768px", width: "100%"}} slot="6094477005" format="autorelaxed" />
					
						</div>
					</div>
				
			
		);
	}
}
         
export default ExpandedItem;
