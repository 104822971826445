import React from 'react';


class TechStuff extends React.Component {	
	render() {
		
		return (
			<div class="v-list-container">
				<div class="v-list-header">Tech stuff</div>
				<div class="v-container">
						
						<p style={{textAlign: "justify"}}>The web application for <a href="https://deal-corner.com">https://deal-corner.com</a> is hosted on a cloud service provided by hahu cloud. The service provider uses cPanel as a web hosting control panel. The user friendly interface used by cPanel makes things easier to interact with the server which uses Linux as an operating system.</p>
						<p style={{textAlign: "justify"}}>On the backend, the server is written in Node.js aiming to obtain the simplicity, scalability and light weightedness edges offered by the language. A number of popular modules such as express are also used to have a concise code. MySQL is our preferred database for its better performance.</p>
						<p style={{textAlign: "justify"}}>The frontend is written with the dynamic React library. This inturn boosted the performance and reduced the latency. In addition, due to the capability to use independent components with React, neater codes could also be written.</p>
					</div>
			</div>
		);
	}
}
         
export default TechStuff;
