import React from 'react';
import Header from './Header';
import HomeActivityRow from './HomeActivityRow';
import '../css/main.css';
import CATEGORIES from '../constants/categories.json';

const TEMP_CATEGORIES = [{}, {}, {}];
const DATA = [{}, {}, {}, {}, {}];
									
class HomeActivity extends React.Component {
	
	render() {
		return (
			<div class="home-container rel w-100" 
				style={{minHeight: "100vh", overflowX: "hidden", backgroundColor: "rgb(128, 43, 177, 0.1)"}}>
				<Header filter={() =>{}} setCategory={() =>{}} showForm={() =>{}} />
				<div style={{maxWidth: "100vw"}}>
					<div id="home-message" style={{minHeight: "25vh"}}>
						<div class="side-menu loading">
							{
								CATEGORIES.slice(0,9).map((item) => 
									<div style={{padding: "5px", display: "flex"}}>
										<div style={{cursor: "pointer"}}>
											{item}
										</div>
									</div>
								)
							}
						</div>
						<div class="home-pic"><div class="w-100 loading" style={{height: "100%"}}></div></div>
					</div>
					
					
					<div class="deals-container">
						{
							TEMP_CATEGORIES.map(() => {
									return (
										<div class="deals-category w-100">
											<div class="rel tr-center" style={{justifyContent: "space-between", padding: "5px 35px 5px 20px"}}>
												<div class="w-100 loading" style={{marginTop: "10px", fontSize: "22px"}}>
													Title is loading...
												</div>
											</div>
											<div class="deals-grid rel w-100 flex">
												{
													DATA.map((item, index) => {
														return (
															<HomeActivityRow />
														)
													})
												}
											</div>
										</div>
									)
								}
							)
						}
					</div>
				</div>
			</div>
		); 
	}
}

export default HomeActivity;
