import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import Home from './js/Home';
import Home2 from './js/Home2';
import Form from './js/Form';
import InfoForm from './js/InfoForm';
import ExpandedItem from './js/ExpandedItem';
import ExpandedInfo from './js/ExpandedInfo';
import List from './js/List';
import List2 from './js/List2';
import About from './js/About';
import Feedback from './js/Feedback';
import StoreRegistrationForm from './js/StoreRegistrationForm';
import TechStuff from './js/TechStuff';
import Vacancy from './js/VacancyList';

import Privacy from './js/Privacy';
import TermsOfUse from './js/TermsOfUse';
import Prohibited from './js/Prohibited';
import Infringement from './js/Infringement';
import Error404 from './js/Error404';

	ReactDOM.render(
		<BrowserRouter>
			<Switch>				
			{window.screen.width < 768 && <Route exact path='/' component={Home} />}
				{window.screen.width >= 768 && <Route exact path='/' component={Home2} />}
				<Route path='/items' component={ExpandedItem} />
				<Route path='/info' render={({match}) => {
					try {
						const paramsString = window.location.search.substring(1);
						let searchParams = new URLSearchParams(paramsString);
						const title = searchParams.get("title");
						const keywords = searchParams.get("keywords");
						document.title = title || "Deal Corner";
						document.querySelector('meta[property="og:title"]').setAttribute('content', title);
						document.querySelector('meta[property="og:type"]').setAttribute('content', "article");			
						document.querySelector('meta[name="description"]').setAttribute('content', title);
						document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);			
						document.querySelector('meta[property="og:description"]').setAttribute('content', title);
						document.querySelector('meta[property="og:url"]').setAttribute('content',window.location.href);
					} catch(e){}
					return (<ExpandedInfo  />);
				}}  />
				<Route path='/shop/items' render={({match}) => (
				  <ExpandedItem type="shop" />
				)} />
				<Route exact path='/form' component={Form} />
				<Route exact path='/infoForm' component={InfoForm} />
				<Route path='/form/:id' render={({match}) => (
				  <Form data={match.params.id} />
				)} />
				{window.screen.width >= 768 && <Route exact path='/list' component={List2} />}	
				{window.screen.width >= 768 && <Route exact path='/list/:category/:num' component={List2} />}	
				<Route exact path='/list' component={List} />	
				<Route exact path='/list/:category/:num' component={List} />
				
						
				<Route exact path='/about' component={About} />			
				<Route exact path='/feedback' component={Feedback} />		
				<Route exact path='/tech' component={TechStuff} />	
				<Route exact path='/vacancy' component={Vacancy} />	
				<Route exact path='/privacy' component={Privacy} />	
				<Route exact path='/terms' component={TermsOfUse} />	
				<Route exact path='/prohibited' component={Prohibited} />
				<Route exact path='/shop/register' component={StoreRegistrationForm} />				
				<Route exact path='/infringement' component={Infringement} />				
				<Route path='/*' component={Error404} />					
			</Switch>			
		</BrowserRouter>, 
	document.getElementById('root') );	


